class ItineraryList {

	/**
	 * ItineraryList Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - ItineraryList instance
	 */

	constructor($, $el) {

		const objects = {
			$itineraryDays: $el.find('.itinerary-day')
		};

		this.updateItineraryView = () => {
			if(objects.$itineraryDays.length > 1) {
				objects.$itineraryDays.addClass('half');
			}
		};

		this.firstRun = () => {
			console.info('~~~ ItineraryList Module ~~~');

			this.updateItineraryView();
		};
	}

	name() {
		return 'ItineraryList';
	}

	init() {
		this.firstRun();
	}

}

export default ItineraryList;

