class WeatherBadge {

	constructor($, $el) {
		const objects = {
			$weatherUrl: $el.data('url'),
			$weatherData: $el.data('weather-data'),
			$tempSymbol: $el.data('temp-symbol'),
		};

		this.resize = () => { };

		this.firstRun = () => {
			console.info('~~~ WeatherBadge Module ~~~');

			$.ajax(objects.$weatherUrl, {
				cache: false,
				dataType: 'json',
				method: 'POST',
				data:objects.$weatherData
			}).done( function(result) {
				var valueTemp = Math.round(result.data[0].temp);
				$el.html(valueTemp + objects.$tempSymbol);
			}).fail(function(result) {
				//console.log('Something went wrong getting weatherbit data: ' + result.responseJSON.error);
			});
		};
	}

	name() {
		return 'WeatherBadge';
	}

	init() {
		this.firstRun();
	}

}

export default WeatherBadge;

