class PasswordChange {

	/**
	 * PasswordChange Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PasswordChange instance
	 */

	constructor($, $el) {

		const objects = {
			$alertError: $el.find('.js-alert-error'),
			$alertErrorOldPass: $el.find('.js-alert-error-old-pass'),
			$alertErrorBadPass: $el.find('.js-alert-error-bad-pass'),
			$alertErrorInvalidFormat: $el.find('.js-alert-error-bad-format'),
			$alertSuccess: $el.find('.js-alert-success'),
			$email: $el.find('input[name="email"]'),
			$feedbackPasswordMismatch: $el.find('.js-alert-mismatch'),
			$formChange: $el.find('form'),
			$modal: $el.closest('.modal'),
			$newPassword: $el.find('input[name="new_password"]'),
			$newPassword2: $el.find('input[name="new_password_confirm"]'),
			$oldPasswordGroup: $el.find('.js-old-password-group'),
			$oldPassword: $el.find('input[name="old_password"]'),
			$token: $el.find('input[name="token"]'),
		};

		this.changeSuccess = (data, textStatus, jqXHR) => {
			if (data['result_code'] === 200) {
				console.info('Password change succeeded.');
				objects.$alertSuccess.removeClass('d-none');
			} else {
				this.changeFail(jqXHR, textStatus, data);
			}
		};

		this.changeFail = (jqXHR, textStatus, errorThrown) => {
			console.error(`Password change failed: ${textStatus} : ${errorThrown}`);
			const resultCode = errorThrown['result_code'];
			const result = errorThrown['result'];
			if (result) {
				switch (result) {
					case '0':
						// Invalid password format
						objects.$alertErrorInvalidFormat.removeClass('d-none');
						break;
					case '4':
						// Bad password
						objects.$alertErrorBadPass.removeClass('d-none');
						break;
					case '8':
						// Cannot reuse previous 5 passwords
						objects.$alertErrorOldPass.removeClass('d-none');
						break;
					default:
						// Other error show generic message.
						objects.$alertError.removeClass('d-none');
				}
			}
			objects.$formChange.find('.btn').removeClass('disabled');
		};

		this.firstRun = () => {
			console.info('~~~ PasswordChange Module ~~~');
			objects.$formChange.submit(this.handleChange);
			objects.$modal.on('show.bs.modal', this.onShow);

			// Disable token or old password field in response to token and email parameters.
			const qs = new URLSearchParams(window.location.search);
			const token = qs.get('token');
			const email = qs.get('email');
			if (!!token && !!email) {
				// Disable old password field.
				objects.$oldPasswordGroup.hide();
				objects.$token.val(token);
				objects.$email.val(email);

				// Rewrite history query string.
				qs.delete('token');
				qs.delete('email');
				let query = qs.toString();
				query = query.length > 0 ? '?' + query : query;
				window.history.replaceState({}, '', `${location.pathname}${query}`);
				//Due to a bug in Firefox we have to add a new entry into the history or the dialog will appear again when the user logs in
				window.history.pushState({}, '', `${location.pathname}${query}`);

				// Show modal.
				objects.$modal.modal('show');
			}
		};

		this.onShow = (event) => {
			if (event.relatedTarget) {
				objects.$email.val($(event.relatedTarget).data('email'));
			}
		};

		this.handleChange = (event) => {
			event.preventDefault();
			if (this.validate()) {
				$el.find('.form-group.has-danger').removeClass('has-danger');
				$el.find('.form-control-feedback').addClass('d-none');
				// POST to registerUser action.
				const $form = $(event.target);
				$form.find('.btn').addClass('disabled');
				const url = $form.attr('action');
				const data = $form.serialize();
				objects.$alertSuccess.addClass('d-none');
				objects.$alertError.addClass('d-none');
				objects.$alertErrorBadPass.addClass('d-none');
				objects.$alertErrorOldPass.addClass('d-none');
				objects.$alertErrorInvalidFormat.addClass('d-none');
				$.ajax({
					cache: false,
					type: 'POST',
					url: url,
					data: data,
					dataType: 'json'
				})
					.done(this.changeSuccess)
					.fail(this.changeFail);
			}
		};

		this.validate = () => {
			// Check passwords.
			const password = objects.$newPassword.val();
			const passwordConfirm = objects.$newPassword2.val();
			if (password !== passwordConfirm) {
				objects.$feedbackPasswordMismatch.removeClass('d-none').closest('.form-group').addClass('has-danger');
				return false;
			}
			return true;
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'PasswordChange';
	}

	init() {
		this.firstRun();
	}

}

export default PasswordChange;
