class PersonalGuidance {

	/**
	 * PersonalGuidance Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PersonalGuidance instance
	 */

	constructor($, $el) {
		const objects = {
			$header: $el.find('header'),
			$navTab: $el.find('.nav-tabs'),
			$tabPane: $el.find('.tab-pane'),
			$tabLinks: $el.find('.nav-link'),
			$progress: $el.find('#progress-bar'),
			$close: $el.find('.close'),
			$prev: $el.find('[data-prev]'),
			$next: $el.find('[data-next]'),
			$budgetDecrease: $el.find('[data-decrease]'),
			$budgetIncrease: $el.find('[data-increase]'),
			$step0: $el.find('#step-0'),
			$feature: $el.find('.feature'),
			$range: $el.find('input[type="range"]'),
			$checkboxes: $el.find('.form-check-input'),
			$circleContainer: $el.find('.container-circle'),
			$backgroundImg: $el.find('.background img'),
		};

		this.resize = () => {};

		this.handlePrevNextClick = (e) => {
			let $button = $(e.target);
			let destPage = $button.data('target');

			const showNextPage = () => {
				objects.$tabPane.removeClass('active');
				$(destPage).tab('show');
				this.changeActiveNav(destPage);
				this.changeProgress(destPage);
			};

			if(destPage === '#step-1' && objects.$step0.hasClass('active')) {
				objects.$circleContainer.addClass('animated');

				objects.$circleContainer.on('webkitAnimationEnd oanimationend msAnimationEnd animationend', () => {
					showNextPage();
					objects.$circleContainer.removeClass('animated');
				});
			} else {
				showNextPage();
			}

		};

		this.handleCloseClick = () => {
			objects.$tabPane.removeClass('active');
			objects.$step0.tab('show');
			this.changeActiveNav('#step-0');
			this.changeProgress('#step-0');
		};

		this.changeProgress = (destPage) => {
			let numSteps = objects.$tabLinks.length;
			let currentStep = destPage.substring(destPage.indexOf('-') + 1); // i.e. #test-0 returns 0
			let width = 100 / (numSteps - 1) * (currentStep - 1); 

			if (currentStep == 0) {
				objects.$header.hide();
				objects.$progress.attr('aria-valuenow', 0);
				$el.removeClass('in-progress');
				$('#main').removeClass('pt-0');
			}
			else {
				objects.$header.show();
				objects.$progress.attr('aria-valuenow', width);
				objects.$progress.width(width + '%');
				$el.addClass('in-progress');
				$('#main').addClass('pt-0');
			}
		};

		this.changeActiveNav = (destPage)=> {
			let targetNav = `.nav-link[href="${destPage}"]`;

			/*
			if (targetNav === '.nav-link[href="#step-1"]') {
				$el.addClass('in-progress');
			} else if (targetNav === '.nav-link[href="#step-0"]') {
				$el.removeClass('in-progress');
			}
			*/

			$('.nav-link.active').removeClass('active');
			$('.nav-item.completed').removeClass('completed');
			objects.$navTab.removeClass('hidden-xs-up');
			$(targetNav).addClass('active').parent('li').prevAll().addClass('completed');
		};

		// todo: currently step validation identical - will need to refactor if validation rules differ
		this.validateStep = ($step) => {
			let valid = $step.find('.form-check-input:checked').length > 0;
			$step.find('[data-next]').attr('aria-disabled', !valid);
			$step.find('button[type=submit]').attr('aria-disabled', !valid);
		};

		this.displayRangeOutput = ()=> {
			let valueToShow = '$$';
			let valueClass = 'two';
			let $rangeOutput = objects.$range.next('output');

			objects.$range.on('input change', () => {
				// set value in dollar signs and class in text
				if (objects.$range.val() === '1') {
					valueToShow = '$';
					valueClass = 'one';
				} else if (objects.$range.val() === '3') {
					valueToShow = '$$$';
					valueClass = 'three';
				} else {
					valueToShow = '$$';
					valueClass = 'two';
				}

				$rangeOutput.text(valueToShow).removeClass().addClass(valueClass);
			});

			objects.$budgetDecrease.on('click', function() {
				let currentVal = objects.$range.val();
				objects.$range.val(--currentVal);
				objects.$range.trigger('change');
			});

			objects.$budgetIncrease.on('click', function() {
				let currentVal = objects.$range.val();
				objects.$range.val(++currentVal);
				objects.$range.trigger('change');
			});
		};

		this.firstRun = () => {
			console.info('~~~ PersonalGuidance Module ~~~');

			objects.$prev.on('click', this.handlePrevNextClick);
			objects.$next.on('click', this.handlePrevNextClick);
			objects.$tabLinks.on('click', this.handlePrevNextClick);
			objects.$close.on('click', this.handleCloseClick);
			objects.$checkboxes.on('click', (e) => {
				let $step = $(e.currentTarget).closest('.tab-pane');
				this.validateStep($step);
			});

			this.displayRangeOutput();


			objects.$backgroundImg.on('lazyloaded', function(e){
				objects.$backgroundImg.addClass('pan');
			});
		};
	}

	name() {
		return 'PersonalGuidance';
	}

	init() {
		this.firstRun();
	}

}

export default PersonalGuidance;
