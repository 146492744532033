import Carousel from '../modules/Carousel';
import MapView from '../modules/MapView';

class PropertyToggle {

	/**
	 * PropertyToggle Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PropertyToggle instance
	 * @param {object} utils - Utils instance
	 */

	constructor($, $el, utils) {
		const objects = {
			$tabItem: $el.find('[data-toggle="tab"]'),
			$tabMap: $el.find('#tab-map-view')[0],
			$tabGrid: $el.find('#tab-grid-view')[0],
			$mapView: $el.find('[data-module="MapView"]'),
			$carousel: $el.find('.property-map-slider [data-module="Carousel"]'),
			objMapView: null,
			objCarousel: null
		};

		this.handleTabShown = (e) => {
			let tab = $(e.target)[0].hash;

			objects.$tabItem.removeClass('active');
			$(e.target).addClass('active');

			if (tab === '#tab-map-view') {
				// console.log('TAB VIEW');
				if (objects.objMapView) objects.objMapView.init();
				if (objects.objCarousel) objects.objCarousel.init();
			} else if(tab === '#tab-grid-view') {
				// console.log('GRID VIEW');
				// $el.find('.property-grid-item').resize();
			}
		};

		this.resize = () => {};

		this.firstRun = () => {
			console.info('~~~ Property Toggle Module ~~~');

			objects.objCarousel = new Carousel($, objects.$carousel, utils);
			objects.objMapView = new MapView($, objects.$mapView, utils);

			objects.$tabItem.on('shown.bs.tab', this.handleTabShown);

		};
	}

	name() {
		return 'PropertyToggle';
	}

	init() {
		this.firstRun();
	}

}
	
export default PropertyToggle;
