import Carousel from './Carousel';

class VacationsInProgress {

	/**
	 * VacationsInProgress Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - VacationsInProgress instance
	 * @param {object} utils - Utils instance
	 */

	constructor($, $el, utils) {

		const params = {
			url: $el.data('url')
		};

		const objects = {
			$carousel: $el.find('.carousel'),
			$modal: $el.find('.modal'),
			$modalCarousel: $el.find('.modal-carousel'),
			objCarousel: new Carousel($, $el.find('.carousel'), utils),
			objModalCarousel: new Carousel($, $el.find('.modal-carousel'), utils),
			$indicators: $el.find('.carousel-indicators')
		};

		let imgData = null;

		this.resize = () => {
			objects.objCarousel.destroyCarousel(objects.$carousel);
			objects.objModalCarousel.destroyCarousel(objects.$modalCarousel);
			objects.$carousel.empty();
			objects.$modalCarousel.empty();
			objects.objCarousel = new Carousel($, objects.$carousel, utils);
			this.displayCarousel();
		};

		this.displayCarousel = () => {

			let $fg = $('<div class="figure-group"></div>');
			let viewport = utils.getViewportSize();

			//  item must have 'approved' status / 'image' type
			imgData = imgData.filter((x) => (x['status'].toLowerCase() === 'approved') && (x['type'].toLowerCase() === 'image'));

			let itemCount = imgData.length;
			// reduce item count to match layout
			if (viewport === 'xs' || viewport === 'sm') {
				itemCount = itemCount - (itemCount % 4);
			} else {
				itemCount = itemCount - (itemCount % 3);
			}

			for (let i = 0; i < itemCount; i++) {

				let itemData = imgData[i];

				if (viewport === 'xs' || viewport === 'sm') {
					if (i % 4 === 0) { // mobile
						$fg = $('<div class="figure-group"></div>');
					}
				} else {
					if ((i + 1) % 3 !== 0) { // desktop
						$fg = $('<div class="figure-group"></div>');
					}
				}

				let $item = $('#carousel-item').clone().removeAttr('id');

				let itemUploader = imgData[i]['_embedded']['uploader'];

				let imgSrc = itemData['images']['original'];
				$item.find('[data-photo]').attr('data-src', imgSrc);
				$item.find('[data-photo]').addClass('lazyload');
				$item.find('[data-toggle="modal"]').attr('data-index', i);
				$item.find('[data-photo]').attr('alt', itemData['caption']);

				if (itemData['source'].toLowerCase() === 'instagram') {
					$item.find('[data-source-icon]').html('<svg><use xlink:href="#logo-instagram"></use></svg>');
				}

				$fg.append($item);
				objects.$carousel.append($fg);

				let $modalItem = $('#modal-carousel-item').clone().removeAttr('id');
				$modalItem.find('[data-photo]').attr('data-src', imgSrc);
				$modalItem.find('[data-photo]').addClass('lazyload');
				$modalItem.find('[data-photo]').attr('alt', itemData['caption']);

				if (itemUploader['avatar_url'] && itemUploader['avatar_url'].length > 0) {
					$modalItem.find('[data-profile-photo]').attr('data-src', itemUploader['avatar_url']);
					$modalItem.find('[data-profile-photo]').addClass('lazyload');
					$modalItem.find('[data-profile-photo]').show();
				} else {
					$modalItem.find('[data-profile-photo]').hide();
				}

				$modalItem.find('[data-profile-name]').text(itemUploader['name']);
				$modalItem.find('[data-profile-username]').text(`@${itemUploader['username']}`);
				$modalItem.find('[data-caption]').text(itemData['caption']);

				let streams = imgData[i]['_embedded']['streams:all']['_embedded']['stream'];

				let learnMoreLink = null;
				for (let i = 0; i < streams.length && null === learnMoreLink; i++) {
					let baseImage = streams[i]['_embedded']['base_image'];
					if(null !== baseImage) {
						let caption = baseImage['caption'];
						if (caption && caption.toLowerCase() === 'learn more') {
							learnMoreLink = streams[i]['_embedded']['base_image']['share_url'];
							if (null === learnMoreLink) {
								learnMoreLink = streams[i]['share_url'];
							}
						}
					}
				}
				if(null !== learnMoreLink) {
					$modalItem.find('[data-info-link]').attr('href', learnMoreLink);
					$modalItem.find('[data-info-link]').show();
				} else {
					$modalItem.find('[data-info-link]').hide();
				}

				objects.$modalCarousel.append($modalItem);
			}

			objects.objCarousel.initCarousel(
				objects.$carousel,
				null,
				{
					slickOptions: {
						appendDots: $('.vacations-in-progress .carousel-indicators'),
						infinite: true,
						slidesToShow: 3,
						$slidesToShowMobile: 1,
						slidesToScroll: 1,
						fade: false,
						dots: false,
						arrows: true,
						centerMode: true,
						centerPadding: 0,
						prevArrow: objects.$carousel.siblings('[data-prev]'),
						nextArrow: objects.$carousel.siblings('[data-next]'),
						variableWidth: true,
						responsive: [{
							breakpoint: utils.constants.size.medium,
							settings: {
								arrows: false,
								slidesToShow: 1,
								slidesToScroll: 1,
								centerMode: true,
								centerPadding: 0,
								variableWidth: false,
								dots: true
							}
						}]
					},
					slickNavOptions: {}
				}
			);

			objects.objModalCarousel.initCarousel(
				objects.$modalCarousel,
				null,
				{
					slickOptions: {
						appendDots: objects.$indicators,
						infinite: true,
						slidesToShow: 1,
						$slidesToShowMobile: 1,
						slidesToScroll: 1,
						fade: false,
						dots: true,
						arrows: true,
						centerMode: false,
						centerPadding: 0,
						prevArrow: objects.$modalCarousel.siblings('[data-prev]'),
						nextArrow: objects.$modalCarousel.siblings('[data-next]'),
						variableWidth: false,
						responsive: [{
							breakpoint: utils.constants.size.medium,
							settings: {
								arrows: false,
								slidesToShow: 1,
								slidesToScroll: 1,
								centerMode: true,
								centerPadding: 0,
								variableWidth: false
							}
						}]
					},
					slickNavOptions: {}
				}
			);


		};

		this.displayModalCarousel = (e) => {

			let item = $(e.relatedTarget);
			let itemIndex = item.data('index');

			objects.objModalCarousel.initCarousel(
				objects.$modalCarousel,
				null,
				{
					slickOptions: {
						appendDots: $('.carousel-indicators'),
						infinite: true,
						slidesToShow: 1,
						$slidesToShowMobile: 1,
						slidesToScroll: 1,
						fade: false,
						dots: true,
						arrows: true,
						centerMode: false,
						centerPadding: 0,
						prevArrow: objects.$modalCarousel.siblings('[data-prev]'),
						nextArrow: objects.$modalCarousel.siblings('[data-next]'),
						variableWidth: false,
						responsive: [{
							breakpoint: utils.constants.size.medium,
							settings: {
								arrows: false,
								slidesToShow: 1,
								slidesToScroll: 1,
								centerMode: true,
								centerPadding: 0,
								variableWidth: false
							}
						}]
					},
					slickNavOptions: {}
				}
			);
			objects.$modalCarousel[0].slick.slickGoTo(itemIndex, true);
		};

		this.firstRun = () => {
			console.info('~~~ VacationsInProgress Module ~~~');

			const url = params.url;
			let media = [];
			$.get(url, (res) => {

				//console.log('Olapic Response: ', res);
				if (res.metadata.code === 200) {
					media = res.data._embedded.media;
					imgData = media;
					this.displayCarousel();
				}
			});

			objects.$modal.on('shown.bs.modal', (e) => {
				this.displayModalCarousel(e);
				objects.objModalCarousel.on('afterChange', (event, slick, currentSlide) => {
					$(slick.$slides[currentSlide]).focus();
				});
			});

			$(window).on('load', function() {
				$('.olapic_upload_button').attr('role', 'button');
			});
		};
	}

	name() {
		return 'VacationsInProgress';
	}

	init() {
		this.firstRun();
	}

}

export default VacationsInProgress;
