class Modal {

	/**
	 * Modal Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Modal instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const objects = {
			$video: $el.find('video'),
			$youtubeVideo: $el.find('.ytvideo'),
			$dismissBtn: $el.find('[data-dismiss]')
		};
		
		this.setVideoModal = () => {
			// Hosted Video
			if(objects.$video.length > 0) {
				$el.on('shown.bs.modal', () => {
					objects.$video.get(0).play();
				});
			}

			// Youtube Video
			if(objects.$youtubeVideo.length > 0) {
				objects.$youtubeVideo[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');

				$el.on('shown.bs.modal', () => {
					objects.$youtubeVideo[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
				});
			}
		};

		this.setNestedModal = (e) => {
			let $modalToggler = $(e.currentTarget);
			let $togglerTarget = $modalToggler.data('nested-modal');
			let $thisModal = $modalToggler.parents('.modal');

			$thisModal.on('hidden.bs.modal', () => {
				$($togglerTarget).modal('show');

				$thisModal.off('hidden.bs.modal');
			});
		};

		this.resize = () => { };


		this.trapFocus = (element) => {
			let focusableEls = element.querySelectorAll('input:not([tabindex="-1"]), select:not([tabindex="-1"]), button:not([tabindex="-1"]), a:not([tabindex="-1"])');

			let lastFocusableEl = focusableEls[focusableEls.length - 1];
			element.addEventListener('keydown', function(e) {

				let isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

				if (!isTabPressed) {
					return;
				}

				if ( e.shiftKey ) /* shift + tab */ {
					if (document.activeElement === $el[0]) {
						lastFocusableEl.focus();
						e.preventDefault();
					}
				} else /* tab */ {
					if (document.activeElement === $('.navbar-brand')[0]) {
						$el[0].focus();
						e.preventDefault();
					}
				}

			});
		};

		this.rescueFocus = () => {
			$('.bodywrapper').focus();
		};

		this.stopVideos = () => {
			// Stop Youtube Videos
			objects.$youtubeVideo.each((i, el) => {
				if(el.contentWindow) {
					el.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
				}
			});
			// Stop Non-Youtube Videos
			objects.$video.each((i, el) => {
				el.pause();
			});
		};

		this.firstRun = () => {
			console.info('~~~ Modal Module ~~~');

			// utils.setTooltips();

			if($el.hasClass('modal-video')) {
				this.setVideoModal();
			}

			objects.$dismissBtn.on('click', this.setNestedModal);

			$el.on('shown.bs.modal', () => {
				$el.attr('tabindex', 0);
				this.trapFocus($el[0]);
			});
			$el.on('hide.bs.modal', () => {
				this.stopVideos();
				this.rescueFocus();
			});


			$el.find('.carousel').on('afterChange', (event, slick, currentSlide) => {
				this.stopVideos();
			});
		};
	}

	name() {
		return 'Modal';
	}

	init() {
		this.firstRun();
	}
}

export default Modal;

