class DedicatedGallery {

	/**
	 * DedicatedGallery Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - DedicatedGallery instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const objects = {
			$filter: $($el.data('filtered-by')),
			$gallerySet: $el.find('.gallery-set'),
			$galleryModals: $('.modal-carousel-gallery .carousel'),
		};

		const options = {
			slickOptions: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				fade: false,
				dots: false,
				centerMode: true,
				centerPadding: 0,
				variableWidth: false,
				speed: 700,
				cssEase: 'ease-in-out',
				focusOnSelect: true,

				responsive: [{
					breakpoint: utils.constants.size.medium,

					settings: {
						infinite: true,
						slidesToShow: 1,
						centerMode: true,
						centerPadding: 0,
						variableWidth: false,
						speed: 500
					}
				}]
			}
		};


		this.initCarousel = ($carousel, slickOptions) => {

			const checkSlideCount = () => {
				if ($($carousel).hasClass('slick-initialized')) {
					let $slickTrack = $($carousel).find('.slick-track');

					if ($($carousel).slick('getSlick').slideCount < 2) {
						$slickTrack.addClass('d-block-track');
					} else {
						$slickTrack.removeClass('d-block-track');
					}
				}
			};

			const updateFilteredState = (e) => {
				if ($($carousel).hasClass('slick-initialized')) {
					$($carousel).slick('slickUnfilter');

					if ($(e.currentTarget).val() !== 'all') {
						objects.$gallerySet.attr('data-filtered', true);

						if ($(e.currentTarget).val() === 'image') {
							$($carousel).slick('slickFilter', '.image-asset');

						} else if ($(e.currentTarget).val() === 'video') {
							$($carousel).slick('slickFilter', '.video-asset');
						}

					} else {
						objects.$gallerySet.attr('data-filtered', false);
					}
				}

				checkSlideCount();
				updateGalleryView();
			};


			const updateGalleryView = () => {
				objects.$gallerySet.each((i, gs) => {
					let $gs = $(gs);
					let $gsItems = $gs.find('.gallery-item:visible');
					let $heading = $gs.find('[data-heading]');
					let $carousel = $($gsItems.data('target')).find('.carousel');

					$gs.data('filtered', true);

					$gsItems.each((index, el) => {
						$(el).attr('data-index', index);
					});

					$('.slick-arrow').attr('aria-disabled', false);

					if ($gsItems.length === 0) {
						$heading.hide();
					} else {
						$heading.show();

						if ($gsItems.length <= 2) {
							$gs.addClass('gallery-set-stacked');
						}
						else {
							$gs.removeClass('gallery-set-stacked');
						}
					}
				});


				let prev = $($carousel).find('.slick-prev');
				let next = $($carousel).find('.slick-next');

				prev.html('<span class="icon-svg"><svg><use xlink:href="#icon-arrow-left"></use></svg></span>');
				next.html('<span class="icon-svg"><svg><use xlink:href="#icon-arrow-right"></use></svg></span>');

				if (next.length > 0) {
					next.insertAfter($($carousel));
					prev.insertAfter($($carousel));
				}
			};

			const displayModalCarousel = (e) => {
				let item = $(e.relatedTarget);
				let itemIndex = item.attr('data-index');
				let $carousel = $(item.data('target')).find('.carousel');

				if ($carousel.hasClass('slick-initialized')) {
					$carousel.slick('slickGoTo', itemIndex);
				}
			};


			// Intitialize
			$($carousel).slick(slickOptions);
			checkSlideCount();
			updateGalleryView();

			// Events
			objects.$filter.find('input').on('change', updateFilteredState);

			$('.modal').on('shown.bs.modal', (e) => {
				displayModalCarousel(e);
			});
		};


		this.firstRun = () => {
			console.info('~~~ DedicatedGallery Module ~~~');

			if (!objects.$galleryModals.hasClass('slick-initialized')) {
				objects.$galleryModals.each((i, gm) => {
					this.initCarousel($(gm), options);
				});
			}

			$el.on('keypress', (e) => {
				if (e.which === 13) {
					let focusedElement = $(document.activeElement);
					if (focusedElement.is('[data-toggle="modal"]')) {
						focusedElement.trigger('click');
					}
				}
			});
		};
	}

	name() {
		return 'DedicatedGallery';
	}

	init() {
		this.firstRun();
	}

}

export default DedicatedGallery;

