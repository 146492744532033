class VacationInspiration {

	/**
	 * OfferList Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - VacationInspiration instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const objects = {
			$vacationInspiration: $el.find('.vacation-inspiration-callout')
		};

		this.firstRun = () => {
			console.info('~~~ VacationInspiration Module ~~~');

			for (let callout of objects.$vacationInspiration) {
				callout.addEventListener('click', (e) => {

					window.dataLayer.push({
						'event': 'vacationInspirationCalloutClicked',
						'data-gtm-module': callout.getAttribute('data-gtm-module'),
						'data-gtm-view': callout.getAttribute('data-gtm-view')
					});
				});
			}
		};
	}

	name() {
		return 'VacationInspiration';
	}

	init() {
		this.firstRun();
	}

}

export default VacationInspiration;

