import Loader from '../modules/Loader';
import moment from 'moment';

class Stays {
	/**
	 * Stays Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Stays instance
	 */

	constructor($, $el) {

		const objects = {
			$loader: $el.find('[data-loader]'),
			$stayArea: $el.find('.js-stay-area'),
			$templatePrevious: $el.find('.js-template-previous'),
			$templateUpcoming: $el.find('.js-template-upcoming'),
			objLoader: null
		};

		this.resize = () => {
		};


		this.loadData = () => {
			// // Check if other instance has already loaded stay data.
			if (Stays._loading) return;
			Stays._loading = true;

			const url = $el.data('action');

			objects.objLoader.startLoader();
			$.getJSON({url: url}, {_: new Date().getTime()})
				.done((data) => {
					$(document)[0].dispatchEvent(new CustomEvent('staydataloaded', {
						'detail': data
					}));
				})
				.fail((jqXHR, textStatus) => {
					//console.log(textStatus);
				})
				.always(() => {
					objects.objLoader.stopLoader();
					Stays._loading = false;
				});
		};

		this.onStayDataLoaded = (event) => {
			// Set mode
			const data = event.detail;
			const now = moment();
			const pastMode = $el.data('show-stays') === 'past';
			const stays = data['stays'];
			stays.forEach((stay) => {
				const arrival = moment(stay.arrival);
				const departure = moment(stay.departure);
				const $newStay = pastMode
					? objects.$templatePrevious.children().clone()
					: objects.$templateUpcoming.children().clone();
				if (pastMode && arrival.isAfter(now)) return;
				if (!pastMode && departure.isBefore(now)) return;

				// Fill in stay fields.
				$newStay.find('.js-image-header').attr('src', stay['image_header']);
				$newStay.find('.js-image-header').attr('alt', stay['image_header_alt_text']);
				$newStay.find('.js-property-name').text(stay['property_name']);
				$newStay.find('.js-property-address').text(stay['property_address']);
				$newStay.find('.js-confirmation').text(stay['synxis_confirmation_number']);
				$newStay.find('.js-room-description').text(stay['room_type_description']);
				$newStay.find('.js-number-of-guests').text(stay['number_of_guests']);
				$newStay.find('.js-room-count').text('1'); // TODO?
				$newStay.find('.js-arrival-day').text(arrival.format('dddd, '));
				$newStay.find('.js-arrival-date').text(arrival.format('LL'));
				$newStay.find('.js-departure-day').text(departure.format('dddd, '));
				$newStay.find('.js-departure-date').text(departure.format('LL'));
				$newStay.find('.js-days-till-trip').text(arrival.diff(now, 'days'));
				$newStay.find('.js-property-address').text(stay['property_address']);
				$newStay.find('.js-property-phone').text(stay['property_phone']);
				$newStay.find('.js-map-link').attr('href', stay['map_url']);
				let $reservationLink = $newStay.find('.js-reservation-link');
				$reservationLink.attr('href', stay['reservation_url']);

				if(!stay['synxis_confirmation_number']) {
					$newStay.find('.js-conf-line').hide();
				}

				if(stay['reservation_status']) {
					let $status = $newStay.find('.js-status');
					$status.text(stay['reservation_status']);
					$status.parent().show();

					if(stay['reservation_status'] === 'Cancelled') {
						$('[data-thank-you]').hide();
					} else {
						$('[data-thank-you]').show();
					}
				}

				let $call = $newStay.find('.js-call-modify-reservation');
				if(stay['synxis_confirmation_number'] || pastMode) {
					$call.hide();
					$reservationLink.show();
				} else {
					if($call && $call.html()) {
						$call.html($call.html().replace('{hotel}', stay['property_name']));
						$newStay.find('.js-phone').text(stay['property_phone']);
						$call.show();
					}
					$reservationLink.hide();
				}

				objects.$stayArea.append($newStay);
				$el.find('.js-no-results').hide();
			});
		};

		this.firstRun = () => {
			console.info('~~~ Stays Module ~~~');

			// Load indicator.
			objects.objLoader = new Loader($, objects.$loader);

			$(document).on('staydataloaded', this.onStayDataLoaded);

			this.loadData();
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'Stays';
	}

	init() {
		this.firstRun();
	}

}

export default Stays;
