import Filter from '../modules/Filter';

class PressLanding {

	/**
	 * PressLanding Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PressLanding instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {
		const params = {
			searchCacheExpiration: $el.data('property-search-cache-expiration'),
			maxResults: $el.data('property-search-max-results'),
			minQuery: $el.data('property-search-min-query'),
			searchUrl: $el.data('property-search-url'),
			browseUrl: $el.data('property-browse-url')
		};

		const objects = {
			propertySearch: {
				resultData: {},
				$input: $el.find('#pl-search input[type="search"]'),
				$btnClose: $el.find('[data-action="close"]'),
				$btnSearch: $el.find('[data-action="search"]'),
				$btnBack: $el.find('[data-action="back"]'),
				$noResults: $el.find('#pl-search-no-results'),
				$searchResults: $el.find('#pl-search-results'),
				$search: $el.find('.pl-search'),
				$browse: $el.find('#pl-browse'),
				$browseResults: $el.find('#pl-browse-results'),
				$browseResultsHeading: $el.find('#pl-browse-results-heading'),
				$resultItemTemplate: $el.find('[data-template-search-result]'),
				$destinationListOptions: $el.find('#pl-browse-destinations a'),
				$browseResultList: $el.find('#pl-browse-results .list-options'),
				$propertyListOptions: $el.find('.list-options input[type="radio"]'),
			},

			$form: $el.find('form'),
			$searchInput: $el.find('input[type="search"]'),
			$btnSearch: $el.find('[data-action="search"]'),
			$btnClose: $el.find('[data-action="close"]'),
			$searchLabel: $el.find('.search-label'),
			$faqs: $el.find('[data-faq]'),
			$faqNav: $el.find('[data-module="NavTabs"]'),
			$faqSections: $el.find('[data-faq-section]'),
			$faqResults: $el.find('[data-faq-section="search"]'),
			$noResults: $el.find('.no-results'),
			$filter: $el.find('[data-module="Filter"]'),
			$pressRelease: $el.find('[data-target="[data-press-release]"]'),
			$checkboxes: $el.find('.custom-control-input'),
			objFilter: null
		};

		let searchCacheInterval = 0;
		const searchCacheExpiration = params.searchCacheExpiration || 5000; //client caching interval in milliseconds
		const maxResults = params.maxResults || 5;
		const minQuery = params.minQuery || 2;

		this.clearSearchCache = () => {
			console.log('clearing search cache');
			objects.propertySearch.resultData = {};
		};

		this.handleFilterChange = () => {
			console.log('handle filter--');
			objects.$faqResults.empty();
			objects.$faqResults.hide();
			objects.$noResults.hide();
			objects.$faqSections.show();


			objects.$searchLabel.find('span:nth-child(1)').show();
			objects.$searchLabel.find('span:nth-child(2)').hide();
		};

		/**
		 * performPropertySearch()
		 * Searches Properties using keyword
		 * @param {string} query -  Keyword value to search
		 */
		this.performPropertySearch = (query) => {

			// reset cache interval
			if (searchCacheInterval) clearInterval(searchCacheInterval);

			const results = objects.propertySearch.resultData;

			// check for value already existing in cache
			if (query.length >= minQuery && !(query in results)) {

				// query not in cache - need to fetch
				let client = params.searchUrl.replace(/{query}/g, query);
				$.ajax({
					cache: false,
					type: 'GET',
					url: client,
					beforeSend: function (xhr) {
						if (xhr && xhr.overrideMimeType) {
							xhr.overrideMimeType('application/json;charset=utf-8');
						}
					},
					dataType: 'json'
				})
					.done((res) => {
						results[query] = res.data;
						console.log('api result: ', results);
						this.renderBrowseResults(query);
					})
					.fail((err) => {
						//console.log('Error searching for property: ', err);
					})
					.always(() => {});

			} else if (query in results) {
				// query already performed with data in cache
				console.log('cache result: ', results[query]);
				this.renderBrowseResults(query);
			} else {
				console.log('no query value');
			}
		};

		this.renderBrowseResults = (query) => {
			const results = objects.propertySearch.resultData;

			// clear displayed results
			objects.propertySearch.$searchResults.empty();

			// output dataset to result list
			if (results[query] && Object.keys(results[query]).length > 0) {

				objects.propertySearch.$browse.hide();

				results[query].forEach((obj,i) => {
					if ( i < maxResults) {
						let $item = objects.propertySearch.$resultItemTemplate.clone().removeAttr('id');
						$item.find('input').attr('data-display-name', obj.name);
						$item.find('input').val(obj.id);
						$item.find('[data-label]').text(obj.name);
						objects.propertySearch.$searchResults.append($item);
					}
				});
				objects.propertySearch.$searchResults.show();
				objects.propertySearch.$noResults.hide();
				objects.objFilter.detectFilters();

				// set timer to clear cache after each search performed
				searchCacheInterval = setTimeout(this.clearSearchCache, searchCacheExpiration);

			} else {
				// no results
				objects.propertySearch.$searchResults.hide();

				if (query.length >= minQuery) {
					objects.propertySearch.$noResults.show();
					objects.propertySearch.$noResults.text(objects.propertySearch.$noResults.data('label').replace(/{x}/g, query));
				} else {
					objects.propertySearch.$browse.show();
				}
			}
			// change view when min query requirement is met
			if (query.length >= minQuery) {
				objects.propertySearch.$btnClose.show();
			} else {
				objects.propertySearch.$btnClose.hide();
			}
		};


		this.handleSearchInputChange = (e) => {

			// hand onto currently selected values.
			let selectedValues = [];
			objects.propertySearch.$searchResults.find('.custom-control-input:checked').each((i, e) => {
				selectedValues.push({
					text: $(e).nextAll('.custom-control-description').text(),
					value: e.value
				});
			});

			// clear currently selected values.
			objects.propertySearch.$searchResults.find('.custom-control-input')
				.prop('checked', false)
				.trigger('change');


			let query = $(e.currentTarget).val().toLowerCase();
			if (query.length === 0) {
				objects.propertySearch.$browse.show();
				objects.propertySearch.$noResults.hide();				
				objects.$btnSearch.show();
				objects.$btnClose.hide();
			} else {
				objects.$btnSearch.hide();
				objects.$btnClose.show();
				objects.propertySearch.$browse.hide();
			
				if (query.length >= 3) {
					this.performPropertySearch(query);
					
					// re-check anything that was previously checked and is still available.
					objects.propertySearch.$searchResults.find('.custom-control-input').each((i, e) => {
						var checked = selectedValues.some((selected) => {
							return selected.text === $(e).nextAll('.custom-control-description').text() && selected.value === e.value;
						});
						if (checked)
							$(e).prop('checked', true).trigger('change');
					});
				} else {
					objects.propertySearch.$noResults.hide();	
					objects.propertySearch.$searchResults.hide();
				}
			}
		};

		this.getDestinationProperties = (destinationId) => {
			
			console.log(`get destination properties: ${destinationId}`);

			let query = destinationId;
			let results = objects.propertySearch.resultData;

			objects.propertySearch.$search.hide();
			objects.propertySearch.$browse.hide();
			objects.propertySearch.$browseResults.show();

			// check for value already in cache
			if (!(query in results)) {

				// query not in cache - need to fetch
				let client = params.browseUrl.replace(/{destinationId}/g, destinationId);
				$.ajax({
					cache: false,
					type: 'GET',
					url: client,
					beforeSend: function (xhr) {
						if (xhr && xhr.overrideMimeType) {
							xhr.overrideMimeType('application/json;charset=utf-8');
						}
					},
					dataType: 'json'
				})
					.done((res) => {
						results[query] = res.data;
						console.log('api result: ', results);
						this.renderSearchResults(query);
					})
					.fail((err) => {
						//console.log('Error browsing for property: ', err);
					})
					.always(() => {});

			} else if (query in results) {
				// query already performed with data in cache
				console.log('cache result: ', results[query]);
				this.renderSearchResults(query);
			} else {
				console.log('no query value');
			}
		};

		this.renderSearchResults = (query) => {
			const results = objects.propertySearch.resultData;
			objects.propertySearch.$browseResultList.empty();

			// output dataset to result list
			if (results[query] && Object.keys(results[query]).length > 0) {

				results[query].forEach((obj) => {
					let $item = objects.propertySearch.$resultItemTemplate.clone().removeAttr('id');
					$item.find('input').attr('data-display-name', obj.name);
					$item.find('input').val(obj.id);
					$item.find('[data-label]').text(obj.name);
					objects.propertySearch.$browseResultList.append($item);
				});
				objects.propertySearch.$browseResultList.show();
				objects.propertySearch.$browseResults.find('.scroll-container').scrollTop(0);
				objects.objFilter.detectFilters();

				// set timer to clear cache after each search performed
				searchCacheInterval = setTimeout(this.clearSearchCache, searchCacheExpiration);
			}
		};

		this.handleDestinationChange = (e) => {
			e.preventDefault();
			let $selectedOption = $(e.target);
			let destinationId = $selectedOption.data('id');

			// Hide search and destination selection
			objects.propertySearch.$browse.hide();
			objects.propertySearch.$search.hide();
			objects.propertySearch.$searchResults.hide();

			// Show browse results
			objects.propertySearch.$browseResultsHeading.text($selectedOption.text());
			objects.propertySearch.$browseResults.show();
			
			this.getDestinationProperties(destinationId);
		};

		this.handleBackClick = (e) => {
			if (e) {
				e.preventDefault();
			}

			objects.propertySearch.$browseResults.find('.custom-control-input')
				.prop('checked', false)
				.trigger('change');

			// Clear browse results
			objects.propertySearch.$browseResultList.empty();
			objects.propertySearch.$browseResults.hide();

			// Show search and destination selection
			objects.propertySearch.$search.show();		
			objects.propertySearch.$browse.show();
		};

		this.handleCloseSearchClick = () => {
			objects.propertySearch.$searchResults.find('.custom-control-input')
				.prop('checked', false)
				.trigger('change');

			objects.propertySearch.$searchResults.empty();
			objects.propertySearch.$searchResults.hide();
			objects.propertySearch.$noResults.hide();
			objects.propertySearch.$browse.show();
			objects.propertySearch.$btnClose.hide();
			objects.propertySearch.$btnSearch.show();
			objects.$searchInput.val('').focus();			
		};

		this.handleFilterClear = () => {
			this.handleBackClick();
			this.handleCloseSearchClick();
		};


		this.firstRun = () => {

			console.info('~~~ PressLanding Module ~~~');

			objects.objFilter = new Filter($, objects.$filter, utils);
			objects.objFilter.init();
			objects.objFilter.updateResultCount();

			objects.propertySearch.$search.show();
			objects.propertySearch.$browse.show();
			objects.propertySearch.$searchResults.hide();
			objects.propertySearch.$browseResults.hide();
			objects.propertySearch.$btnClose.hide();

			objects.propertySearch.$input.focus();
			objects.propertySearch.$input.on('keyup', this.handleSearchInputChange);
			// objects.propertySearch.$btnSearch.on('click', this.handleSearch);
			objects.propertySearch.$btnClose.on('click', this.handleCloseSearchClick);
			objects.propertySearch.$btnBack.on('click', this.handleBackClick);

			objects.propertySearch.$destinationListOptions.on('click', this.handleDestinationChange);

			objects.$searchInput.on('keyup', this.handleSearchInputChange);
			objects.$filter.on('filterchange', this.handleFilterChange);
			objects.$btnClose.on('click', this.handleFilterChange);
			objects.$pressRelease.on('filterclear', this.handleFilterClear);
		};
	}

	name() {
		return 'PressLanding';
	}

	init() {
		this.firstRun();
	}

}

export default PressLanding;

