// import $ from 'jquery';
// import 'jquery.cookie';
// import 'bootstrap';

class Utils {

	constructor() {

		let _this = this;

		this.constants = {
			size: { small: 640, medium: 768, large: 1024, xlarge: 1500 },
		};

		this.getViewportSize = () => {
			// let width = window.innerWidth;
			let width = document.documentElement.clientWidth;

			if (width < this.constants.size.small) {
				return 'xs';
			} else if (width < this.constants.size.medium ) {
				return 'sm';
			} else if ( width < this.constants.size.large ) {
				return 'md';
			} else if ( width < this.constants.size.xlarge ) {
				return 'lg';
			} else {
				return 'xl';
			}
		};

		// /**
		//  * a11yClick
		//  * This event controls the click, spacebar and enter key for binding
		//  */
		// this.a11yClick = (event) => {
		// 	if (event.type === 'click') {
		// 		return true;
		// 	}
		// 	else if (event.type === 'keypress') {
		// 		const code = event.charCode || event.keyCode;
		// 		if (code === 13) { //TODO: Check this in other browsers (code === 32)
		// 			return true;
		// 		}
		// 	}
		// 	else {
		// 		return false;
		// 	}
		// };

		// /**
		//  * ariaHelp
		//  * This event controls the injection of text into an aria-live control
		//  */
		// this.ariaHelp = (string) => {
		// 	$('#a11y-help').text(string);
		// };

		// /**
		//  * isIOS
		//  * Checks if device is iOS to address potential mobile Safari/iOS issues
		//  */
		// this.isIOS = () => {
		// 	const devices = [
		// 		'iPad Simulator',
		// 		'iPhone Simulator',
		// 		'iPod Simulator',
		// 		'iPad',
		// 		'iPhone',
		// 		'iPod'
		// 	];
		// 	while (devices.length) {
		// 		if ( navigator.platform === devices.pop() ) {
		// 			return true;
		// 		}
		// 	}
		// 	return false;
		// };

		this.getOS = () => {
			let userAgent = window.navigator.userAgent,
				platform = window.navigator.platform,
				macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
				windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
				iosPlatforms = ['iPhone', 'iPad', 'iPod'],
				os = null;

			if (macosPlatforms.indexOf(platform) !== -1) {
				os = 'Mac';
			} else if (iosPlatforms.indexOf(platform) !== -1) {
				os = 'iOS';
			} else if (windowsPlatforms.indexOf(platform) !== -1) {
				os = 'Windows';
			} else if (/Android/.test(userAgent)) {
				os = 'Android';
			} else if (!os && /Linux/.test(platform)) {
				os = 'Linux';
			}

			return os;
		};

		// /**
		//  * sanitize
		//  * This method strips html tags
		//  */
		// this.sanitize = function(str) {
		// 	str = str.replace(/<(?:.|\n)*?>/gm, '');
		// 	str = str.replace(/[|<|>|=|?|:|%|]/gm, '');
		// 	return str;
		// };

		// /**
		//  * encodeURIComponent
		//  * This method escapes quotes and slashes (e.g. for search queries)
		//  */
		// this.encodeURIComponent = (str) => {
		// 	str = str.replace(/"/g, '\\"');
		// 	return encodeURIComponent(JSON.parse(`"${str}"`)).replace(/\(/g, '%28').replace(/\)/g, '%29');
		// };

		// /**
		//  * ajaxLoader
		//  * This method injects a loader to a selected container. This would usually be fired in a beforeSend ajax method as content loads in.
		//  */
		// this.ajaxLoader = (container) => {
		// 	const loader = `
		// 		<div class="ajax-loader">
		// 			<svg class="circular" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/></svg>
		// 		</div>
		// 	`;
		// 	container.html(loader);
		// };

		/**
		 * scrollToTop
		 * This method creates a button that scrolls page to top
		 */
		this.scrollToTop = () => {
			const $backToTop = $(`
				<div class="scroll-to-top-container">
					<button class="scroll-to-top" aria-label="Scroll to top">
						<svg class="icon" focusable="false" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-back-to-top"></use></svg>
					</button>
				</div>
			`);
			$('.footer').before($backToTop);
			const $scrollToTop = $('.scroll-to-top');
			$scrollToTop.on('click', (event) => {
				event.preventDefault();
				this.scrollTo( null, null, null, () => {  } );
			});

			$scrollToTop.on('mouseleave', (event) => {
				$(event.currentTarget).find('use').attr('xlink:href', '#icon-back-to-top');
			});

			$(window).on('scroll', () => {
				let footerOffset = $('footer').offset().top;

				$(window).scrollTop() > 2000 ?
					/* > 2000px from top - show button */
					$scrollToTop.addClass('is-visible'):
					/* <= 2000px from top - hide button */
					$scrollToTop.removeClass('is-visible');

				/* Position absolute button when footer has entered the bottom of the window */
				if ( $(window).scrollTop() + window.innerHeight >= footerOffset ) {
					$('.scroll-to-top-container').css({
						position: 'absolute',
						top: ( footerOffset - 50 )
					});
				} else {
					$('.scroll-to-top-container').removeAttr('style');
				}
			});
		};

		/**
		 * debounce
		 * This method prevents excessive firing from things like scroll and resize handlers. Should be used instead of setTimeout.
		 * @param {function} func - description
		 * @param {Number} wait - timeout in ms
		 * @param {boolean} immediate - call function at start, defaults to false
		 */
		this.debounce = (func, wait, immediate) => {
			let timeout;
			return function() {
				let context = this, args = arguments;
				const later = function() {
					timeout = null;
					if (!immediate){ func.apply(context, args); }
				};
				const callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) {func.apply(context, args);}
			};
		};

		// /**
		//  * Load External Script and add to page
		//  * @param  {[type]}   url      [description]
		//  * @param  {Function} callback [description]
		//  * @return {[type]}            [description]
		//  */
		// this.loadScript = (url) => {
		// 	return new Promise((resolve, reject) => {
		// 		let ready = false;
		// 		const tag = document.getElementsByTagName('script')[0];
		// 		const script = document.createElement('script');
		//
		// 		script.type = 'text/javascript';
		// 		script.src = url;
		// 		script.async = true;
		// 		script.onload = script.onreadystatechange = () => {
		// 			if (!ready && (!this.readyState || this.readyState === 'complete' || this.readyState === 'loaded')) {
		// 				ready = true;
		// 				resolve(this);
		// 			}
		// 		};
		// 		tag.parentNode.insertBefore(script, tag);
		// 		script.onerror = script.onabort = reject;
		// 	});
		// };

		/**
		 * getUrlParameter
		 * This method returns a url parameters value.
		 */
		this.getUrlParameter = (sParam) => {
			let sPageURL = decodeURIComponent(window.location.search.substring(1)),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i;

			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=');

				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : sParameterName[1];
				}
			}
		};

		// /**
		//  * setCookie
		//  * This method sets a cookie
		//  * @param {string} cookieName
		//  * @param {string} cookieValue
		//  * @param {object} settingObj
		//  */
		// this.setCookie = (cookieName, cookieValue, settingObj = null)	=> {
		// 	let defaultObject = { expires: 30, path: '/' };
		// 	if (cookieValue) {
		// 		$.cookie(cookieName, cookieValue, settingObj===null?defaultObject:settingObj);
		// 	} else {
		// 		$.removeCookie(cookieName, { path: '/' });
		// 	}
		// };

		// /**
		//  * getCookie
		//  * This method return a cookie value
		//  * @param {string} cookieName
		//  */
		// this.getCookie = (cookieName)	=> {
		// 	return $.cookie(cookieName);
		// };

		// /**
		//  * Use this interval in all scenarios where you would use setInterval. More performant using RAF
		//  * @param  {callback} fn
		//  * @param  {[number]} delay in milliseconds
		//  * @return {[object]} object.value for cancelling raf
		//  *
		//  * Usage
		//  * timer = utils.requestInterval( this.frame, 500 )
		//  * utils.clearRequestInterval( timer )
		//  *
		//  */
		// this.requestInterval = (fn, delay) => {
		// 	let start = new Date().getTime();
		// 	let handle = {};
		//
		// 	function loop() {
		// 		const current = new Date().getTime();
		// 		const delta = current - start;
		//
		// 		if (delta >= delay) {
		// 			fn.call();
		// 			start = new Date().getTime();
		// 		}
		// 		handle.value = self.requestAnimationFrame(loop);
		// 	}
		//
		// 	handle.value = self.requestAnimationFrame(loop);
		// 	return handle;
		// };
		//
		// this.clearRequestInterval = handle => {
		// 	self.cancelAnimationFrame(handle.value);
		// };

		// /**
		//  * Convert Audio/Video Element's seconds to formatted string
		//  * @param  {[number]} seconds
		//  * @return {[string]} "00:00:00"
		//  */
		// this.secondsToTime = seconds => {
		// 	let h = 0,m = 0,s = 0;
		// 	const Time = x => {
		// 		return {
		// 			map: f => Time(f(x)),
		// 		};
		// 	};
		// 	Time(seconds)
		// 		.map( x => Math.floor(x) )
		// 		.map( x => { h = Math.floor(x / 3600); return x; })
		// 		.map( x => { m = Math.floor(x % 3600 / 60); return x; })
		// 		.map( x => { s = Math.floor(x % 3600 % 60); return x; });
		// 	( h < 10 ) ? h = '0'+ h : null;
		// 	( m < 10 ) ? m = '0'+ m : null;
		// 	( s < 10 ) ? s = '0'+ s : null;
		//
		// 	return (h > 0) ? `${h}:${m}:${s}` :	`${m}:${s}`;
		// };

		/*
		 * Single Listener for resize and scroll. Call all live module functions.
		 * Resize and scroll are called within Classes via this.resize or this.scroll respectively
		 */
		this.listeners = (mod) => {
			let resizes = [], scrolls = [];

			// mod.map( v => {
			if ($.isFunction(mod.resize)) {
				resizes.push(mod.resize);
			}
			if ($.isFunction(mod.scroll)) {
				scrolls.push(mod.scroll);
			}
			// });

			/*
			 *	Resize is debounced, scroll is not. You can deboune the class specific scroll method if you want.
			 */

			$(window).on('resize', _this.debounce( (e) => {
				for (let i = 0; i < resizes.length; i++ ) {
					resizes[i](e);
				}
			}, 250, false));

			$(window).on('scroll', (e) => {
				for (let i = 0; i < scrolls.length; i++ ) {
					scrolls[i](e);
				}
			});
		};

		this.scrollTo = (scrollTarget=null, scrollOffset=null, duration=null, callback=null, scrollingElement=null) => {
			let scrollPos = 0;
			if (!scrollingElement) {
				scrollingElement = $('html, body');
			}
			if (scrollTarget !== null) {
				scrollPos = scrollTarget.offset().top;

				if (scrollOffset !== null) {
					scrollPos = scrollPos + scrollOffset;
				}
			}
			scrollingElement.animate({ scrollTop: scrollPos }, duration === null ? 'slow': duration, callback === null ? null: callback);
		};


		this.setSticky = () => {
			$('[data-set-sticky]').each((i, el) => {
				let $el = $(el);
				let $scrollPos = $el.data('offset');
				let $additionalOffset = $('[data-module="Header"]').height();

				if ($additionalOffset !== null && $additionalOffset !== undefined) {
					$scrollPos = $scrollPos - $additionalOffset;
				}

				if($(window).scrollTop() > $scrollPos) {
					$(el).addClass('fixed-top');
				} else {
					$(el).removeClass('fixed-top');
				}
			});
		};

		this.setStickyOffset = () => {
			$('[data-set-sticky]').each((i, el) => {
				let $el = $(el);
				$el.removeClass('fixed-top');
				$el.data('offset', $el.offset().top);
			});
		};

		this.setTooltips = () => {
			let _utils = new Utils();
			$('[data-toggle="tooltip"]').each((i, el) => {
				let $el = $(el);

				if (_utils.getViewportSize() === 'xs' || _utils.getViewportSize() === 'sm') {
					$el.tooltip({
						placement: 'top'
					});

				} else {
					$el.tooltip({
						placement: 'auto'
					});
				}
			});

			$('[data-toggle="tooltip"]').on('keypress',function(e) {
				if(e.which == 13) {
					$(this).tooltip('toggle');
				}
			});
		};

		/**
		 * initViewMore()
		 * Sets up functionality for any 'view more' list buttons
		 */
		this.initViewMore = () => {
			$('[data-view-more]').each((i, el) => {
				$(el).unbind('click');
				let $el = $(el);
				let targetSelector = $el.data('view-more');
				let initialCount = $el.data('initial-count');
				let displayClass = $el.data('display-class');
				let moreCount = $el.data('count');

				// reset by removing display (hiding) class
				const $targetAll = $(`${targetSelector}`);
				$targetAll.removeClass(displayClass);

				// get total count of items being shown
				let $targetItems = $(`${targetSelector}:visible`);

				if(targetSelector === '.experiences-listing') {
					$targetItems = $targetAll.not('.hidden');
				}


				// hide separators (if used)
				let separatorSelector = $el.data('separator');

				// add display (hiding) class for items greater than initial count
				for (let j=initialCount; j<($targetItems.length); ++j) {
					$($targetItems[j]).addClass(displayClass);

					if (separatorSelector) {
						let $separator = $($targetItems[j]).prev(separatorSelector);
						if ($separator) {
							$separator.addClass(displayClass);
						}
					}
				}

				if ($targetItems.length <= initialCount) {
					$el.hide();
					$el.removeClass('d-md-inline-block');
				} else {
					$el.show();
					$el.addClass('d-md-inline-block');
				}

				var focusTarget;

				let currentCount = initialCount;
				$el.on('click', (e) => {	
					e.preventDefault();
					if (currentCount < $targetItems.length) {
						focusTarget = $($targetItems[currentCount]).find("[data-focus]");
						if (!focusTarget) {
							focusTarget = $($targetItems[currentCount]).find('button');
						}
						
						for (let m=currentCount; m<(currentCount+moreCount); ++m) {
							$($targetItems[m]).fadeIn('fast');
							$($targetItems[m]).removeClass(displayClass);
							$($targetItems[m]).css('display', '');

							// show separators when associated items are visible
							if (separatorSelector) {
								let $separator = $($targetItems[m]).prev(separatorSelector);
								if ($separator) {
									$separator.removeClass(displayClass);
								}
							}
						}
						currentCount += moreCount;
					}
					// hide button once all items are visible
					if (currentCount >= $targetItems.length) {
						$el.hide();
						$el.removeClass('d-md-inline-block');
					}
					
					if (!$(focusTarget).is(':visible')) {
						focusTarget = focusTarget.siblings('.headline').find('a');
					}

					// set focus to first newly-revealed element
					$(focusTarget).focus();
				});
			});
		};

		/**
		 * initScrollTo()
		 * Sets up functionality for any smooth 'scroll to' links
		 */
		this.initScrollTo = () => {
			let _utils = new Utils();
			let $headerSize = 0;

			if (_utils.getViewportSize() === 'xs' || _utils.getViewportSize() === 'sm' || _utils.getViewportSize() === 'md') {
				$headerSize = $('.navbar-header').height();
			}


			$('[data-scroll-to]').each((i, el) => {
				$(el).on('click', (e) => {
					let dest = $(e.target).attr('href');
					_utils.scrollTo($(dest), -($headerSize));
				});
			});
		};

		/**
		 * initSetSticky()
		 * Sets up functionality for sticky elements
		 */
		this.initSetSticky = () => {
			this.setStickyOffset();

			this.listeners({
				scroll: this.setSticky,
				resize: () => {
					this.setStickyOffset();
					this.setSticky();
					$('[data-set-sticky]').removeClass('resizing');
				}
			});

			$(window).on('resize', function() {
				$('[data-set-sticky]').addClass('resizing');
			});
		};

		/** moveModals()
		 * Moves modal markup to container existing at top-level of page to prevent layout/z-index issues
		 */
		this.moveModals = () => {
			let $modals = $('[data-module="Modal"]');
			let $container = $('#modal-container');
			if ($container) $modals.appendTo($container);
		};

		/**
		 * trimBadges()
		 * Sets up functionality for trim all descriptive badges to 3 max
		 */
		this.trimBadges = () => {
			let $badge = $('.badge-descriptive');

			if($badge.parents('ul').length) {
				let $list = $badge.parents('ul');

				$list.each((i, el) => {
					$(el).children('li').slice(3).hide();
				});
			}
		};
	}

	// secondsToTime(seconds) {
	// 	this.secondsToTime(seconds);
	// }
	//
	// requestInterval(fn, delay) {
	// 	this.requestInterval(fn, delay);
	// }
	//
	// clearRequestInterval(handle) {
	// 	this.clearRequestInterval(handle);
	// }

	// constants() {
	// 	return this.constants;
	// }

	name() {
		return 'Utils';
	}

}

export default Utils;
