class ActivationEmail {

	/**
	 * ActivationEmail Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PasswordReset instance
	 */

	constructor($, $el) {

		const objects = {
			$alertError: $el.find('.js-alert-error'),
			$alertNotFoundError: $el.find('.js-alert-no-acct'),
			$alertNetworkError: $el.find('.js-network-error'),
			$alertSuccess: $el.find('.js-alert-success'),
			$form: $el.find('form')
		};

		this.firstRun = () => {
			console.info('~~~ ActivationEmail Module ~~~');
			objects.$form.submit(this.handleActivation);
		};

		this.handleActivation = (event) => {
			event.preventDefault();

			$el.find('.form-group.has-danger').removeClass('has-danger');
			$el.find('.form-control-feedback').addClass('d-none');

			const $form = $(event.target);
			$form.find('.btn').addClass('disabled');
			const url = $form.attr('action');
			const data = $form.serialize();
			objects.$alertSuccess.addClass('d-none');
			objects.$alertError.addClass('d-none');
			objects.$alertNotFoundError.addClass('d-none');
			objects.$alertNetworkError.addClass('d-none');
			$.ajax({
				cache: false,
				type: 'POST',
				url: url,
				data: data,
				dataType: 'json'
			})
				.done(this.activateSuccess)
				.fail(this.activateFail);
		};

		this.activateSuccess = (data, textStatus, jqXHR) => {
			if (data['result_code'] === 200) {
				console.info('Forgot password succeeded.');
				objects.$alertSuccess.removeClass('d-none');
				objects.$form.find('.btn').addClass('d-none');
			} else {
				this.activateFail(jqXHR, textStatus, data);
			}
		};

		this.activateFail = (jqXHR, textStatus, errorThrown) => {
			console.error(`Forgot password failed: ${textStatus} : ${errorThrown}`);
			// if(textStatus === 'success') {
			// 	const resultCode = errorThrown['result_code'];
			const resultCode = jqXHR['status'];
			if (resultCode) {
				switch (resultCode) {
					case 418:
						// User already exists.
						objects.$alertError.removeClass('d-none');
						objects.$alertNotFoundError.addClass('d-none');
						objects.$alertSuccess.addClass('d-none');
						objects.$alertNetworkError.addClass('d-none');
						break;
					case 400:
						// User does not exist.
						objects.$alertNotFoundError.removeClass('d-none');
						objects.$alertError.addClass('d-none');
						objects.$alertSuccess.addClass('d-none');
						objects.$alertNetworkError.addClass('d-none');
						break;
					default:
						// Other error show generic message.
						objects.$alertError.addClass('d-none');
						objects.$alertNotFoundError.addClass('d-none');
						objects.$alertSuccess.addClass('d-none');
						objects.$alertNetworkError.removeClass('d-none');
				}
			}
			// } else {
			// 	objects.$alertError.addClass('d-none');
			// 	objects.$alertSuccess.addClass('d-none');
			// 	objects.$alertNetworkError.removeClass('d-none');
			// }

			objects.$form.find('.btn').removeClass('disabled');
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'ActivationEmail';
	}

	init() {
		this.firstRun();
	}

}

export default ActivationEmail;
