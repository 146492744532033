// import Carousel from '../modules/Carousel';
import moment from 'moment';
import CustomEvent from 'custom-event';

class OfferSummary {

	/**
	 * RoomDetail Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - RoomDetail instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils, rateApi) {

		// noinspection JSUnusedLocalSymbols
		let _rateApi = rateApi;
		let qs = new URLSearchParams(window.location.search);

		const objects = {
			numberFormat: Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2}),
			numberFormatNoDecimal: Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0}),
			propertyId: $el.data('property-id'),
			$rate: $el.find('.js-room-rate'),
			$priceContainer: $el.find('.price-container'),
			$strikeRate: $el.find('.js-strike-rate'),
			$offerType: $el.data('discount-type'),
			$offerAmt: $el.data('discount-amt'),
			startDate: qs.get('start-date'),
			$bookButton: $el.find('[data-action="book"]'),
			endDate: qs.get('end-date'),
			$discountAriaLabel: $el.data('discount-aria-label'),
			$showStrikeThrough: $el.data('show-strike-through')
		};

		this.firstRun = () => {
			console.info('~~~ Offer Summary Module ~~~');

			$(document).on('reservationdatesselected', this.handleDateChange);

			$(document).on('room-rates-loaded', this.handleRateLoad);

			this.initPrice();
		};

		this.handleDateChange = (event) => {
			const startDate = moment(event.detail.startDate).format('YYYY-MM-DD');
			const endDate = moment(event.detail.endDate).format('YYYY-MM-DD');
			// this.updatePrice(startDate, endDate);
			objects.$rate.text('');
			objects.$priceContainer.addClass('d-none');
			objects.$strikeRate.addClass('d-none');
			_rateApi.requestData(startDate, endDate);
		};

		this.handleRateLoad = (event) => {
			if(typeof event.detail.data === 'undefined') {
				console.log('Event Data Undefined');
				return;
			}

			let hotels = event.detail.data.hotels;
			// console.log("Rate Load: "+JSON.stringify(hotels));
			// console.log("Matching P: "+objects.propertyId);

			let matchFound = false;
			for (let i = 0; i < hotels.length; ++i) {
				let hotel = hotels[i];
				if(parseInt(hotel.hotelId) === parseInt(objects.propertyId)) {
					//console.log('OS: Hotel Matches '+hotel.hotelId+' Average Rate: '+hotel.averageRate+' Avail: '+hotel.availability+' AvQty: '+hotel.availabilityQuantity);

					if (hotel.availability === 'open' || objects.$barRate && objects.$barRate.length > 0 && hotel.availability === 'minstay') {
						objects.$rate.text('$' + objects.numberFormatNoDecimal.format(Math.ceil(hotel.averageRate)) + '*');
						objects.$priceContainer.removeClass('d-none');

						// Strike Through Pricing
						let strikePrice = -1;
						if(objects.$offerType === 'percent-off' && !isNaN(objects.$offerAmt) ) {
							strikePrice = (hotel.averageRateNoFees / (1 - objects.$offerAmt / 100)) + hotel.averageFee;
							//console.log("offer type: percent-off | offer amt: ", objects.$offerAmt);
						} else if(objects.$offerType === 'amt-off' && !isNaN(objects.$offerAmt)) {
							strikePrice = (hotel.averageRate + objects.$offerAmt) + hotel.averageFee;
							//console.log("offer type: amt-off | offer amt: ", objects.$offerAmt);
						}

						if(strikePrice > 0 && strikePrice > hotel.averageRate) {
							objects.$strikeRate.text('$' + objects.numberFormatNoDecimal.format(Math.ceil(strikePrice)));
							objects.$strikeRate.removeClass('d-none');
						}

						if(objects.$showStrikeThrough){
							let discountAriaLabel = objects.$discountAriaLabel;
							discountAriaLabel = discountAriaLabel.replace('new-price',('$' + objects.numberFormatNoDecimal.format(Math.ceil(hotel.averageRate))));
							discountAriaLabel = discountAriaLabel.replace('original-price',('$' + objects.numberFormatNoDecimal.format(Math.ceil(strikePrice))));
							console.log(discountAriaLabel);
							document.getElementById(hotel.hotelId).setAttribute('aria-label', discountAriaLabel);
						}


						objects.$bookButton.show();
					} else {
						objects.$bookButton.hide();
					}
					matchFound = true;
				}
			}
			if(!matchFound && objects.$barRate) {
				console.log('No Rate Match for [' + objects.propertyId + '] showing BAR');
				// objects.$barRate.removeClass('d-none');
			}

			let myLoadEvent = new CustomEvent('property-detail-rate-updated');
			$(document)[0].dispatchEvent(myLoadEvent);
		};

		this.initPrice = () => {
			// Check date from parameters
			let paramStartDate = moment(objects.startDate);
			let paramEndDate = moment(objects.endDate);
			let paramDatesValid = paramStartDate.isValid() && paramEndDate.isValid();

			// If parameters are not valid then read dates from local storage
			// If dates are set, call updatePrice
			const startDate = paramDatesValid ? paramStartDate : moment(localStorage.getItem('startDate'));
			const endDate = paramDatesValid ? paramEndDate : moment(localStorage.getItem('endDate'));

			if (startDate.isValid() && endDate.isValid()) {
				// this.updatePrice(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
				// create event to request data
				const ev = new CustomEvent('reservationdatesselected', {
					'detail': {
						source: $el,
						startDate: startDate,
						endDate: endDate
					}
				});
				this.handleDateChange(ev);
				// _rateApi.requestData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
			} else {
				let startStay = moment().startOf('day').add(1,'days');
				let endStay = moment().startOf('day').add(5,'days');
				// create event to request data
				const ev = new CustomEvent('reservationdatesselected', {
					'detail': {
						source: $el,
						startDate: startStay,
						endDate: endStay
					}
				});
				this.handleDateChange(ev);
				// _rateApi.requestData(tomorrowStart.format('YYYY-MM-DD'), tomorrowEnd.format('YYYY-MM-DD'));
			}
		};

		this.resize = () => {
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'OfferSummary';
	}

	init() {
		this.firstRun();
	}

}

export default OfferSummary;
