class PageHelpful {

	/**
	 * PageHelpful Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PageHelpful instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const objects = {
			$btn: $el.find('.btn')
		};

		this.resize = () => {

		};

		this.showHelperTooltip = () => {
			utils.setTooltips();
		};

		this.setHelpfulStatus = (e) => {
			let $this = $(e.currentTarget);

			$this.toggleClass('selected');
			sessionStorage.setItem('helpful', true);

			if(!$this.hasClass('selected')) {
				sessionStorage.removeItem('helpful');
			}
		};

		this.checkSession = () => {
			if(sessionStorage.getItem('helpful')) {
				objects.$btn.addClass('selected');
			}
		};

		this.firstRun = () => {
			console.info('~~~ 👍 Page Helpful Module 👍 ~~~');

			this.showHelperTooltip();
			this.checkSession();

			objects.$btn.on('click', this.setHelpfulStatus);
		};
	}

	name() {
		return 'PageHelpful';
	}

	init() {
		this.firstRun();
	}

}

export default PageHelpful;
