class Feature {

	/**
	 * Feature Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Feature instance
	 * @param {object} Utils - Imports from Utils
	 */

	constructor($, $el) {
		this.resize = () => { };

		this.firstRun = () => {
			console.info('~~~ Feature Module ~~~');
		};
	}

	name() {
		return 'Feature';
	}

	init() {
		this.firstRun();
	}

}

export default Feature;

