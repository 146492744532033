import Login from '../modules/Login';

class SignUp {

	/**
	 * SignUp Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - SignUp instance
	 */

	constructor($, $el) {

		const objects = {
			$form: $el.find('form'),
			$btn: $el.find('button.btn'),
			$alertError: $el.find('#alert-sign-up-error'),
			$alertNetworkError: $el.find('.js-network-error'),
			$alertSuccess: $el.find('#alert-sign-up-success'),
			$feedbackEmailFail: $el.find('#feeedback-email'),
			$feedbackPasswordMismatch: $el.find('#feeedback-password-mismatch'),
			$feedbackUserExists: $el.find('#feeedback-user-exists'),
			$password: $el.find('#input-signup-password'),
			$passwordConfirmation: $el.find('#input-signup-confirm-password')
		};

		this.firstRun = () => {
			console.info('~~~ SignUp Module ~~~');
			$el.submit(this.handleSignUp);
			$el.find('.btn-facebook').on('click', Login.connectToFacebook);
			$el.find('.btn-google').on('click', Login.connectToGoogle);
			$el.find('.js-telephone-field').hide();
		};

		this.handleSignUp = (event) => {
			event.preventDefault();
			if (this.validate()) {
				$el.find('.form-group.has-danger').removeClass('has-danger');
				$el.find('.form-control-feedback').addClass('d-none');
				// POST to registerUser action.
				objects.$btn.addClass('disabled');
				const url = objects.$form.attr('action');
				const data = objects.$form.serialize();
				objects.$alertError.addClass('d-none');
				objects.$alertNetworkError.addClass('d-none');
				$.ajax({
					cache: false,
					type: 'POST',
					url: url,
					data: data,
					dataType: 'json'
				})
					.done(this.signUpSuccess)
					.fail(this.signUpFail);
			}
		};

		this.signUpSuccess = (data, textStatus, jqXHR) => {
			if (data['result_code'] === 200) {
				console.info('SignUp succeeded.');
				objects.$alertSuccess.removeClass('d-none');
				$('.js-sign-up-form').addClass('d-none');
				$('.js-sign-up-footer').addClass('d-none');
				$('.js-sign-up-title').addClass('d-none');
				$('.js-activation-success').removeClass('d-none');
				// location.reload();
			} else {
				this.signUpFail(jqXHR, textStatus, data);
			}
		};

		this.signUpFail = (jqXHR, textStatus, errorThrown) => {
			console.error(`SignUp failed: ${textStatus} : ${errorThrown}`);
			// $el.find('.alert.alert-danger.d-none').removeClass('d-none');
			const resultCode = errorThrown['result_code'];
			if (resultCode) {
				switch (resultCode) {
					case 517:
					case 518:
					case 519:
						// User already exists.
						objects.$feedbackUserExists.removeClass('d-none').closest('.form-group').addClass('has-danger');
						break;
					case 520:
						// Could not send email:
						objects.$feedbackEmailFail.removeClass('d-none').closest('.form-group').addClass('has-danger');
						break;
					default:
						// Other error show generic message.
						objects.$alertError.removeClass('d-none').html(errorThrown['result_text']);
						// objects.$alertNetworkError.removeClass('d-none');
				}
			} else {
				objects.$alertNetworkError.removeClass('d-none');
			}

			objects.$btn.removeClass('disabled');
		};

		this.validate = () => {
			// Check passwords.
			const password = objects.$password.val();
			const passwordConfirm = objects.$passwordConfirmation.val();
			if (password !== passwordConfirm) {
				objects.$feedbackPasswordMismatch.removeClass('d-none').closest('.form-group').addClass('has-danger');
				return false;
			}
			return true;
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'SignUp';
	}

	init() {
		this.firstRun();
	}

}

export default SignUp;
