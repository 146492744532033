import moment from 'moment';
import CustomEvent from 'custom-event';

class PropertyDetail {

	/**
	 * RoomDetail Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - RoomDetail instance
	 * @param {object} utils - Utils instance
	 * @param {object} rateApi - RateApi
	 */

	constructor($, $el, utils, rateApi) {

		// noinspection JSUnusedLocalSymbols
		let qs = new URLSearchParams(window.location.search);

		const objects = {
			numberFormat: Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2}),
			numberFormatNoDecimal: Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0}),
			propertyId: $el.data('property-id'),
			rateUrl: $el.data('rate-url'),
			$available: $el.find('.js-available'),
			$availableHide: $el.find('.js-available-hide'),
			$availableShow: $el.find('.js-available-show'),
			$rate: $el.find('.js-room-rate'),
			$barRate: $el.find('.js-bar-rate'),
			$carousel: $el.find('.carousel-factory[data-init="false"]'),
			$mlosMsg: $el.find('.js-avail-minstay-msg'),
			$closedMsg: $el.find('.js-avail-closed-msg'),
			$daysadvanceMsg: $el.find('.js-days-advance-msg'),
			$loading: $el.find('.js-loading'),
			$strikeRate: $el.find('.js-strike-rate'),
			$offerType: $el.data('discount-type'),
			$offerAmt: $el.data('discount-amt'),
			$priceContainer: $el.find('.price-container'),
			$bookButtons: $el.find('[data-action="book"]'),
			objCarousel: null,
			startDate: qs.get('start-date'),
			endDate: qs.get('end-date'),
			$priceArea: $el.find('.price-area'),
			$discountAriaLabel: $el.data('discount-aria-label'),
			$showStrikeThrough: $el.data('show-strike-through')
		};

		this.firstRun = () => {
			console.info('~~~ PropertyDetail Module ~~~');

			$(document).on('reservationdatesselected', this.handleDateChange);

			$(document).on('room-rates-loaded', this.handleRateLoad);

			this.initPrice();
		};

		this.handleDateChange = (event) => {
			const startDate = moment(event.detail.startDate).format('YYYY-MM-DD');
			const endDate = moment(event.detail.endDate).format('YYYY-MM-DD');
			// this.updatePrice(startDate, endDate);
			objects.$rate.text('');
			objects.$priceContainer.addClass('d-none');
			objects.$strikeRate.addClass('d-none');
			objects.$bookButtons.addClass('disabled');
			objects.$priceArea.removeClass('d-none');
			if(objects.$barRate) {
				objects.$barRate.addClass('d-none');
				// show loading
				objects.$availableHide.hide();
				objects.$availableShow.hide();
				objects.$mlosMsg.addClass('d-none');
				objects.$closedMsg.addClass('d-none');
				objects.$daysadvanceMsg.addClass('d-none');
				objects.$loading.removeClass('d-none');
			}
			rateApi.requestData(startDate, endDate);
		};

		this.handleRateLoad = (event) => {
			//console.log('PD = handleRateLoad');
			if(typeof event.detail.data === 'undefined') {
				console.log('Event Data Undefined');
				return;
			}

			// clear loading
			objects.$loading.addClass('d-none');

			let hotels = event.detail.data.hotels;
			//console.log("Rate Load: hotels = ",hotels);

			let matchFound = false;
			for (let i = 0; i < hotels.length; ++i) {
				let hotel = hotels[i];
				if(parseInt(hotel.hotelId) === parseInt(objects.propertyId)) {
					//console.log('PD = Hotel Matches '+hotel.hotelId+' Average Rate: '+hotel.averageRate+' Avail: '+hotel.availability+' AvQty: '+hotel.availabilityQuantity);

					let hasBarRate = objects.$barRate && objects.$barRate.length > 0;

					objects.$mlosMsg.addClass('d-none');
					objects.$closedMsg.addClass('d-none');
					objects.$daysadvanceMsg.addClass('d-none');

					if (hotel.availability === 'open') {
						// Show price
						objects.$priceContainer.removeClass('d-none');

						// Strike Through Pricing
						let strikePrice = -1;

						if(objects.$offerType === 'percent-off' && !isNaN(objects.$offerAmt) ) {
							strikePrice = (hotel.averageRateNoFees / (1 - objects.$offerAmt / 100)) + hotel.averageFee;
							//console.log("offer type: percent-off | offer amt: ", objects.$offerAmt);
						}
						else if(objects.$offerType === 'amt-off' && !isNaN(objects.$offerAmt)) {
							strikePrice = (hotel.averageRate + objects.$offerAmt) + hotel.averageFee;
							//console.log("offer type: amt-off | offer amt: ", objects.$offerAmt);
						}

						if(strikePrice > 0 && strikePrice > hotel.averageRate) {
							objects.$strikeRate.text('$' + objects.numberFormatNoDecimal.format(Math.ceil(strikePrice)));
							objects.$strikeRate.removeClass('d-none');
						}

						if(objects.$showStrikeThrough){
							let discountAriaLabel = objects.$discountAriaLabel;
							discountAriaLabel = discountAriaLabel.replace('new-price',('$' + objects.numberFormatNoDecimal.format(Math.ceil(hotel.averageRate))));
							discountAriaLabel = discountAriaLabel.replace('original-price',('$' + objects.numberFormatNoDecimal.format(Math.ceil(strikePrice))));
							console.log(discountAriaLabel);
							document.getElementById(hotel.hotelId).setAttribute('aria-label', discountAriaLabel);
						}

						objects.$rate.text('$' + objects.numberFormatNoDecimal.format(Math.ceil(hotel.averageRate)) + '*');
						objects.$bookButtons.removeClass('disabled');
						objects.$available.removeClass('sold-out');
						objects.$availableHide.hide();
						objects.$availableShow.show();
						objects.$barRate.addClass('d-none');
					} else {
						//console.log('PD = hotel not available');
						if (hasBarRate) {
							// Show BAR rate instead of messaging
							objects.$barRate.removeClass('d-none');
						} else if (hotel.availability === 'minstay') {
							// Show minstay message
							objects.$mlosMsg.removeClass('d-none');
							objects.$mlosMsg.html(objects.$mlosMsg.attr('data-msg').replace('{0}', hotel.availabilityQuantity));
						} else if (hotel.availability === 'close') {
							if(hotel.availabilityQuantity > 0) {
								// Show days-in-advance message
								objects.$daysadvanceMsg.removeClass('d-none');
								objects.$daysadvanceMsg.html(objects.$daysadvanceMsg.attr('data-msg').replace('{0}', hotel.availabilityQuantity));
							} else {
								// Show closed message
								//console.log('PD = hotel not available - Show closed message');
								objects.$closedMsg.removeClass('d-none');
							}
						} else {
							// Didn't match an availability message above...
							console.log(hotel.availability+' needs messaging');
						}
					}

					matchFound = true;
				}
			}
			if(!matchFound) {
				console.log('PD = No Rate Match for [' + objects.propertyId + '] showing BAR');
				objects.$barRate.removeClass('d-none');
			}

			let myLoadEvent = new CustomEvent('property-detail-rate-updated');
			$(document)[0].dispatchEvent(myLoadEvent);
		};

		this.initPrice = () => {
			// Check date from parameters
			let paramStartDate = moment(objects.startDate);
			let paramEndDate = moment(objects.endDate);
			let paramDatesValid = paramStartDate.isValid() && paramEndDate.isValid();

			// If parameters are not valid then read dates from local storage
			// If dates are set, call updatePrice
			const startDate = paramDatesValid ? paramStartDate : moment(localStorage.getItem('startDate'));
			const endDate = paramDatesValid ? paramEndDate : moment(localStorage.getItem('endDate'));

			if (startDate.isValid() && endDate.isValid()) {
				// this.updatePrice(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
				// create event to request data
				const ev = new CustomEvent('reservationdatesselected', {
					'detail': {
						source: $el,
						startDate: startDate,
						endDate: endDate
					}
				});
				this.handleDateChange(ev);
				// rateApi.requestData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
			} else {
				let startStay = moment().startOf('day').add(1,'days');
				let endStay = moment().startOf('day').add(5,'days');
				this.startDate = startStay.toDate();
				this.endDate = endStay.toDate();
				const ev = new CustomEvent('reservationdatesselected', {
					'detail': {
						source: $el,
						startDate: startStay,
						endDate: endStay
					}
				});
				this.handleDateChange(ev);
				// rateApi.requestData(tomorrowStart.format('YYYY-MM-DD'), tomorrowEnd.format('YYYY-MM-DD'));
			}
		};

		this.resize = () => {
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'PropertyDetail';
	}

	init() {
		this.firstRun();
	}

}

export default PropertyDetail;
