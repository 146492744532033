import CustomEvent from 'custom-event';

class Filter {

	/**
	 * Filter Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Filter instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const params = {
			filterTarget: $el.data('target'),
			targetParent: $el.data('target-parent')
		};
		const objects = {
			$filterDrawer: $el.find('.filter-collapse'),
			$checkboxes: $el.find('.custom-control-input'),
			$filterCount: $el.find('[data-filter-count]'),
			$resultCount: $el.find('[data-result-count]'),
			$clearFilters: $el.find('[data-clear-filters]'),
			$keys: $el.find('[data-key]'),
			$levels: $el.find('[data-level]')
		};

		this.disableMobileScroll = () => {
			objects.$filterDrawer.on('show.bs.collapse', () => {
				$('body').addClass('filter-open');
			});

			objects.$filterDrawer.on('hidden.bs.collapse', () => {
				$('body').removeClass('filter-open');
			});
		};

		this.performFiltering = () => {

			console.log('Perform Filtering...');

			// items to toggle
			let $filterTarget = $(params.filterTarget);
			
			let allFilterLevels = objects.$levels.map((i, level) => {
				return $(level).data('level');
			}).get();

			// distinct levels (multiple filter keys can share the same level)
			let filterLevels = [...new Set(allFilterLevels)];

			// initially show all items
			params.targetParent ? $filterTarget.parent().show() : $filterTarget.show();

			let $viewMore = $('[data-view-more]');
			if ($viewMore.length) {
				$viewMore.each((i, e) => {
					let hideClass = $(e).data('display-class');

					if(params.targetParent && params.filterTarget !== '.experiences-listing' && params.filterTarget !== '.vacation-inspiration-listing') {
						$filterTarget.parent().removeClass(hideClass);
					} else {
						$filterTarget.removeClass(hideClass);
					}
				});
			}
			if(params.filterTarget === '.experiences-listing') {
				$filterTarget.removeClass('hidden');
			}

			// iterate over each level/key group and hide items that don't match selected filters
			$.each(filterLevels, (i, level) => {

				// if deeper than the first level, check to see if filters at this level can be disabled
				if (level > 1) {
					this.disableCheckboxes(level);
				}

				// look at each visible item and determine if it satisfies ANY filter within this level
				$filterTarget.each((i, item) => {
					let match = false;
					let $levelCheckboxes = $('.custom-control-input:checked', '[data-level=' + level + ']');

					// if no checked filters, everything visible remains visible
					if ($levelCheckboxes.length === 0) {
						match = true;
					} else {
						// otherwise, check each filter for a match
						$levelCheckboxes.each((i, filter) => {
							let $filter = $(filter);
							let filterKey = $filter.parents('[data-key]').data('key');
							let filterValue = $filter.val();
							let itemValues = $(item).data(filterKey);

							if (itemValues && itemValues.includes(filterValue)) {
								match = true;
								return false; // break the loop
							}
						});
					}

					if (!match) {
						if(params.filterTarget !== '.experiences-listing') {
							params.targetParent ? $(item).parent().hide() : $(item).hide();
						} else {
							$(item).addClass('hidden');
						}
					}
				});
			});

			// update counts
			this.updateFilterCount();

			this.updateResultCount();
			utils.initViewMore();

		};

		this.updateResultCount = () => {
			let resultCount = $(params.filterTarget).filter(':visible').length;
			let resultLabel = resultCount;
			if (objects.$resultCount.data('label')) {
				resultLabel = objects.$resultCount.data('label')[resultCount > 1 ? 2 : resultCount].replace(/{x}/g, resultCount);
			}
			objects.$resultCount.text(`${resultLabel}`);
		};

		this.updateFilterCount = () => {
			let $checked = objects.$checkboxes.filter(':checked');
			objects.$filterCount.text(($checked.length > 0 ? `(${$checked.length})` : ''));
		};

		this.disableCheckboxes = (level) => {
			// filters at this level
			let $levelCheckboxes = $('.custom-control-input', '[data-level=' + level + ']');
			let $filterTarget = $(params.filterTarget);

			// number of selected filters from previous level
			let selectedCount = objects.$checkboxes.filter((i, checkbox) => {
				return $(checkbox).parents('[data-level]').data('level') == (level-1) && $(checkbox).is(':checked');
			}).length;

			// if previous level has no selected filters, all filters at this level are disabled
			if(selectedCount == 0) {
				$levelCheckboxes.prop('disabled', true);
				$levelCheckboxes.prop('checked', false);
			} else {
				// otherwise, examine each filter for a matching item - no matching item results in the disabling of the filter
				$levelCheckboxes.each((i, filter) => {
					let $filter = $(filter);
					let filterKey = $filter.parents('[data-key]').data('key');
					let filterValue = $filter.val();
					let disabled = $filterTarget.filter((i, item) => {
						let itemValues = $(item).data(filterKey);
						return $(item).is(':visible') && itemValues && itemValues.includes(filterValue);
					}).length === 0;
				
					$filter.prop('disabled', disabled);
					if (disabled) {
						$filter.prop('checked', false);
					}
					
				});
			}
		};

		this.handleFilterChange = (e) => {
			e.preventDefault();
			e.stopPropagation();

			let ev = new CustomEvent('filterchange',
				{'detail': { }}
			);
			$el[0].dispatchEvent(ev);

			this.performFiltering();
		};

		this.handleClearFiltersClick = () => {
			objects.$checkboxes.prop('checked', false);
			this.performFiltering();

			let ev = new CustomEvent('filterclear',
				{'detail': { }}
			);
			$el[0].dispatchEvent(ev);
		};

		this.detectFilters = () => {
			objects.$checkboxes.off('change');
			objects.$checkboxes = $el.find('.custom-control-input');
			objects.$checkboxes.on('change', this.handleFilterChange);
		};

		this.filterByUrlParam = () => {
			let paramDestination = utils.getUrlParameter('destination');
			let paramType = utils.getUrlParameter('type');
			let paramTheme = utils.getUrlParameter('theme');

			$(window).on('load', () => {
				$el.find('input:checkbox[value="' + paramDestination + '"]').prop('checked', true);
				$el.find('input:checkbox[value="' + paramType + '"]').prop('checked', true);
				$el.find('input:checkbox[value="' + paramTheme + '"]').prop('checked', true);

				if(paramDestination || paramType || paramTheme) {
					this.performFiltering();
				}
			});
		};

		this.firstRun = () => {

			console.info('~~~ Filter Module ~~~');

			objects.$checkboxes.on('change', this.handleFilterChange);
			objects.$clearFilters.on('click', this.handleClearFiltersClick);

			this.disableMobileScroll();
			this.performFiltering();
			this.filterByUrlParam();

			// if levels not specified, each key acts as a level.
			if (!objects.$levels.length) {
				objects.$keys.each((i, key) => {
					$(key).attr('data-level', i + 1);
				});
				objects.$levels = $el.find('[data-level]');
			} 

			// initially disable all filters deeper than first level
			objects.$checkboxes.filter((i, filter) => {
				return $(filter).parents('[data-level]').data('level') > 1;
			}).prop('disabled', true);
		};
	}

	name() {
		return 'Filter';
	}

	init() {
		this.firstRun();
	}

}

export default Filter;

