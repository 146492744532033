import DOMPurify from 'dompurify';

class SearchResults {

	/**
	 * SearchResults Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - SearchResults instance
	 * @param {object} utils - Utils instance
	 */

	constructor($, $el, utils) {

		let qs = new URLSearchParams(window.location.search);

		const params = {
			q: qs.get('q')
		};
		const objects = {
			resultData: {},
			$body: $('body'),
			$form: $el.find('form'),
			$input: $el.find('.search-input'),
			$submit: $el.find('[data-action="submit"]'),
			$url: $el.find('form').attr('action'),
			$cacheExpiration: $el.find('form').data('cache-expiration'),
			$maxResults: $el.find('form').data('max-results'),
			$resultList: $el.find('[data-search-results]'),
			$resultItemTemplate: $el.find('[data-template-search-result]'),
			$srAssistant: $el.find('[aria-live="assertive"]'),
		};

		const api = objects.$url;

		let cacheInterval = '';
		const cacheExpiration = objects.$cacheExpiration || 5000; //client caching interval in milliseconds
		const maxResults = objects.$maxResults || 10;

		this.resize = () => { };

		this.clearCache = () => {
			console.log('clearing cache');
			objects.resultData = {};
		};

		this.updateLocationUrl = () => {
			window.history.replaceState({}, '', `${location.pathname}?${qs}`);
		};

		this.performSearch = (query) => {

			console.log('Searching for: ', query);

			if (cacheInterval) clearInterval(cacheInterval);

			// clear results
			if (objects.$resultList.length > 0) {
				objects.$resultList.empty();
				objects.$srAssistant.text($('.search-results-label').data('message'));
			}

			// check for value already in cache
			if (query.length > 0 && !(query in objects.resultData)) {

				// query not in cache - need to fetch
				let client = `${api}${query}`;

				$.ajax({
					url: client,
					method: 'GET',
					async: true
				})
					.done((res) => {
						//console.log('>>>> api search result: ', res);	 
						objects.resultData[query] = res;
						var sanitized = DOMPurify.sanitize(res);
						objects.$resultList.html(sanitized);
						objects.$resultList.show();
						objects.$srAssistant.text($('.search-results-label').data('message'));

					})
					.fail((err) => {
						//console.log('Error getting search results: ', err);
					})
					.always(() => {
						cacheInterval = setTimeout(this.clearCache, cacheExpiration);
						utils.initViewMore();
					});

			} else if (query in objects.resultData) {
				// query already performed with data in cache
				//console.log('>>>> cache result: ', objects.resultData[query]);
				var sanitized = DOMPurify.sanitize(objects.resultData[query]);
				objects.$resultList.html(sanitized);
				objects.$resultList.show();
				objects.$srAssistant.text($('.search-results-label').data('message'));
				cacheInterval = setTimeout(this.clearCache, cacheExpiration);
				utils.initViewMore();

			} else {
				console.log('no query value');
				objects.$resultList.hide();
				objects.$srAssistant.text($('.search-results-label').data('message'));
			}

			qs.set('q', query);
			this.updateLocationUrl();

		};

		this.handleInputChange = (e) => {
			let query = $(e.currentTarget).val();
			this.performSearch(query);
		};

		this.firstRun = () => {
			console.info('~~~ Search Results Module ~~~');

			objects.$input.focus();
			objects.$input.on('keyup', this.handleInputChange);

			if (params.q) {
				objects.$input.val(params.q);
				this.performSearch(params.q);
			}
		};
	}

	name() {
		return 'SearchResults';
	}

	init() {
		this.firstRun();
	}

}

export default SearchResults;
