import Loader from '../modules/Loader';

class UserProfile {

	/**
	 * UserProfile Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - UserProfile instance
	 * @param {object} utils - Utils instance
	 */

	constructor($, $el, utils) {

		const objects = {
			$avatarForm: $el.find('#avatar-form'),
			$card: $el.find('.card'),
			$companionTemplate: $el.find('.js-companion-template'),
			$companionRow: $el.find('.js-companion-row'),
			$btnNew: $el.find('[data-action="new"]'),
			$btnAdd: $el.find('[data-action="add"]'),
			$btnEdit: $el.find('[data-action="edit"]'),
			$btnCancel: $el.find('[data-action="cancel"]'),
			$btnSubmit: $el.find('[data-action="submit"]'),
			$cardCollapse: $el.find('.collapse'),
			$countrySelect: $el.find('.js-country-select'),
			$emptyPreferenceLabel: $el.find('.js-empty'),
			$companionEditForm: $el.find('form.js-companion-edit-form'),
			$companionAddForm: $el.find('form.js-companion-add-form'),
			$fullName: $el.find('.js-full-name'),
			$intlInput: $el.find('#personal-info-detail .intl-input'),
			$loader: $(document).find('[data-loader]'),
			$profileForm: $el.find('form.js-profile-form'),
			$preferenceForm: $el.find('form.js-preference-form'),
			$stateInput: $el.find('.js-state-input'),
			$stateSelect: $el.find('.js-state-select'),
			objLoader: null,
			profileUrl: $el.data('profile-action'),
			companionUrl: $el.data('companion-action'),
			$errorContainer: $el.find('.error-container')
		};

		const telInputOptions = {
			autoPlaceholder: 'off',
			initialCountry: navigator.language ? navigator.language.substr(3,2).toLowerCase() : 'us',
			preferredCountries: $el.data('top-countries') ? $el.data('top-countries').split(',') : ['us'],
			separateDialCode: false
		};

		this.resize = () => {
		};

		this.handleNewClick = (e) => {
			e.preventDefault();
			const $card = $(e.currentTarget).parents('.card');
			$card.attr('data-mode', 'new');
			this.renderCard($card);
		};

		this.handleAddClick = (e) => {
			e.preventDefault();
			const $card = $(e.currentTarget).parents('.card');
			$card.attr('data-mode', 'add');
			this.renderCard($card);
		};

		this.handleEditClick = (e) => {
			e.preventDefault();
			const $card = $(e.currentTarget).parents('.card');
			$card.attr('data-mode', 'edit');
			this.renderCard($card);
		};

		this.handleCancelClick = (e) => {
			e.preventDefault();
			const $card = $(e.currentTarget).parents('.card');
			$card.attr('data-mode', 'view');
			this.resetCard($card);
		};

		this.handleCountryChange = (e) => {
			e.preventDefault();
			const $select = $(e.currentTarget);
			if ($select.val() === 'US') {
				objects.$stateInput.hide();
				objects.$stateSelect.show();
			} else {
				objects.$stateInput.show();
				objects.$stateSelect.hide();
			}
		};

		this.handleAvatarUpload = (e) => {
			e.preventDefault();
			objects.$errorContainer.addClass('d-none');
			const $form = $(e.target);
			const file = $form[0].file.files[0];
			// const data = new FormData($form[0]);
			const data = new FormData();
			const fileType = file.name.split().pop();
			data.append('file', file, `avatar${fileType}`);
			const locale = $el.data('locale');
			const userpath = $el.data('userpath');
			const workspace = $el.data('workspace');
			const usernode = $el.data('usernode');
			$('.modal').modal('hide');
			objects.objLoader.startLoader();
			$.ajax({
				cache: false,
				url: `/modules/api/jcr/v1/${workspace}/${locale}/paths${userpath}/files/profile`,
				type: 'POST',
				data: data,
				dataType: 'json',
				contentType: false,
				processData: false
			}).done(function (data) {
				$.ajax({
					cache: false,
					url: `/modules/api/jcr/v1/${workspace}/${locale}/nodes/${usernode}/properties/j__picture`,
					type: 'PUT',
					contentType: 'application/json; charset=utf-8',
					data: JSON.stringify({value: data.id}),
					processData: false,
					dataType: 'json'
				})
					.done(() => {
						location.reload();
					})
					.fail((jqXHR, textStatus) => {
						//console.log(textStatus);
						this.showError(false);
					})
					.always(() => {
						objects.objLoader.stopLoader();
					});
			}).fail((jqXHR, textStatus) => {
				//console.log(textStatus);
				this.showError(false);
			});
		};

		this.handleCompanionAdd = (e) => {
			e.preventDefault();
			objects.$errorContainer.addClass('d-none');
			const $card = $(e.target).parents('.card');
			const $add = $card.find('.js-companion-add');
			const data = {'op': 'create'};
			data['type'] = $add.find('select[name=type]').val();
			data['first_name'] = $add.find('input[name=first_name]').val();
			data['last_name'] = $add.find('input[name=last_name]').val();
			const phone = $add.find('input[name=phone]').intlTelInput('getNumber');
			if (phone) { data['phone'] = phone; }

			objects.objLoader.startLoader();
			$.ajax({
				cache: false,
				url: objects.companionUrl,
				type: 'POST',
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json',
				processData: false
			})
				.done((data) => {
					this.loadCompanions(data);
				})
				.fail((jqXHR, textStatus) => {
					//console.log(textStatus);
					this.showError(false);
				})
				.always(() => {
					objects.objLoader.stopLoader();
					$card.attr('data-mode', 'view');
					this.renderCard($card);
				});
		};

		this.handleDeleteClick = (e) => {
			e.preventDefault();
			objects.$errorContainer.addClass('d-none');
			let cid = $(e.target).data('companion');
			const data = {'op': 'delete', 'id': cid};

			objects.objLoader.startLoader();
			$.ajax({
				cache: false,
				url: objects.companionUrl,
				type: 'POST',
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json',
				processData: false
			})
				.done((data) => {
					this.loadCompanions(data);
				})
				.fail((jqXHR, textStatus) => {
				//	console.log(textStatus);
					this.showError(false);
				})
				.always(() => {
					const $card = $('#companion-'+cid);
					objects.objLoader.stopLoader();
					$card.remove();
				});
		};

		this.handleCompanionEdit = (e) => {
			e.preventDefault();
			objects.$errorContainer.addClass('d-none');
			const $card = $(e.target).parents('.card');
			const $edit = $card.find('.js-companion-edit:visible');
			const data = {'op': 'update', 'family_members': []};

			$edit.each((i, companion) => {
				const companionData = {};
				const $companion = $(companion);
				companionData['id'] = $companion.find('input[name=id]').val();
				companionData['type'] = $companion.find('select[name=type]').val();
				companionData['first_name'] = $companion.find('input[name=first_name]').val();
				companionData['last_name'] = $companion.find('input[name=last_name]').val();
				companionData['phone'] = $companion.find('input[name=phone]').intlTelInput('getNumber');
				data.family_members.push(companionData);
			});

			objects.objLoader.startLoader();
			$.ajax({
				cache: false,
				url: objects.companionUrl,
				type: 'POST',
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json',
				processData: false
			})
				.done((data) => {
					this.loadCompanions(data);
				})
				.fail((jqXHR, textStatus) => {
					//console.log(textStatus);
					this.showError(false);
				})
				.always(() => {
					objects.objLoader.stopLoader();
					$card.attr('data-mode', 'view');
					this.renderCard($card);
				});
		};

		this.handleUpdateProfile = (e) => {
			e.preventDefault();
			objects.$errorContainer.addClass('d-none');
			const data = [];
			$el.find('[data-path]:input:visible').each((i, v) => {
				const $v = $(v);
				let val;
				const initialValue = $v.attr('data-initial-value');
				switch (v.type) {
					case 'checkbox':
						val = `${$v.is(':checked')}`;
						break;
					case 'tel':
						val = $v.intlTelInput('getNumber');
						break;
					default:
						val = $v.val();
				}
				if (val !== initialValue) {
					if (!val) {
						data.push({'op': 'remove', 'path': $v.data('path')});
					} else {
						data.push({'op': 'replace', 'path': $v.data('path'), 'value': val});
					}
				}
			});
			objects.objLoader.startLoader();
			$.ajax({
				cache: false,
				url: objects.profileUrl,
				type: 'POST',
				data: JSON.stringify(data),
				dataType: 'json',
				contentType: 'application/json',
				processData: false
			})
				.done((data) => {
					this.loadProfile(data);
				})
				.fail((jqXHR, textStatus) => {
					//console.log(textStatus);
					this.showError(false);
				})
				.always(() => {
					objects.objLoader.stopLoader();
					objects.$card.attr('data-mode', 'view');
					this.renderCard(objects.$card);
				});
		};

		this.renderCard = ($card) => {
			$card.each((i, e) => {
				const $e = $(e);
				switch ($e.attr('data-mode')) {
					case 'view': {
						$e.find('[aria-disabled="false"]').attr('aria-disabled', true);
						$e.find('[data-action="add"]').show();

						$e.find('[data-view*="save"]').hide();
						$e.find('[data-view*="edit"]').hide();
						$e.find('[data-view*="add"]').hide();
						$e.find('[data-view*="view"]').show();
						const hasChecks = $e.find('input[type="checkbox"]').length > 0;
						const checked = $e.find('input[type="checkbox"]:checked');
						const isTravelCompanions = $e.find('form.js-companion-add-form').length > 0;
						const hasTravelCompanions = objects.$companionEditForm.find('.js-companion-edit').length > 0;
						if ((hasChecks && checked.length === 0) || (isTravelCompanions && !hasTravelCompanions)) {
							$e.find('[data-view*="empty"]').show();
							$e.find('[data-action="edit"]').hide();
							$e.find('.card-block').hide();
						} else {
							$e.find('[data-view*="empty"]').hide();
							$e.find('[data-action="edit"]').show();
							$e.find('.card-block').show();
						}
						break;
					}
					case 'edit':
						$e.find('[aria-disabled="true"]').attr('aria-disabled', false);
						$e.find('[data-action="edit"]').hide();
						$e.find('[data-action="add"]').hide();

						$e.find('.card-block').show();
						$e.find('[data-view*="empty"]').hide();
						$e.find('[data-view*="view"]').hide();
						$e.find('[data-view*="edit"]').show();
						$e.find('[data-view*="save"]').show();
						break;
					case 'add':
						$e.find('[aria-disabled="true"]').attr('aria-disabled', false);
						$e.find('[data-action="add"]').hide();
						$e.find('[data-action="edit"]').hide();

						$e.find('.card-block').show();
						$e.find('[data-view*="save"]').hide();
						$e.find('[data-view*="empty"]').hide();
						$e.find('[data-view*="edit"]').hide();
						$e.find('[data-view*="view"]').show();
						$e.find('[data-view*="add"]').show();
						break;
					case 'new':
						$e.find('.card-block').show();
						$e.find('[aria-disabled="true"]').attr('aria-disabled', false);
						$e.find('[data-view*="empty"]').hide();
						$e.find('[data-view*="save"]').show();
						$e.find('[data-view*="new"]').show();
						$e.find('[data-view*="edit"]').show();
						break;
				}
			});
		};

		this.resetCard = ($card) => {
			$card.find('[data-initial-value]:input').each((i, f) => {
				const $f = $(f);
				if ($f.is(':checkbox')) {
					f.checked = $f.data('initial-value');
				} else if ($f.attr('type') === 'tel') {
					$f.intlTelInput('setNumber', $f.data('initial-value'));
				} else {
					$f.val($f.data('initial-value'));
				}
			});
			this.renderCard($card);
		};

		this.loadData = ($form) => {
			if ($form.length === 0) { return; } // Not this component.

			objects.objLoader.startLoader();
			let profileLoaded = false, companionsLoaded = false;

			$.getJSON({url: objects.profileUrl}, {_: new Date().getTime()})
				.done(this.loadProfile)
				.fail((jqXHR, textStatus) => {
					//console.log(textStatus);
					this.showError(true);
				})
				.always(() => {
					profileLoaded = true;
					if (profileLoaded && companionsLoaded) {
						objects.$card.attr('data-mode', 'view');
						this.renderCard(objects.$card);
						objects.objLoader.stopLoader();
					}
				});

			$.getJSON({url: objects.companionUrl}, {_: new Date().getTime()})
				.done(this.loadCompanions)
				.fail((jqXHR, textStatus) => {
					//console.log(textStatus);
					this.showError(true);
				})
				.always(() => {
					companionsLoaded = true;
					if (profileLoaded && companionsLoaded) {
						objects.$card.attr('data-mode', 'view');
						this.renderCard(objects.$card);
						objects.objLoader.stopLoader();
					}
				});
		};

		this.loadCompanions = (data) => {
			const companions = data['family_members'];
			if (companions) {
				objects.$companionRow.empty();
				companions.forEach((companion) => {
					const $newCompanion = objects.$companionTemplate.children().clone();
					const id = companion['id'];
					const type = companion['type'];
					const typeText = $newCompanion.find(`option[value=${type}]`).text();
					const firstName = companion['first_name'];
					const lastName = companion['last_name'];
					const phone = companion['phone'];

					$newCompanion.attr('id', 'companion-'+id);
					$newCompanion.find('input[name=id]').val(id);
					$newCompanion.find('select[name=type]').val(type).attr('data-initial-value', type);
					$newCompanion.find('.js-companion-type').text(typeText);
					$newCompanion.find('input[name=first_name]').val(firstName).attr('data-initial-value', firstName);
					$newCompanion.find('input[name=last_name]').val(lastName).attr('data-initial-value', lastName);
					$newCompanion.find('.js-companion-name').text(`${firstName} ${lastName}`);
					const $phoneInput = $newCompanion.find('input[name=phone]');
					$phoneInput.intlTelInput(telInputOptions);
					if (phone) {
						$phoneInput.attr('data-initial-value', phone);
						$phoneInput.intlTelInput('setNumber', phone);
						$newCompanion.find('.js-companion-phone').text(this.formatPhone($phoneInput));
					} else {
						$newCompanion.find('.js-companion-phone-line').hide();
					}
					$newCompanion.find('.btn-remove').data('companion',id);
					$newCompanion.find('.btn-remove').on('click',(e) => {
						$('#delete-companion-modal').find('.js-companion-delete').data('companion',id);
					});

					objects.$companionRow.append($newCompanion);
				});
			}

			// Initialize Delete Modal
			$('#delete-companion-modal').find('.js-companion-delete').on('click', this.handleDeleteClick);
		};

		this.loadProfile = (data) => {
			const guestProfile = data['guest_profile'];
			if (guestProfile) {
				Object.keys(guestProfile).forEach((key) => {
					let value = guestProfile[key] || '';
					const $input = $el.find(`[data-path="/guest_profile/${key}"]:input`);
					const $span = $el.find(`span[data-path="/guest_profile/${key}"]`);
					$input.attr('data-initial-value', value);
					if ($input.is('[type="tel"]')) {
						$input.intlTelInput('setNumber', value);
						value = this.formatPhone($input);
					} else {
						$input.val(value);
					}
					value = value || $span.data('placeholder') || '';
					$span.text(value);
					if (key === 'city') {
						if (!value) {
							$('.js-state-separator', $el).hide();
						} else {
							$('.js-state-separator', $el).show();
						}
					}
				});
				$el.find('[data-email]').data('email', guestProfile['email']);

				// Hide welcome message if address line 1, city, state, zip and country fields are filled in
				if(guestProfile['address1'] && guestProfile['city'] && guestProfile['state_code'] && guestProfile['zip'] && guestProfile['country_code']) {
					$('.fill-out-profile-msg').hide();
				}
			}
			const guestPreferences = data['guest_preference'];
			if (guestPreferences) {
				Object.keys(guestPreferences).forEach((key) => {
					$el.find(`input[data-path="/guest_preference/${key}"]:checkbox`).each((i, c) => {
						if (guestPreferences[key]) {
							c.checked = true;
							c.setAttribute('data-initial-value', true);
						} else {
							c.checked = false;
							c.setAttribute('data-initial-value', false);
						}
					});
				});
			}

			// Set data-initial-value on any remaining checkboxes that don't have it.
			// This is so the user can cancel changes.
			$el.find('input:not([data-initial-value]):checkbox').each((i, c) => {
				let val = `${$(c).is(':checked')}`;
				c.setAttribute('data-initial-value', val);
			});

			$('.pwd-change-login').hide();
			$('.pwd-change-no-login').show();
		};

		this.formatPhone = ($input) => {
			let number = $input.val();
			let dialCode = $input.intlTelInput('getSelectedCountryData').dialCode;
			if (number) {
				return `+${dialCode} ${number}`;
			}
			return '';
		};

		this.showError = (isLoad) => {
			objects.$errorContainer.removeClass('d-none');
			if(isLoad) {
				objects.$errorContainer.find('.load-error').removeClass('d-none');
				objects.$errorContainer.find('.network-error').addClass('d-none');
			} else {
				objects.$errorContainer.find('.load-error').addClass('d-none');
				objects.$errorContainer.find('.network-error').removeClass('d-none');
			}

			$('html, body').animate({
				scrollTop: $('.error-container').offset().top - 100
			}, 250, 'linear');
		};

		this.firstRun = () => {
			import('intl-tel-input').then(() => {
				import('intl-tel-input/build/js/utils.js');

				console.info('~~~ UserProfile Module ~~~');

				objects.$intlInput.intlTelInput(telInputOptions);
				objects.$companionAddForm.find('.intl-input').intlTelInput(telInputOptions);

				utils.setTooltips();

				objects.$avatarForm.submit(this.handleAvatarUpload);
				objects.$avatarForm.find('#file-upload').on('change', () => {
					objects.$avatarForm.submit();
				});

				objects.$profileForm.submit(this.handleUpdateProfile);
				objects.$preferenceForm.submit(this.handleUpdateProfile);
				objects.$companionAddForm.submit(this.handleCompanionAdd);
				objects.$companionEditForm.submit(this.handleCompanionEdit);

				objects.$btnNew.on('click', this.handleNewClick);
				objects.$btnAdd.on('click', this.handleAddClick);
				objects.$btnEdit.on('click', this.handleEditClick);
				objects.$btnCancel.on('click', this.handleCancelClick);

				objects.$countrySelect.on('change', this.handleCountryChange);

				objects.$cardCollapse.on('hidden.bs.collapse', (e) => {
					const $card = $(e.target).parents('.card');
					$card.find('[data-action="edit"]').hide();
					localStorage.setItem(`collapse-${e.target.id}`, true);
				});

				objects.$cardCollapse.on('show.bs.collapse', (e) => {
					const $card = $(e.target).parents('.card');
					this.renderCard($card);
					localStorage.removeItem(`collapse-${e.target.id}`);
				});

				// Load indicator.
				objects.objLoader = new Loader($, objects.$loader);

				objects.$card.find('.collapse').each((i, el) => {
					if (el.id && localStorage.getItem(`collapse-${el.id}`)) {
						$(el).collapse('hide');
					} else {
						$(el).collapse('show');
					}
				});

				this.loadData(objects.$profileForm);
			});
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'UserProfile';
	}

	init() {
		this.firstRun();
	}

}

export default UserProfile;
