import flatpickr from 'flatpickr';
// import flatpickr from '../vendor/flatpickr';
import moment from 'moment';
import noUiSlider from 'nouislider';
import Loader from '../modules/Loader';

import MapView from '../modules/MapView';
import FavoriteButton from './FavoriteButton';
import BookNowButton from './BookNowButton';

class ParametricSearch {

	/**
	 * ParametricSearch Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - ParametricSearch instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		let qs = new URLSearchParams(window.location.search);

		this.loadLocalStorage = () => {
			let localStorageStartDate = moment(localStorage.getItem('startDate'));
			let localStorageEndDate = moment(localStorage.getItem('endDate'));
			let today = moment().startOf('day').toDate();
			let localStorageAdults = localStorage.getItem('adults');
			let localStorageChildren = localStorage.getItem('children');

			let results = {
				startDate: null,
				endDate: null,
			};

			if (localStorageAdults !== null) {
				objects.$psAdultCount.attr({
					value: localStorageAdults,
					'aria-valuenow': localStorageAdults
				});
			}
			if (localStorageChildren !== null) {
				objects.$psChildCount.attr({
					value: localStorageChildren,
					'aria-valuenow': localStorageChildren
				});
			}

			if (localStorageStartDate.isValid() && localStorageEndDate.isValid()) {
				let startDate = localStorageStartDate.toDate();

				if (startDate >= today) {
					results.startDate = localStorageStartDate;
					results.endDate = localStorageEndDate;
				} else {
					localStorage.removeItem('startDate');
					localStorage.removeItem('endDate');
				}
			}
			return results;
		};

		const objects = {
			$loader: $el.find('[data-loader]'),
			$form: $el.find('form'),
			$filters: $el.find('.filters'),
			$calendar: $el.find('.calendar')[0],
			$psStartDate: $el.find('#ps-start-date'),
			$psEndDate: $el.find('#ps-end-date'),
			$psAdultCount: $el.find('#ps-adult-count'),
			$psChildCount: $el.find('#ps-child-count'),
			$numberAdjusters: $el.find('[data-adjust]'),
			$numbers: $el.find('input[type="number"]'),
			$psDisplayDateRange: $el.find('#ps-display-date-range'),
			$psDateRangeSR: $el.find('#ps-date-range-sr'),
			$psActiveMonthSR: $el.find('#ps-active-month-sr'),
			$psDisplayDestination: $el.find('#ps-display-destination'),
			$jsPropertyName: $el.find('.js-property-name'),
			$jsBookNowText: $el.find('#js-book-now-text'),
			$psDisplayPriceRange: $el.find('#ps-display-price-range'),
			$psDisplayPriceRangeMin: $el.find('#ps-display-price-range-min'),
			$psDisplayPriceRangeMax: $el.find('#ps-display-price-range-max'),
			$psDisplayRating: $el.find('#ps-display-rating'),
			$gridView: $el.find('.grid-view'),
			$mapView: $el.find('[data-module="MapView"]'),
			$mapInfoboxOverlay: $el.find('[data-template-marker-click]'),
			$btnDone: $el.find('[data-action="done"]'),
			$btnSubmitDestination: $el.find('[data-action="submit-destination"]'),
			$btnSort: $el.find('[data-action="sort"]'),
			$btnClearSort: $el.find('[data-action="clear-sort"]'),
			$btnClearFilter: $el.find('[data-action="clear"]'),
			$btnClearAllFilters: $el.find('[data-action="clear-all"]'),
			$btnFiltersBack: $el.find('.filters').find('[data-action="back"]'),
			$btnView: $el.find('[data-action="view"]'),
			$btnViewMore: $el.find('[data-view-more]'),
			$optionsDestination: $el.find('input[name="ps-destination-id"]'),
			$optionsRating: $el.find('input[name="ps-min-rating"]'),
			$optionsRatingSubmit: $el.find('[data-action="submit-rating"]'),
			$optionsAmenities: $el.find('input[name="ps-amenities"]'),
			$optionsBedrooms: $el.find('input[name="ps-bedrooms"]'),
			$optionsAmenitiesSubmit: $el.find('[data-action="submit-amenities"]'),
			$optionsPropertyType: $el.find('input[name="ps-property-type"]'),
			$optionsPropertyTypeSubmit: $el.find('[data-action="submit-property-type"]'),
			$optionsNeighborhood: $el.find('input[name="ps-neighborhood"]'),
			$optionsNeighborhoodSubmit: $el.find('[data-action="submit-neighborhood"]'),
			$optionsOther: $el.find('input[name^="ps-other"]'),
			$optionsOtherSubmit: $el.find('[data-action="submit-other"]'),
			$optionsSort: $el.find('input[name="ps-sort"]'),
			$optionsSortCollapse: $el.find('#ps-sort-collapse'),
			$results: $el.find('.results'),
			$resultItemTemplate: $el.find('[data-template-result-item]'),
			$resultStatusHeaderTemplate: $el.find('[data-template-result-status-header]'),
			$resultCount: $el.find('[data-result-count]'),
			$reviewContainer: $el.find('.js-review-container'),
			$noResults: $el.find('.no-results'),
			$propertyGrid: $el.find('[data-module="PropertyGrid"]'),
			$propertyGridItems: $el.find('.property-grid-item'),
			$psPriceRange: $el.find('#ps-price-range'),
			$loadingIndicator: $el.find('.js-parametric-loading'),
			$noResultsIndicator: $el.find('.js-parametric-no-results'),
			objCalendar: null,
			objMapView: null,
			objSlider: null,
			objLoader: null,
			maxPrice: $el.data('max-price'),
			$ariaAssistant: $el.find('[data-aria-assistant]'),
			filters: {
				$destination: $el.find('[data-filter="destination"]'),
				$dates: $el.find('[data-filter="dates"]'),
				$price: $el.find('[data-filter="price"]'),
				$rating: $el.find('[data-filter="rating"]'),
				$amenities: $el.find('[data-filter="amenities"]'),
				$propertyType: $el.find('[data-filter="property-type"]'),
				$neighborhood: $el.find('[data-filter="neighborhood"]'),
				$other: $el.find('[data-filter="other"]'),
			}
		};
		const localData = this.loadLocalStorage();
		const params = {
			locale: $el.data('local') || 'en',
			destinationId: qs.get('destination'),
			startDate: qs.get('start-date') || localData.startDate || $el.data('start-date') || moment().add(7, 'days'),
			endDate: qs.get('end-date') || localData.endDate || $el.data('end-date') || moment().add(11, 'days'),
			minPrice: qs.get('min-price') || $el.data('min-price'),
			maxPrice: qs.get('max-price') || $el.data('max-price'),
			minRating: qs.get('min-rating'),
			currency: $el.data('currency') || '$',
			srcMod: $el.data('srcMod') || '',
			imagePath: $el.data('image-path') || ''
		};
		const results = { initial: [], filtered: [], unfiltered: [], unmatched: [] };

		$(objects.$filters).on('focus', 'button', function () {
			if ($(this).parents('.collapse').length > 0) {
				return false;
			} else {
				objects.$filters.find('.collapse').collapse('hide');
			}
		});

		$(objects.$btnClearAllFilters).on('focus', function () {
			objects.$filters.find('.collapse').collapse('hide');
		});

		let ajax;

		this.resize = () => {
			// this.initActiveButton();
		};

		this.initActiveButton = () => {
			let viewport = utils.getViewportSize();

			if (viewport === 'xs' || viewport === 'sm') {
				this.changeActiveButton($('[data-view="grid"]'));
			} else {
				this.changeActiveButton($('[data-view="map"]'));
			}
		};

		this.changeActiveButton = (activeBtn) => {
			let $thisBtnView = activeBtn.data('view');

			objects.$btnView.removeClass('active');
			activeBtn.addClass('active');
			objects.$results.attr('data-view', $thisBtnView);
			objects.$optionsSortCollapse.collapse('hide');
		};

		this.setDestinationText = () => {
			let destinationDisplayText = objects.$optionsDestination.filter(':checked:not(.d-none)').map(function (i, opt) {
				return $(this).data('display-name');
			}).toArray().join(', ');

			objects.$psDisplayDestination.text(destinationDisplayText);


		};


		this.changeDestination = (destinationId) => {

			$el.find('[data-template-marker-click]').hide();

			let selectedOption = $el.find(`[name="ps-destination-id"][value="${destinationId}"]`)[0];
			if (selectedOption) {
				$el.find(`[name="ps-destination-id"][value="${destinationId}"]`).prop('checked', true);

				qs.set('destination', destinationId);

				this.toggleDisplayValues(objects.filters.$destination, true);
				if (!qs.get('neighborhood')) {
					this.clearFilter(objects.filters.$neighborhood);
				}
				this.applyFilter(objects.filters.$destination);
				this.setNeighborhoods(destinationId);
				this.setDestinationText();
			} else {
				this.toggleDisplayValues(objects.filters.$destination, false);
			}
		};

		this.handleDestinationChange = (e) => {
			let destinationId = objects.$optionsDestination.filter(':checked:not(.d-none)').val();
			this.clearFilter(objects.filters.$neighborhood);
			objects.$filters.find('.collapse').collapse('hide');
			this.changeDestination(destinationId);
			this.getResults();
		};

		this.changeDates = (startDate, endDate) => {
			let viewport = utils.getViewportSize();
			let displayDateFormat = (viewport === 'xs' || viewport === 'sm') ? 'MMM DD' : 'MMMM DD';

			objects.$psDisplayDateRange.text(`${startDate.format(displayDateFormat)} - ${endDate.format(displayDateFormat)}`);
			objects.$psStartDate.val(startDate.format());
			objects.$psEndDate.val(endDate.format());
			objects.objCalendar.jumpToDate(startDate.toDate());
			objects.objCalendar.setDate([startDate.toDate(), endDate.toDate()], false);

			qs.set('start-date', startDate.format('YYYY-MM-DD'));
			qs.set('end-date', endDate.format('YYYY-MM-DD'));
			localStorage.setItem('startDate', moment(startDate).format('YYYY-MM-DD'));
			localStorage.setItem('endDate', moment(endDate).format('YYYY-MM-DD'));

			this.toggleDisplayValues(objects.filters.$dates, true);
			this.applyFilter(objects.filters.$dates);
			this.updateResultsView();

			objects.$psDateRangeSR.text(`${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`);
		};

		this.handleCalendarDayCreate = (dObj, dStr, fp, dayElem) => {
			let currentDate = moment();
			let ariaLabel = dayElem.getAttribute('aria-label');

			dayElem.setAttribute('data-date', moment(dayElem.dateObj).format('YYYY-MM-DD'));

			if (moment(dayElem.dateObj) >= currentDate.subtract(1, 'days')) {
				dayElem.innerHTML += '<div class="price"></div>';
			} else {
				// Don't let the user select the day before today
				dayElem.className += ' disabled';
				dayElem.disabled = true;
			}

			// flatpickr ADA fixes
			$(dayElem).attr('role', 'button');

			if (moment(dayElem.dateObj).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
				$(dayElem).attr('aria-current', 'date');
			}

			if ($(dayElem).hasClass('prevMonthDay') || $(dayElem).hasClass('nextMonthDay')) {
				$(dayElem).addClass('disabled');
				$(dayElem).attr('aria-hidden', 'true');
				$(dayElem).removeAttr('aria-label');
			}

			if ($(dayElem).hasClass('startRange') && $(dayElem).hasClass('endRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as date');
			} else if ($(dayElem).hasClass('startRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as check in date');
			} else if ($(dayElem).hasClass('endRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as check out date');
			}
		};

		this.handleCalendarValueUpdate = () => {
			let selectedStartDate = moment(objects.objCalendar.selectedDates[0]);
			let selectedEndDate = moment(objects.objCalendar.selectedDates[1]);
			let dateCompare = selectedStartDate.format('YYYY-MM-DD').localeCompare(selectedEndDate.format('YYYY-MM-DD'));
			if (dateCompare < 0) {
				this.changeDates(selectedStartDate, selectedEndDate);
				this.getResults();
			}
		};

		this.handleNumberChange = (e) => {
			e.preventDefault();
			let $target = $(e.target);
			let amt = 0;

			// change target if event originates from adjuster buttons
			if ($(e.currentTarget).attr('type') === 'button') {
				$target = $(e.currentTarget).siblings('[type="number"]');
				amt = $(e.currentTarget).data('adjust');
			}

			let $adjusters = $target.parent().find('[data-adjust]');
			let curVal = parseInt($target.val());
			let min = parseInt($target.attr('min'));
			let max = parseInt($target.attr('max'));

			// enable both adjusters.
			$adjusters.attr('aria-disabled', false);

			if (
				(curVal + amt) >= min &&
				(curVal + amt) <= max
			) {
				let newVal = curVal + amt;
				$target.val(newVal);
				$target.attr('aria-valuenow', newVal);
			}

			if ($target.attr('id') === 'ps-adult-count') {
				localStorage.setItem('adults', curVal);
				$(document).find('#mar-adult-count').attr({
					value: curVal,
					'aria-valuenow': curVal
				});
			}
			if ($target.attr('id') === 'ps-child-count') {
				localStorage.setItem('children', curVal);
				$(document).find('#mar-child-count').attr({
					value: curVal,
					'aria-valuenow': curVal
				});
			}

			// disable the clicked adjuster if at min or max.
			$target.attr('aria-disabled', curVal === min || curVal === max);

			this.updateResultsView();
		};

		this.changeRating = (rating) => {
			let selectedOption = $el.find(`[name="ps-min-rating"][value="${rating}"]`)[0];
			if (selectedOption) {
				$el.find(`[name="ps-min-rating"][value="${rating}"]`).prop('checked', true);

				qs.set('min-rating', rating);

				this.toggleDisplayValues(objects.filters.$rating, true);
				this.applyFilter(objects.filters.$rating);

				let $ratingCircles = objects.$psDisplayRating;
				$ratingCircles.removeClass();
				$ratingCircles.addClass(`rating rating-${rating}0`);

				$('[data-label-rating]').text(rating);

			} else {
				this.toggleDisplayValues(objects.filters.$rating, false);
			}
			this.applyFilter(objects.filters.$rating);
		};

		this.handleRatingChange = () => {
			let rating = objects.$optionsRating.filter(':checked:not(.d-none)').val();
			this.changeRating(rating);
			this.performFiltering();
			this.closeCollapse();
		};


		this.changeAmenities = (amenities) => {
			if (amenities.length > 0) {
				$(amenities).each((i, a) => {
					$el.find(`[name="ps-amenities"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				qs.set('amenities', amenities);
				this.toggleDisplayValues(objects.filters.$amenities, true);
			} else {
				qs.delete('amenities');
				this.toggleDisplayValues(objects.filters.$amenities, false);
			}
			this.applyFilter(objects.filters.$amenities);
		};

		this.handleAmenitiesChange = (e) => {
			let selectedOptions = $el.find('[name="ps-amenities"]:checked');
			let selectedValues = [];

			selectedOptions.each((i, a) => {
				selectedValues.push(a.value.toLowerCase());
			});

			this.changeAmenities(selectedValues);
			this.performFiltering();
			this.closeCollapse();
		};

		this.changePropertyType = (propertyType) => {
			if (propertyType.length > 0) {
				$(propertyType).each((i, a) => {
					$el.find(`[name="ps-property-type"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				qs.set('property-type', propertyType);
				this.toggleDisplayValues(objects.filters.$propertyType, true);
			} else {
				qs.delete('property-type');
				this.toggleDisplayValues(objects.filters.$propertyType, false);
			}
			this.applyFilter(objects.filters.$propertyType);
		};

		this.handlePropertyTypeChange = (e) => {
			let selectedOptions = $el.find('[name="ps-property-type"]:checked');
			let selectedValues = [];

			selectedOptions.each((i, a) => {
				selectedValues.push(a.value.toLowerCase());
			});

			this.changePropertyType(selectedValues);
			this.performFiltering();
			this.closeCollapse();
		};

		this.changeNeighborhood = (neighborhood) => {
			if (neighborhood.length > 0) {
				$(neighborhood).each((i, a) => {
					$el.find(`[name="ps-neighborhood"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				qs.set('neighborhood', neighborhood);
				this.toggleDisplayValues(objects.filters.$neighborhood, true);
				objects.filters.$neighborhood.find('[data-toggle="collapse"]').removeAttr('tabindex');
			} else {
				qs.delete('neighborhood');
				this.toggleDisplayValues(objects.filters.$neighborhood, false);
				objects.filters.$neighborhood.find('[data-toggle="collapse"]').attr('tabindex', '-1');
			}
			this.applyFilter(objects.filters.$neighborhood);

		};

		this.handleNeighborhoodChange = (e) => {
			let selectedOptions = $el.find('[name="ps-neighborhood"]:checked');
			let selectedValues = [];

			selectedOptions.each((i, a) => {
				selectedValues.push(a.value.toLowerCase());
			});

			this.changeNeighborhood(selectedValues);
			this.performFiltering();
			this.closeCollapse();
		};

		this.changeBedrooms = (bedrooms) => {
			if (bedrooms.length > 0) {
				$(bedrooms).each((i, a) => {
					$el.find(`[name="ps-bedrooms"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				qs.set('bedrooms', bedrooms);
			} else {
				qs.delete('bedrooms');
			}
		};

		this.changeGroups = (groups) => {
			if (groups.length > 0) {
				$(groups).each((i, a) => {
					$el.find(`[name="ps-other-groups"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				qs.set('groups', groups);
			} else {
				qs.delete('groups');
			}
		};

		this.changeCollection = (collection) => {
			if (collection.length > 0) {
				$(collection).each((i, a) => {
					$el.find(`[name="ps-other-collection"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				qs.set('collection', collection);
				this.toggleDisplayValues(objects.filters.$other, true);
			} else {
				qs.delete('collection');
				this.toggleDisplayValues(objects.filters.$other, false);
			}
		};

		this.handleOtherChange = (e) => {

			let selectedCollectionOptions = $el.find('[name="ps-other-collection"]:checked');
			let selectedGroupOptions = $el.find('[name="ps-other-groups"]:checked');
			let selectedBedroomOptions = $el.find('[name="ps-bedrooms"]:checked');
			let selectedCollectionValues = [];
			let selectedGroupValues = [];
			let selectedBedroomValues = [];

			selectedCollectionOptions.each((i, a) => {
				selectedCollectionValues.push(a.value.toLowerCase());
			});
			selectedGroupOptions.each((i, a) => {
				selectedGroupValues.push(a.value.toLowerCase());
			});
			selectedBedroomOptions.each((i, a) => {
				selectedBedroomValues.push(a.value.toLowerCase());
			});

			this.changeCollection(selectedCollectionValues);
			this.changeGroups(selectedGroupValues);
			this.changeBedrooms(selectedBedroomValues);

			this.applyFilter(objects.filters.$other);
			this.performFiltering();
			this.closeCollapse();

			if (selectedCollectionValues.length > 0 || selectedGroupValues.length > 0 || selectedBedroomValues.length > 0) {
				this.toggleDisplayValues(objects.filters.$other, true);
			} else {
				this.toggleDisplayValues(objects.filters.$other, false);
			}
		};

		this.handleSortChange = (e) => {
			let $selectedSortOption = $el.find('[name="ps-sort"]:checked');
			let dir = $selectedSortOption.data('dir');
			if (dir) {
				qs.set('dir', dir);
				this.updateLocationUrl();
			}
			this.sortResults($selectedSortOption.val());
		};

		this.changePriceRange = (min, max) => {
			if (!min) {
				//min = objects.$psPriceRangeMin.attr('min');
			} else {
				//min = Math.max(min, objects.$psPriceRangeMin.attr('min'));
			} //todo: also make sure min < max possible value
			if (!max) {
				//max = objects.$psPriceRangeMax.attr('max');
			} else {
				//max = Math.min(max, objects.$psPriceRangeMax.attr('max'));
			} //todo: also make sure max > min possible value

			/*
			qs.set('min-price', min);
			qs.set('max-price', max);
			this.updateLocationUrl();
			*/

			objects.$psDisplayPriceRangeMin.text(`${params.currency}${min}`);
			objects.$psDisplayPriceRangeMax.text(`${params.currency}${max}`);
			objects.$psDisplayPriceRange.text(`${params.currency}${min} - ${params.currency}${max}`);
			objects.objSlider.updateOptions({ start: [min, max] });

			this.toggleDisplayValues(objects.filters.$price, true);
		};

		this.disableFilter = ($filter) => {
			$filter.attr('aria-disabled', true);
		};
		this.enableFilter = ($filter) => {
			$filter.attr('aria-disabled', false);
		};

		this.applyFilter = ($filter) => {
			if ($filter) {
				let filterType = $filter.data('filter');
				let filterCount = $filter.find('input[type="checkbox"]:checked, input[type="radio"]:checked').length;
				if (filterCount === 0) filterCount = '';
				$filter.find('[data-filter-count]').text(filterCount);
			}
		};

		this.findAnyMatchInArrays = (array1, array2) => {
			for (let i = 0; i < array1.length; i++) {
				for (let j = 0; j < array2.length; j++) {
					if (array1[i] === array2[j]) {
						return true;
					}
				}
			}
			return false;
		};

		this.performFiltering = () => {
			objects.$loadingIndicator.removeClass('d-none');
			objects.$noResultsIndicator.addClass('d-none');
			objects.$resultCount.addClass('d-none');

			$el.find('[data-template-marker-click]').hide();

			// set price filter criteria
			let range = objects.objSlider.get();
			let priceMin = Math.round(range[0] || 0);
			let priceMax = Math.round(range[1] || 0);

			// set rating filter criteria
			let rating = 0;
			if ($el.find('[name="ps-min-rating"]:checked').length > 0) {
				rating = parseInt($el.find('[name="ps-min-rating"]:checked').val());
			}

			// set amenities filter criteria
			let amenities = [];
			$el.find('[name="ps-amenities"]:checked').each((i, a) => {
				amenities.push(a.value.toLowerCase());
			});

			// set property type filter criteria
			let propertyTypes = [];
			$el.find('[name="ps-property-type"]:checked').each((i, a) => {
				propertyTypes.push(a.value.toLowerCase());
			});

			// set neighborhood filter criteria
			let neighborhoods = [];
			$el.find('[name="ps-neighborhood"]:checked').each((i, a) => {
				neighborhoods.push(a.value.toLowerCase());
			});

			// set collection filter criteria
			let collections = [];
			$el.find('[name="ps-other-collection"]:checked').each((i, a) => {
				collections.push(a.value.toLowerCase());
			});

			// set groups filter criteria
			let groups = [];
			$el.find('[name="ps-other-groups"]:checked').each((i, a) => {
				groups.push(a.value.toLowerCase());
			});

			let bedrooms = [];
			$el.find('[name="ps-bedrooms"]:checked').each((i, a) => {
				bedrooms.push(a.value.toLowerCase());
			});

			// filter results
			results.filtered = results.unfiltered.filter((obj) => {
				let filters = [];
				filters.push(parseInt(obj['price'] || 0) >= priceMin);
				filters.push(parseInt(obj['price'] || 0) <= priceMax);
				filters.push(parseInt(obj['rating']['value'] || 5) >= rating);

				if (amenities.length > 0) {
					filters.push(amenities.every((x) => obj['amenities'].includes(x)));
				}
				if (propertyTypes.length > 0) {
					filters.push(propertyTypes.every((x) => obj['type'].includes(x)));
				}
				if (neighborhoods.length > 0) {
					filters.push(neighborhoods.every((x) => obj['neighborhood'].includes(x)));
				}
				if (collections.length > 0) {
					filters.push(collections.every((x) => obj['collection'].includes(x)));
				}
				if (groups.length > 0) {
					filters.push(groups.every((x) => obj['groups-events'].includes(x)));
				}
				// if (bedrooms.length > 0) {
				// 	filters.push(bedrooms.every((x) => obj['bedrooms'].includes(x)));
				// }
				if (bedrooms.length > 0 && obj['bedrooms']) {
					filters.push(this.findAnyMatchInArrays(bedrooms, obj['bedrooms']));
				}
				return filters.every((i) => i);
			});

			results.unmatched = results.unfiltered.filter((x) => results.filtered.indexOf(x) < 0);
			this.sortResults();

			if (results.unfiltered.length > 0) {
				objects.$loadingIndicator.addClass('d-none');
				objects.$noResultsIndicator.removeClass('d-none');
				objects.$resultCount.removeClass('d-none');
			}
		};

		this.clearFilter = ($filter, isClearAll = false) => {
			let filterType = $filter.data('filter');
			switch (filterType) {
				case 'dates':
					objects.objCalendar.clear();
					this.getResults();
					break;
				case 'price':
					qs.delete('min-price');
					qs.delete('max-price');
					this.resetPriceRange();
					this.changePriceRange(objects.objSlider.options.range.min, objects.objSlider.options.range.max);
					break;
				case 'rating':
					qs.delete('min-rating');
					break;
				case 'amenities':
					qs.delete('amenities');
					break;
				case 'property-type':
					qs.delete('property-type');
					break;
				case 'neighborhood':
					qs.delete('neighborhood');
					break;
				case 'destination':
					qs.delete('destination');
					break;
				case 'other':
					qs.delete('groups');
					qs.delete('collection');
					qs.delete('bedrooms');
					break;
				default:
			}

			this.toggleDisplayValues($filter, false);
			this.updateLocationUrl();

			if (!['dates'].includes(filterType)) {
				$filter.find('input[type="checkbox"], input[type="radio"]').prop('checked', false);
				this.applyFilter($filter);
			}
			// clearAll will call performFiltering separately
			if (!isClearAll) {
				this.performFiltering();
			}
		};

		this.clearAllFilters = (e) => {
			this.clearFilter(objects.filters.$price, true);
			this.clearFilter(objects.filters.$rating, true);
			this.clearFilter(objects.filters.$amenities, true);
			this.clearFilter(objects.filters.$propertyType, true);
			this.clearFilter(objects.filters.$neighborhood, true);
			this.clearFilter(objects.filters.$other, true);
			this.clearFilter(objects.filters.$destination, true);
			this.performFiltering();
			this.changeDestination();
			this.getResults();
			objects.$filters.find('.collapse').collapse('hide');
		};

		this.setNeighborhoods = (destinationId) => {
			$el.find('[data-neighborhood-destination-id]').hide();
			let $neighborhoodItems = $el.find(`[data-neighborhood-destination-id="${destinationId}"]`);
			if ($neighborhoodItems.length > 0 && objects.$optionsDestination.filter(':checked:not(.d-none)').length === 1) {
				$neighborhoodItems.show();
				this.enableFilter(objects.filters.$neighborhood);
				objects.filters.$neighborhood.find('[data-toggle="collapse"]').removeAttr('tabindex');
			} else {
				this.disableFilter(objects.filters.$neighborhood);
				objects.filters.$neighborhood.find('[data-toggle="collapse"]').attr('tabindex', '-1');
			}
		};

		this.resetPriceRange = () => {
			// get min/max price for setting range filter
			let origRange = objects.objSlider.get();

			if (results.unfiltered.length > 0) {
				let priceRange = results.unfiltered.reduce((x, i) => {
					return [
						Math.min(i.price ? i.price : Number.MAX_VALUE, x[0]),
						Math.max(i.price ? i.price : 0, x[1])
					];
				}, [origRange[0], origRange[1]]);

				objects.objSlider.updateOptions({
					range: {
						'min': Math.round((priceRange[0] === priceRange[1] || isNaN(priceRange[0])) ? 0 : priceRange[0]),
						'max': Math.round((isNaN(priceRange[1])) ? origRange[1] : priceRange[1])
					}
				});
			}
		};

		this.sortResults = (value) => {
			$el.find('[data-template-marker-click]').hide();

			if (results.unfiltered.length > 0) {
				results.unfiltered.forEach((obj) => {
					obj.status = 0;
					if (results.filtered.includes(obj)) {
						obj.status = 1;
					}
					// matching + non-matching properties may be unavailable (must test all)
					if (obj['availability'] !== true) {
						obj.status = -1;
					}
				});

				results.unfiltered = results.unfiltered.sort((a, b) => {
					// sort by status first
					let a1 = parseInt(a['status']);
					let b1 = parseInt(b['status']);

					if (b1 > a1) return 1;
					if (b1 < a1) return -1;

					// then sort by learn more
					if (a.learnMore > b.learnMore) return 1;
					if (a.learnMore < b.learnMore) return -1;

					if (value) {
						qs.set('sort', value);
					} else if (qs.get('sort')) {
						value = qs.get('sort').toLowerCase();
					}

					if (value) {
						// split array on '.' for multiple-level property values
						let valArray = value.split('.');

						let a2 = a[valArray[0]];
						let b2 = b[valArray[0]];

						//todo: adjust to accommodate multiple levels
						/*
						  compare(obj1, obj2, propertyPath)
						  value = object; for (i = 0; i < strings.length; i++) {value = value[strings[i]]}
						*/
						if (valArray.length === 2 && a[valArray[0]]) {
							a2 = a[valArray[0]][valArray[1]];
							b2 = b[valArray[0]][valArray[1]];
						}

						if (qs.get('dir') !== 'asc') {
							return b2 - a2;
						} else {
							return a2 - b2;
						}
					}
					else return 0;
				});

				this.updateResultsView();
				utils.trimBadges();
			}
		};

		this.toggleDisplayValues = ($filter, applied) => {
			if (applied) {
				$filter.find('[data-view="applied"]').show();
				$filter.find('[data-view="applied"]').attr('aria-hidden', false);
				$filter.find('[data-view="unapplied"]').hide();
				$filter.find('[data-view="unapplied"]').attr('aria-hidden', true);
			} else {
				$filter.find('[data-view="unapplied"]').show();
				$filter.find('[data-view="unapplied"]').attr('aria-hidden', false);
				$filter.find('[data-view="applied"]').hide();
				$filter.find('[data-view="applied"]').attr('aria-hidden', true);
				this.disableFilter(objects.filters.$neighborhood);
			}
		};

		this.updateResultsView = () => {
			objects.$mapInfoboxOverlay.hide();

			let mapLocations = [];

			// clear previous results in grid
			objects.$propertyGrid.empty();

			let lastItemStatus;

			// iterate over filtered data to create items
			results.unfiltered.forEach((obj, i) => {

				obj.status = 0;
				if (results.filtered.includes(obj)) {
					obj.status = 1;
				}
				// matching + non-matching properties may be unavailable (must test all)
				if (obj['availability'] !== true) {
					obj.status = -1;
				}

				let $item = objects.$resultItemTemplate.clone().removeAttr('data-template-result-item');
				let $overlay = objects.$mapInfoboxOverlay.clone().removeAttr('style');
				let isAvailable = obj['availability'] === true;
				let isLearnMore = obj['learnMore'] === true;

				$item.attr('data-id', obj.id);
				$item.attr('data-status', obj.status);
				$item.find('[data-item-name]').text(obj.name);

				objects.$jsPropertyName.text(obj.name);

				$item.find('.js-avail-buttons').attr('aria-label', objects.$jsBookNowText.text() + ' at ' + obj.name);
				$overlay.find('.js-avail-buttons').attr('aria-label', objects.$jsBookNowText.text() + ' at ' + obj.name);

				$item.find('[data-item-photo]').attr('src', obj.photo);
				$item.find('[data-item-photo]').attr('alt', obj.photoAltText || '');

				let favBtn = $item.find('.btn-favorite');
				favBtn.attr('data-identifier', obj.pageid);
				favBtn.attr('title', 'Like ' + obj.name);
				favBtn.attr('aria-label', 'Like ' + obj.name);

				let bookBtn = $item.find('[data-module=BookNowButton]');

				bookBtn.attr('aria-label', bookBtn.data('label')[3] + ' at ' + obj.name);

				$overlay.find('[data-item-photo]').attr('src', obj.photo);

				if (obj.url) {
					$item.find('[data-item-name]').attr('href', obj.url);
				} else {
					$item.find('[data-item-name]').removeAttr('href');
				}
				$overlay.find('[data-item-name]').attr('href', obj.url);
				$overlay.find('[data-item-name]').text(obj.name);

				// removed conditional (for testing only)
				/*
				if (window.location.hostname.toString() === 'localhost') {
					$item.find('[data-item-photo]').attr('src', 'http://aqua-dev.avantia.net' + obj.photo);
					$overlay.find('[data-item-photo]').attr('src', 'http://aqua-dev.avantia.net' + obj.photo);
					if (obj.status === -1) {
						$item.addClass('color-magenta');
					} else if (obj.status === 0) {
						$item.addClass('color-brown');
					} else if (obj.status === 1) {
						$item.addClass('color-aqua');
					}
				}
				*/

				let $status = $overlay.find('[data-item-status]');
				$status.find('span').hide();
				if (obj.status === 1) {
					$status.hide();
				} else {
					$status.show();
					$status.find(`span:nth-child(${obj.status + 2})`).show();
				}

				let typeString = '';
				if (obj.locationName) {
					$item.find('[data-item-category]').text(obj.locationName);
					$overlay.find('[data-item-category]').text(obj.locationName);
					typeString = obj.locationName;
				} else {
					$item.find('[data-item-category]').text('');
					$overlay.find('[data-item-category]').text('');
				}

				obj.typeDesc.forEach((type) => {
					if (typeString.length > 0) {
						typeString += ' \u2022 ';
					}
					typeString += type;
				});
				$item.find('[data-item-category]').text(typeString);

				let address = '';
				if (obj.location.addr1) {
					address += obj.location.addr1;
				}
				if (obj.location.addr2) {
					address += obj.location.addr2;
				}

				$item.find('[data-item-name]').attr('aria-label', obj.name);

				if (obj.phoneNumber) {
					$item.find('[data-item-phone]').text(obj.phoneNumber);
					$item.find('[data-item-phone]').parents('a').attr('href', `tel:${obj.phoneNumber}`);
					$item.find('[data-item-phone]').parents('a').attr('aria-label', `Phone Number for ${obj.locationName}`);
					$overlay.find('[data-item-phone]').text(obj.phoneNumber);
					$overlay.find('[data-item-phone]').parents('a').attr('href', `tel:${obj.phoneNumber}`);
					$overlay.find('[data-item-phone]').parents('a').attr('aria-label', `Phone Number for ${obj.locationName}`);

				} else {
					$item.find('[data-item-phone]').parent().hide();
					$overlay.find('[data-item-phone]').parent().hide();
				}

				let $badges = $item.find('[data-item-badges]');
				let $badgesOverlay = $overlay.find('[data-item-badges]');
				let $badgeItem = $badges.children().first();

				$badges.empty();
				$badgesOverlay.empty();

				obj.styles.forEach((s) => {
					let $bi = $badgeItem.clone();
					$bi.addClass(`color-${s.class}`);
					$bi.text(s.name);
					$badges.append($bi);
					$badgesOverlay.append($bi.clone());
				});

				if (obj.price) {
					let price = `${params.currency}${Math.ceil(obj.price)}*`;
					$item.find('[data-item-price]').text(price);
					$overlay.find('[data-item-price]').text(price);
				}

				if (obj.rating) {
					let reviewCountLabel = '';
					let reviewCount = obj['rating']['reviewCount'] || 0;
					if ($item.find('[data-review-count]').data('label')) {
						reviewCountLabel = $item.find('[data-review-count]').data('label')[reviewCount > 1 ? 2 : reviewCount].replace(/{x}/g, reviewCount);
					}
					if ($overlay.find('[data-review-count]').data('label')) {
						reviewCountLabel = $overlay.find('[data-review-count]').data('label')[reviewCount > 1 ? 2 : reviewCount].replace(/{x}/g, reviewCount);
					}

					$item.find('[data-review-count]').attr('href', obj['rating']['reviewsUrl']);
					$item.find('[data-aria-review]').text(reviewCountLabel);

					$overlay.find('[data-review-count]').attr('href', obj['rating']['reviewsUrl']);
					$overlay.find('[data-aria-review]').text(reviewCountLabel);

					let ratingImage = $item.find('[data-rating-image]');
					let ratingImageOverlay = $overlay.find('[data-rating-image]');
					if (obj['rating'] && !isNaN(obj['rating']['value'])) {
						ratingImage.attr('src', ratingImage.attr('src').replace(/{x}/g, Math.round(obj['rating']['value']).toFixed(1)));
						ratingImageOverlay.attr('src', ratingImage.attr('src').replace(/{x}/g, Math.round(obj['rating']['value']).toFixed(1)));
					} else {
						ratingImage.hide();
						ratingImageOverlay.hide();
						$item.find('.trip-advisor').hide();
						$overlay.find('.trip-advisor').hide();
					}
				} else {
					$item.find('.trip-advisor').hide();
					$overlay.find('.trip-advisor').hide();
				}

				let $btnBook = $item.find('[data-action="book"]');
				let $btnBookOverlay = $overlay.find('[data-action="book"]');
				if (isAvailable || isLearnMore) {
					if (isAvailable) {
						$btnBook.text($btnBook.data('label')[1]);
						$btnBookOverlay.text($btnBook.data('label')[1]);
						$btnBook.attr('aria-label', $btnBook.data('label')[1] + ' at ' + obj.name);
					}
					if (isLearnMore) {
						$btnBook.text($btnBook.data('label')[2]);
						$btnBookOverlay.text($btnBook.data('label')[2]);
						$btnBook.attr('aria-label', $btnBook.data('label')[2] + ' about ' + obj.name);
					}
					$btnBook.attr('aria-disabled', false);
					$btnBookOverlay.attr('aria-disabled', false);
				} else {
					$item.find('.js-avail-buttons').addClass('d-none');
					$item.find('.js-avail-price').addClass('d-none');
					$overlay.find('.js-avail-buttons').addClass('d-none');
					$overlay.find('.js-avail-price').addClass('d-none');

					if (obj['avail_status'] === 'MinStay') {
						let $mlos = $item.find('.js-avail-minstay-msg');
						let $mlosmap = $overlay.find('.js-avail-minstay-msg');
						$mlos.removeClass('d-none');
						$mlos.html($mlos.html().replace('{0}', obj['avail_qty']));
						$mlosmap.removeClass('d-none');
						$mlosmap.html($mlosmap.html().replace('{0}', obj['avail_qty']));
						let minstayMessage = $item.find('.js-avail-minstay-msg').text();
						$item.find('.js-avail-minstay-msg').attr('aria-label', minstayMessage + ' at ' + obj.name);
						$overlay.find('.js-avail-minstay-msg').attr('aria-label', minstayMessage + ' at ' + obj.name);
					} else if (obj['avail_status'] === 'Close') {
						if (obj['avail_qty'] > 0) {
							// Days in Advance
							let dia = $item.find('.js-days-advance-msg');
							dia.removeClass('d-none');
							dia.html(dia.html().replace('{0}', obj['avail_qty']));
							let diamap = $overlay.find('.js-days-advance-msg');
							diamap.removeClass('d-none');
							diamap.html(diamap.html().replace('{0}', obj['avail_qty']));
						} else {
							// Closed
							let $closed = $item.find('.js-avail-closed-msg');
							$closed.removeClass('d-none');
							let $closedmap = $overlay.find('.js-avail-closed-msg');
							$closedmap.removeClass('d-none');

							let closedMessage = $item.find('.js-avail-closed-msg').text();
							$item.find('.js-avail-closed-msg').attr('aria-label', obj.name + ' ' + closedMessage);
							$overlay.find('.js-avail-closed-msg').attr('aria-label', obj.name + ' ' + closedMessage);
						}
					} else {
						//TODO handle other status msgs
						console.log(obj['avail_status'] + ' needs messaging');
					}

					// $btnBook.text($btnBook.data('label')[0]);
					// $btnBook.attr('aria-disabled', true);
					// $btnBookOverlay.text($btnBook.data('label')[0]);
					// $btnBookOverlay.attr('aria-disabled', true);
					// $item.find('[data-item-price]').text('');
					// $overlay.find('[data-item-price]').text('');
				}

				let bookUrl = obj.bookingUrl;

				bookUrl = bookUrl + '&src={src}{srcmod}&hotel={hotel}&arrive={arrive}&depart={depart}&rooms={rooms}&adult={adult}&child={child}&promo={promo}'
					.replace(/{src}/g, `aap.${obj.hotelCode}..psgrid`)
					.replace(/{srcmod}/g, params.srcMod)
					.replace(/{hotel}/g, obj.synxisId)
					.replace(/{promo}/g, '');

				if (isLearnMore) {
					let learnMoreUrl = obj.learnMoreUrl;
					$btnBook.attr('data-bookUrl', learnMoreUrl);
				}
				else {
					$btnBook.attr('data-bookUrl', bookUrl);
				}


				bookUrl = bookUrl
					.replace(/{arrive}/g, moment(objects.$psStartDate.val()).format('MM-DD-YYYY'))
					.replace(/{depart}/g, moment(objects.$psEndDate.val()).format('MM-DD-YYYY'))
					.replace(/{rooms}/g, 1)
					.replace(/{adult}/g, objects.$psAdultCount.val())
					.replace(/{child}/g, objects.$psChildCount.val());

				if (isLearnMore) {
					let learnMoreUrl = obj.learnMoreUrl;
					$btnBook.attr('href', learnMoreUrl);
					$btnBookOverlay.attr('href', learnMoreUrl);
				}
				else {
					$btnBook.attr('href', bookUrl);
					// replace 'src' query params for map overlay
					$btnBookOverlay.attr('href', bookUrl.replace(/psgrid/g, 'psmap'));
				}

				let contentStringClick = $overlay.show().prop('outerHTML');

				let objMapLocation = {
					id: obj.id || '',
					status: obj.status,
					lat: obj.location.latitude,
					lng: obj.location.longitude,
					label: obj.name,
					title: obj.name,
					description: '',
					imgPath: obj.photo,
					contentStringClick: contentStringClick
				};

				if (obj.status === 0) {
					objMapLocation.iconUrl = params.imagePath + '/markers/map-marker-non-matching.svg';
					objMapLocation.iconUrlActive = params.imagePath + '/markers/map-marker-non-matching-active.svg';
				} else if (obj.status === 1) {
					objMapLocation.iconUrl = params.imagePath + '/markers/map-marker-available.svg';
					objMapLocation.iconUrlActive = params.imagePath + '/markers/map-marker-available-active.svg';
				} else {
					objMapLocation.iconUrl = params.imagePath + '/markers/map-marker-unavailable.svg';
					objMapLocation.iconUrlActive = params.imagePath + '/markers/map-marker-unavailable-active.svg';
				}

				mapLocations.push(objMapLocation);

				$item.on('mouseenter', (e) => {
					$el.find('[data-template-marker-click]').hide();

					let $pgi = $(e.currentTarget);
					let marker = objects.objMapView.getMarker('id', $pgi.data('id'));
					if (marker >= 0) {
						objects.objMapView.showMarkerHover(marker);
						objects.objMapView.highlightMarker(marker);
						objects.objMapView.centerMarker(marker);
						if (objects.objMapView.getZoom() < 14) {
							objects.objMapView.setZoom(14);
						} else if (objects.objMapView.getZoom() > 17) {
							objects.objMapView.setZoom(17);
						}
					}
				});
				$item.on('mouseleave', (e) => {
					let $pgi = $(e.currentTarget);
					let marker = objects.objMapView.getMarker('id', $pgi.data('id'));
					if (marker >= 0) {
						objects.objMapView.hideMarkerHover(marker);
						// objects.objMapView.unhighlightMarker(marker);
					}
				});

				// check to see if status is changing to display result group headers
				if (obj.status !== lastItemStatus) {
					let $header = objects.$resultStatusHeaderTemplate.clone();
					if (obj.status < 1) {
						$header.find('span').text($header.data('label')[(obj.status + 1)]);
						$header.attr('data-status', obj.status);
						objects.$propertyGrid.append($header);
					}
				} else {
					//lastItemStatus += 1;
				}

				objects.$propertyGrid.append($item);

				// update last status for header display logic
				lastItemStatus = obj.status;

				let module = new FavoriteButton($, favBtn, utils);
				if (favBtn.data('init') !== false) {
					module.init();
				}

				let bmodule = new BookNowButton($, bookBtn, utils);
				if (bookBtn.data('init') !== false) {
					bmodule.init();
				}

			});
			// end forEach

			objects.$mapView.attr('data-locations', mapLocations);

			if (objects.objMapView) {
				objects.objMapView.locations = mapLocations;
				objects.objMapView.zoom = 4;
				objects.objMapView.init();
			}

			// update result count
			let availableMatches = 0;
			for (let i = 0; i < results.filtered.length; i++) {
				// console.log(i+": "+results.filtered[i].name+" - "+results.filtered[i].availability);
				if (results.filtered[i].availability) {
					availableMatches++;
				}
			}

			let resultCount = availableMatches;//results.filtered.length;
			let resultLabel = resultCount;
			if (objects.$resultCount.data('label')) {
				resultLabel = objects.$resultCount.data('label')[resultCount > 1 ? 2 : resultCount].replace(/{x}/g, resultCount);
			}
			objects.$resultCount.text(`${resultLabel}`);

			if (resultCount === 0) {
				objects.$noResults.show();
			} else {
				objects.$noResults.hide();
			}

			this.updateLocationUrl();

			// initiate view more button
			utils.initViewMore();



		};

		this.getResults = () => {
			if (ajax) {
				ajax.abort();
			}

			objects.objLoader.startLoader();
			$('.flatpickr-calendar').attr('aria-busy', true);


			let destinationId;
			let selectedOptions = $el.find('[name="ps-destination-id"]:checked');
			let selectedValues = [];

			selectedOptions.each((i, a) => {
				selectedValues.push(a.value.toLowerCase());
			});

			if (!$el.find('[name="ps-destination-id"]:checked').data('default')) {
				destinationId = $el.find('[name="ps-destination-id"]:checked').val();
			}

			if (moment(objects.$psStartDate.val()).isValid() && moment(objects.$psEndDate.val()).isValid()) {

				let startDate = moment(objects.$psStartDate.val()).format('YYYY-MM-DD');
				let endDate = moment(objects.$psEndDate.val()).format('YYYY-MM-DD');
				let client = objects.$form.attr('action')
					.replace(/{startDate}/g, startDate)
					.replace(/{endDate}/g, endDate)
					.replace(/{destinationId}/g, selectedValues.toString() ? selectedValues.toString() : '');

				ajax = $.ajax({
					url: client,
					method: objects.$form.attr('method'),
					async: true,
					beforeSend: function (xhr) {
						if (xhr && xhr.overrideMimeType) {
							xhr.overrideMimeType('application/json;charset=utf-8');
						}
					},
					dataType: 'json'
				})
					.done((res) => {
						let data = res.data;
						console.log('PS = Result data received: ', data);

						results.initial = data;
						results.unfiltered = data;

						if (data.length <= 1) {
							this.disableFilter(objects.filters.$price);
							this.disableFilter(objects.filters.$rating);
							this.disableFilter(objects.filters.$amenities);
							this.disableFilter(objects.filters.$propertyType);
							this.disableFilter(objects.filters.$other);
						} else {
							this.enableFilter(objects.filters.$price);
							this.enableFilter(objects.filters.$rating);
							this.enableFilter(objects.filters.$amenities);
							this.enableFilter(objects.filters.$propertyType);
							this.enableFilter(objects.filters.$other);

						}

						this.resetPriceRange();

						/*
							  if (qs.get('min-price') || qs.get('max-price')) {
									let minPrice = qs.get('min-price');
									let maxPrice = qs.get('max-price');
									this.changePriceRange(minPrice, maxPrice);
							  }
							  */
					})
					.fail((err) => {
						//console.log('Error getting results: ', err);
					})
					.always(() => {
						objects.objLoader.stopLoader();
						$('.flatpickr-calendar').attr('aria-busy', false);
						ajax = null;
						this.performFiltering();
					});

			}
		};

		this.handleClearFilterClick = (e) => {
			let $filter = $(e.currentTarget).parents('[data-filter]');
			this.clearFilter($filter);
		};

		this.updateLocationUrl = () => {
			window.history.replaceState({}, '', `${location.pathname}?${qs}`);
		};

		this.setInitialValues = () => {
			if (qs.get('destination')) {
				this.changeDestination(qs.get('destination'));
				this.setDestinationText();
			} else {
				this.disableFilter(objects.filters.$neighborhood);
				objects.filters.$neighborhood.find('[data-toggle="collapse"]').attr('tabindex', '-1');
			}

			this.changeDates(moment(params.startDate), moment(params.endDate));

			if (qs.get('min-price') || qs.get('max-price')) {
				let minPrice = qs.get('min-price');
				let maxPrice = qs.get('max-price');
				this.changePriceRange(minPrice, maxPrice);
			}
			if (qs.get('min-rating')) {
				let minRating = qs.get('min-rating');
				this.changeRating(minRating);
			}
			if (qs.get('amenities')) {
				let amenities = qs.get('amenities').toLowerCase().split(',');
				this.changeAmenities(amenities);
			}
			if (qs.get('bedrooms')) {
				let bedrooms = qs.get('bedrooms').toLowerCase().split(',');
				this.changeBedrooms(bedrooms);
			}
			if (qs.get('neighborhood')) {
				let neighborhood = qs.get('neighborhood').toLowerCase().split(',');
				this.changeNeighborhood(neighborhood);
			}
			if (qs.get('property-type')) {
				let propertyTypes = qs.get('property-type').toLowerCase().split(',');
				this.changePropertyType(propertyTypes);
			}
			if (qs.get('collection')) {
				let collection = qs.get('collection').toLowerCase().split(',');
				this.changeCollection(collection);
			}
			if (qs.get('groups')) {
				let groups = qs.get('groups').toLowerCase().split(',');
				this.changeGroups(groups);
			}

			this.getResults();

		};

		this.showMapOverlay = (marker) => {

			const markerContent = marker.contentStringClick.replaceAll('data-item-name=""', 'data-item-name="" tabindex="0"');

			$el.find('[data-template-marker-click]').replaceWith($.parseHTML(markerContent));
			$el.find('[data-template-marker-click]').show();
			$el.find('[data-template-marker-click]').find('.close').on('click', () => {
				$el.find('[data-template-marker-click]').hide();
			});



			this.initTrapFocus();
		};

		this.showReviewModal = (event) => {
			event.preventDefault();
			const ajaxUrl = event.currentTarget.href;
			objects.$reviewContainer.load(ajaxUrl, () => {
				objects.$reviewContainer.find('.modal').modal('show');
			});
		};

		this.closeCollapse = () => {
			objects.$filters.find('.collapse').collapse('hide');
			if (utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm') {
				utils.scrollTo(null, null, null, () => { });
			}
		};

		this.handleOnReady = () => {
			let monthDropdown = $('select.flatpickr-monthDropdown-months')[0];
			monthDropdown.setAttribute('aria-label', 'Month');
			let dateInput = $('input.calendar.mt-2.flatpickr-input')[0];
			dateInput.setAttribute('aria-label', 'Enter Your Stay Dates');


			// flatpickr ADA fixes
			let $monthSelect = $('select.flatpickr-monthDropdown-months');
			let $yearInput = $('input.cur-year');
			$el.find('#mar-month-year').text(`${$monthSelect.find('option:selected').text()} ${$yearInput.val()}`);
			$monthSelect.attr('aria-label', 'select month');
			$yearInput.attr('aria-label', 'select year');
			$yearInput.removeAttr('tabindex');

			$('.flatpickr-calendar').attr('role', 'application');
			$('.flatpickr-prev-month, .flatpickr-next-month').attr('role', 'button').attr('tabindex', 0);
			$('.flatpickr-input').attr('tabindex', -1);

			$('.flatpickr-weekdaycontainer').attr('aria-hidden', 'true');
			$('.flatpickr-prev-month').attr('aria-label', 'previous month');
			$('.flatpickr-next-month').attr('aria-label', 'next month');

			$('.flatpickr-prev-month, .flatpickr-next-month').on('keydown', (e) => {
				if (e.key === 'Enter') {
					if ($(e.target).hasClass('flatpickr-prev-month')) {
						objects.objCalendar.changeMonth(-1);
					} else if ($(e.target).hasClass('flatpickr-next-month')) {
						objects.objCalendar.changeMonth(1);
					}
				}
			});

			let focusableEls = [
				$el.find('.flatpickr-prev-month'),
				$el.find('.flatpickr-monthDropdown-months'),
				$el.find('.cur-year'),
				$el.find('.flatpickr-next-month'),
				($el.find('.flatpickr-day.selected') || $el.find('.flatpickr-day[tabindex=0]')[0]),
				objects.$psAdultCount,
				objects.$psChildCount,
				objects.$btnDone,
			];

			$el.on('keydown', (e) => {
				let $activeElement = $(document.activeElement);

				switch (e.key) {
					case 'Tab': {
						if (e.shiftKey) {
							if ($activeElement.hasClass('flatpickr-day')) {
								$el.find('.flatpickr-next-month').focus();
							} else if ($activeElement.hasClass('flatpickr-prev-month')) {
								e.preventDefault();
								$(focusableEls[focusableEls.length - 1]).focus();
							} else if ($activeElement === objects.$btnDone) {
								e.preventDefault();
								let $anchor = $el.find('.flatpickr-day.startRange:not(.nextMonthDay):not(.prevMonthDay)');
								if (
									$anchor.length > 0
									&& $anchor.is(':visible')
								) {
									//
								} else {
									$anchor = $el.find('.flatpickr-day:not(.disabled):not(.nextMo	nthDay):not(.prevMonthDay)').first();
								}
								$anchor.attr('tabindex', 0);
								$anchor.focus();
							}
						} else {
							if ($activeElement.hasClass('flatpickr-next-month')) {
								let currentMonth = moment().month();
								let selectedMonth = objects.objCalendar.currentMonth;
								if (currentMonth === selectedMonth) {
									e.preventDefault();

									let $anchor = $el.find('.flatpickr-day.today');

									if (
										$anchor.length > 0
										&& $anchor.is(':visible')
									) {
										//
									} else {

										$anchor = $el.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
									}
									$anchor.attr('tabindex', 0);
									$anchor.focus();
								}
							} else if ($activeElement.hasClass('flatpickr-day')) {
								e.preventDefault();
								objects.$psAdultCount.focus();
							} else if ($activeElement.attr('data-action') === 'done') {
								e.preventDefault();
								if (!$el.find('.flatpickr-prev-month').hasClass('flatpickr-disabled')) {
									$el.find('.flatpickr-prev-month').focus();
								} else {
									$el.find('.flatpickr-monthDropdown-months').focus();
								}
							}
						}
						break;
					}
					case 'PageUp': {
						e.preventDefault();
						if (e.shiftKey) {
							if (
								objects.objCalendar.currentYear === moment().year() + 1 && objects.objCalendar.currentMonth >= moment().month()
								|| (objects.objCalendar.currentYear > moment().year() + 1)
							) {
								objects.objCalendar.changeMonth(-12);
							} else {
								// jump to first available (current) date
								objects.objCalendar.jumpToDate(moment().toDate(), true);
							}
						} else {
							objects.objCalendar.changeMonth(-1);
						}

						this.fixCalendarDayTabIndex(true);
						break;
					}
					case 'PageDown': {
						e.preventDefault();
						if (e.shiftKey) {
							objects.objCalendar.changeMonth(12);
						} else {
							objects.objCalendar.changeMonth(1);
						}

						this.fixCalendarDayTabIndex(true);
						break;
					}
					case 'Home': {
						if ($activeElement.hasClass('flatpickr-day')) {
							e.preventDefault();
							// move to start of week
							const dataDate = moment($activeElement.attr('data-date'));
							const startOfWeek = dataDate.clone().startOf('week');
							const startOfMonth = dataDate.clone().startOf('month');
							let targetDate = (startOfWeek.month() < dataDate.month()) ? startOfMonth : startOfWeek;
							$(`.flatpickr-day[data-date="${targetDate.format('YYYY-MM-DD')}"]`).focus();
						}
						break;
					}
					case 'End': {
						if ($activeElement.hasClass('flatpickr-day')) {
							e.preventDefault();
							// move to end of week
							const dataDate = moment($activeElement.attr('data-date'));
							const endOfWeek = dataDate.clone().endOf('week');
							const endOfMonth = dataDate.clone().endOf('month');
							let targetDate = (endOfWeek.month() > dataDate.month()) ? endOfMonth : endOfWeek;
							$(`.flatpickr-day[data-date="${targetDate.format('YYYY-MM-DD')}"]`).focus();
						}
						break;
					}
				}
			});

			this.fixCalendarMonthTabIndex();
		};

		this.fixCalendarMonthTabIndex = () => {
			setTimeout(() => {
				// hack to 'correct' flatpickr setting of tabindex in 'buildMonthSwitch()'
				$el.find('.flatpickr-monthDropdown-months, .flatpickr-monthDropdown-month').removeAttr('tabindex');
			}, 250);
		};

		this.fixCalendarDayTabIndex = (shouldFocus = true) => {
			setTimeout(() => {
				let $anchor = $el.find('.flatpickr-day.startRange:not(.nextMonthDay):not(.prevMonthDay)');

				if (
					$anchor.length > 0
					&& $anchor.is(':visible')
				) {
					$anchor.attr('tabindex', 0);

					if (shouldFocus) {
						$anchor.focus();
					}
				} else {
					$anchor = $el.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
					$anchor.attr('tabindex', 0);

					if (shouldFocus) {
						$anchor.focus();
					}
				}
			}, 100);
		};

		this.initTrapFocus = () => {
			this.trapFocus($el.find('[data-template-marker-click]'));
		};

		this.trapFocus = (element) => {
			let focusableEls = element[0].querySelectorAll('input:not([tabindex="-1"]), select:not([tabindex="-1"]), button:not([tabindex="-1"]), a:not([tabindex="-1"])');
			let lastFocusableEl = focusableEls[focusableEls.length - 1];

			element[0].addEventListener('keydown', function (e) {

				if (e.shiftKey) /* shift + tab */ {
					if (document.activeElement.matches('div[data-template-marker-click] a[data-item-name]')) {
						lastFocusableEl.focus();
						e.preventDefault();
					}
				} else /* tab */ {
					if (document.activeElement === lastFocusableEl) {
						element.focus();
						e.preventDefault();
					}
				}

				this.focusRelatedMarker = () => {
					const title = focusableEls[0].innerHTML;
					const relatedMarker = $(`.gm-style [aria-label="${title}"]`);

					if (relatedMarker.length) {
						relatedMarker.focus();
					}
				};

				if (e.key === "Escape" || e.keyCode === 27) {
					// Example: Close a modal
					$el.find('[data-template-marker-click]').hide();
					this.focusRelatedMarker();
					setTimeout(() => {
						this.focusRelatedMarker();
					}, 500);
				}

			});

			setTimeout(() => {
				$el.find('div[data-template-marker-click]').find('a[data-item-name]').focus();
			}, 500);
		};

		this.rescueFocus = () => {
			$el.attr('tabindex', '-1');
			$el.attr('aria-hidden', 'true');
			$('[data-toggle="reservation-flyout"]').attr('aria-hidden', 'true');
			$('[data-toggle="reservation-flyout"]').attr('aria-expanded', 'false');
		};

		this.toggleFocusOffBody = () => {
			let ariaAssistant = $('[data-aria-assistant-flyout]');
			ariaAssistant.empty();

			if ($('.bodywrapper').hasClass('active')) {
				$('.bodywrapper').attr('tabindex', '-1');
				$('.bodywrapper').attr('focusable', false);
				this.trapFocus($el, $('[data-tel]'));
				ariaAssistant.append(ariaAssistant.data('expanded'));
			} else {
				$('.bodywrapper').attr('tabindex', '0');
				$('.bodywrapper').attr('focusable', true);
				this.rescueFocus();
				ariaAssistant.append(ariaAssistant.data('collapsed'));
			}
		};

		this.toggleAdaVisibility = () => {

			if ($('[data-toggle="reservation-flyout"]').attr('aria-expanded') === true) {
				$('[data-toggle="reservation-flyout"]').attr('aria-expanded', false);
				$('[data-toggle="reservation-flyout"]').attr('aria-hidden', true);
			} else {
				$('[data-toggle="reservation-flyout"]').attr('aria-expanded', true);
				$('[data-toggle="reservation-flyout"]').attr('aria-hidden', false);
			}
		};

		this.firstRun = () => {
			console.info('~~~ ParametricSearch Module ~~~');
			utils.setTooltips();

			this.initActiveButton();

			objects.$numberAdjusters.on('click', this.handleNumberChange);
			objects.$numbers.on('change', this.handleNumberChange);
			objects.$btnClearFilter.on('click', this.handleClearFilterClick);
			objects.$btnClearAllFilters.on('click', this.clearAllFilters);
			objects.$btnSubmitDestination.on('click', this.handleDestinationChange);
			objects.$optionsRatingSubmit.on('click', this.handleRatingChange);
			objects.$optionsAmenitiesSubmit.on('click', this.handleAmenitiesChange);
			objects.$optionsPropertyTypeSubmit.on('click', this.handlePropertyTypeChange);
			objects.$optionsNeighborhoodSubmit.on('click', this.handleNeighborhoodChange);

			objects.$optionsOtherSubmit.on('click', this.handleOtherChange);

			objects.$optionsSort.on('click', this.handleSortChange);

			objects.$filters.find('.collapse').on('show.bs.collapse', (e) => {
				objects.$filters.find('.collapse').collapse('hide');
				$el.find('[data-template-marker-click]').hide();
			});

			objects.$btnDone.on('click', () => {
				objects.$filters.find('.collapse').collapse('hide');
				if (utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm') {
					utils.scrollTo(null, null, null, () => { });
				}
			});

			// nouislider
			objects.objSlider = noUiSlider.create(objects.$psPriceRange[0], {
				start: [0, objects.maxPrice],
				connect: true,
				step: 1,
				range: { min: 0, max: objects.maxPrice }
			});
			objects.objSlider.on('update', (values, handle) => {
				let minVal = Math.round(values[0]);
				let maxVal = Math.round(values[1]);
				objects.$psDisplayPriceRange.text(`${params.currency}${minVal} - ${params.currency}${maxVal}`);
				objects.$psDisplayPriceRangeMin.text(`${params.currency}${minVal}`);
				objects.$psDisplayPriceRangeMax.text(`${params.currency}${maxVal}`);
			});
			objects.objSlider.on('set', () => {
				let range = objects.objSlider.get();
				qs.set('min-price', Math.round(range[0]));
				qs.set('max-price', Math.round(range[1]));
				this.toggleDisplayValues(objects.filters.$price, true);
				this.applyFilter(objects.filters.$price);
			});
			objects.objSlider.on('change', () => {
				let range = objects.objSlider.get();
				qs.set('min-price', Math.round(range[0]));
				qs.set('max-price', Math.round(range[1]));
			});

			$('[data-action="submit-price-range"]').on('click', () => {
				this.performFiltering();
				this.closeCollapse();
			});

			$('[data-action="submit-bedroom-selections"]').on('click', () => {
				this.performFiltering();
				this.closeCollapse();
			});

			let $handles = objects.$psPriceRange.find('.noUi-handle');
			$handles.attr('tabindex', 0);
			$handles.on('click', function () { this.focus(); });
			$handles.on('keydown', (e) => {
				let $handle = $(e.currentTarget);
				let whichHandle;
				if ($handle.hasClass('noUi-handle-lower')) {
					whichHandle = 0;
				} else if ($handle.hasClass('noUi-handle-upper')) {
					whichHandle = 1;
				}
				let amount = e.shiftKey ? 10 : 1;
				let value = Number(objects.objSlider.get()[whichHandle]);
				switch (e.which) {
					case 37:
					case 40:
						objects.objSlider.set(!whichHandle ? [value - amount, null] : [null, value - amount]);
						break;
					case 38:
					case 39:
						objects.objSlider.set(!whichHandle ? [value + amount, null] : [null, value + amount]);
						break;
				}
			});

			objects.objMapView = new MapView($, objects.$mapView, utils);

			objects.$mapView.on('markerclick', (e) => {
				this.showMapOverlay(e.detail.marker);
			});

			objects.$mapView.on('drag zoom', (e) => {
				$el.find('[data-template-marker-click]').hide();
			});

			flatpickr.localize(flatpickr.l10ns[params.locale]);
			flatpickr.l10ns[params.locale].weekdays.shorthand = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'S'];

			objects.objCalendar = flatpickr(objects.$calendar, {
				inline: true,
				mode: 'range',
				onDayCreate: this.handleCalendarDayCreate,
				onValueUpdate: this.handleCalendarValueUpdate,
				onReady: this.handleOnReady,
				minDate: 'today',
				static: true,
				onChange: () => {
					this.fixCalendarDayTabIndex(false);
					this.fixCalendarMonthTabIndex();
				},
				onMonthChange: () => {
					this.fixCalendarDayTabIndex(false);
					this.fixCalendarMonthTabIndex();
					objects.$psActiveMonthSR.text(`${moment().month(objects.objCalendar.currentMonth).format('MMMM')} ${objects.objCalendar.currentYear}`);
				},
				onYearChange: () => {
					this.fixCalendarDayTabIndex(false);
					this.fixCalendarMonthTabIndex();
				},
			});

			objects.$btnClearSort.on('click', (e) => {
				results.unfiltered = results.initial.slice();
				qs.delete('sort');
				qs.delete('dir');
				objects.$optionsSort.prop('checked', false);
				this.performFiltering();
			});

			objects.$btnSort.on('click', (e) => {
				let $btn = $(e.currentTarget);
				let ariaText = objects.$ariaAssistant.data('aria-assistant');
				let alertText;

				$el.find('[data-action="sort"]').removeClass('active');
				$btn.addClass('active');

				objects.$ariaAssistant.text(ariaText);

				let valueToSort = $btn.data('sort');

				if (qs.get('dir') !== 'asc') {
					qs.set('dir', 'asc');
					$btn.find('svg').first().show();
					$btn.find('svg').last().hide();

					$btn.attr('aria-label', $('.ascending-label').text() + $btn.data('sort-type'));
					alertText = ' ' + $('.ascending-label').data('type') + ' ';
				} else {
					qs.set('dir', 'desc');
					$btn.find('svg').first().hide();
					$btn.find('svg').last().show();

					$btn.attr('aria-label', $('.descending-label').text() + $btn.data('sort-type'));
					alertText = ' ' + $('.descending-label').data('type') + ' ';
				}

				objects.$ariaAssistant.text(objects.$ariaAssistant.data('aria-assistant') + alertText + $btn.data('sort-type'));
				this.sortResults(valueToSort);
			});

			objects.$btnView.on('click', (e) => {
				$el.find('[data-template-marker-click]').hide();

				let $btn = $(e.currentTarget);
				let viewToView = $btn.data('view');

				if (viewToView === 'grid') {
					objects.$btnView.removeClass('active');
					$btn.addClass('active');
					objects.$results.attr('data-view', 'grid');
					objects.$optionsSortCollapse.collapse('hide');
				} else if (viewToView === 'map') {
					objects.$btnView.removeClass('active');
					$btn.addClass('active');
					objects.objMapView.resize();
					objects.$results.attr('data-view', 'map');
					objects.$optionsSortCollapse.collapse('hide');
					objects.objMapView.fitMap();
				} else if (viewToView === 'filters') {
					$el.find('.filters').show();
					objects.$optionsSortCollapse.collapse('hide');

					// Hide grid or map view on mobile so that the filter "takes over the screen"
					let viewport = utils.getViewportSize();
					if (viewport === 'xs' || viewport === 'sm') {
						if (objects.$results.attr('data-view') === 'grid') {
							objects.$gridView.hide();
						} else {
							objects.$mapView.hide();
						}
					}
				} else if (viewToView === 'key') {
					$btn.toggleClass('active');
				} else if (viewToView === 'sort') {
					$btn.addClass('active');
				}
			});

			objects.$optionsSortCollapse.on('hide.bs.collapse', () => {
				$el.find('[data-view="sort"]').removeClass('active');
			});

			objects.$btnFiltersBack.on('click', (e) => {
				// Show grid or map view since the filter "takes over the screen" on mobile
				if (objects.$results.attr('data-view') === 'grid') {
					objects.$gridView.show();
				} else {
					objects.$mapView.show();
					if (utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm') {
						objects.objMapView.fitMap();
					}
				}

				$el.find('.filters').hide();
				utils.scrollTo(null, null, null, () => { });
			});

			const outsideClickListener = (event) => {
				if (!$(event.target).closest('.filters').length) {
					// close the filter when a click occurs outside of the filters
					objects.$filters.find('.collapse').collapse('hide');
				}
			};
			document.addEventListener('click', outsideClickListener);

			$el.on('click', 'a[data-review-count]', this.showReviewModal);

			objects.objLoader = new Loader($, objects.$loader);

			this.setInitialValues();

			this.fixCalendarDayTabIndex();
		};
	}

	name() {
		return 'ParametricSearch';
	}

	init() {
		this.firstRun();
	}
}

export default ParametricSearch;
