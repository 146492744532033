class FAQ {

	/**
	 * FAQ Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - FAQ instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		let qs = new URLSearchParams(window.location.search);

		const params = {
			section: qs.get('section')
		};
		const objects = {
			$form: $el.find('form'),
			$searchInput: $el.find('input[type="search"]'),
			$btnSearch: $el.find('[data-action="search"]'),
			$btnClose: $el.find('[data-action="close"]'),
			$searchLabel: $el.find('.search-label'),
			$faqs: $el.find('[data-faq]'),
			$faqNav: $el.find('[data-module="NavTabs"]'),
			$faqSections: $el.find('[data-faq-section]'),
			$faqResults: $el.find('[data-faq-section="search"]'),
			$noResults: $el.find('.no-results'),
			$filter: $el.find('[data-module="Filter"]'),
			$checkboxes: $el.find('.custom-control-input')

		};

		const results = {
			$filtered: [],
			$unfiltered: []
		};

		this.performFAQSearch = (query) => {

			objects.$checkboxes.prop('checked', false);

			console.log('Searching for: ', query);
			objects.$faqNav.find('.active').removeClass('active');

			results.$filtered = results.$unfiltered.filter(function() {
				let faqContent = $(this).text().toLowerCase();
				return faqContent.indexOf(query) > -1;
			});

			objects.$faqSections.hide();
			objects.$faqResults.empty();

			if (results.$filtered.length > 0) {
				objects.$noResults.hide();
				objects.$faqResults.append(results.$filtered);
				objects.$faqResults.show();
			} else {
				objects.$faqResults.hide();
				objects.$noResults.show();
			}

			objects.$searchLabel.find('span:nth-child(2)').show();
			objects.$searchLabel.find('span:nth-child(1)').hide();
		};

		this.handleFilterChange = (e) => {
			objects.$searchInput.val('');
			objects.$faqResults.empty();
			objects.$faqResults.hide();
			objects.$noResults.hide();
			objects.$faqSections.show();

			objects.$btnSearch.show();
			objects.$btnClose.hide();

			objects.$searchLabel.find('span:nth-child(1)').show();
			objects.$searchLabel.find('span:nth-child(2)').hide();

		};

		this.handleSearchInputChange = (e) => {
			objects.$btnSearch.hide();
			objects.$btnClose.show();
			let query = $(e.currentTarget).val().toLowerCase();
			if (query.length >= 3) {
				this.performFAQSearch(query);
			}
		};

		this.firstRun = () => {

			console.info('~~~ FAQ Module ~~~');

			results.$unfiltered = objects.$faqs.clone();

			objects.$searchInput.on('keyup', this.handleSearchInputChange);
			objects.$filter.on('filterchange', this.handleFilterChange);
			objects.$btnClose.on('click', this.handleFilterChange);

			if (params.section) {
				let $option = objects.$filter.find(`input[name="faq-section"][value="${params.section}"]`);

				if ($option) {
					$option.click();
					$option.prop('checked', true).trigger('change');
				}
			}
		};
	}

	name() {
		return 'FAQ';
	}

	init() {
		this.firstRun();
	}

}

export default FAQ;

