import CustomEvent from 'custom-event';

class RateApi {

	constructor() {
		// let _this = this;
		RateApi._loading = false;
		RateApi._rateData = null;
		RateApi._startDate = '';
		RateApi._endDate = '';

		this.firstRun = () => {
			console.info('~~~ RateApi Module ~~~');

			// $(document).on('reservationdatesselected', this.handleDateChange);
			// On ajax complete, initialize carousel
			// $(document).on('ajaxStop', this.initializeCarousel);
		};
	}

	name() {
		return 'RateApi';
	}

	init() {
		this.firstRun();
	}

	getRateData(startDate, endDate) {
		console.log('RateApi - getRateData');
		let $bundle = $('#rateapi-bundle');
		let $path = $bundle.data('path'); //URL to action

		let promoCode = '';
		$('[data-promo-code]').each(function () {
			let myCode = $(this).attr('data-promo-code');
			if(promoCode.length > 0 && myCode.length > 0) promoCode += ',';
			promoCode += $(this).attr('data-promo-code');
		});

		let propertyIds = this.propertyIds();
		let roomTypes = this.roomTypes();

		// const startDateMoment = moment(localStorage.getItem('startDate'));
		// const endDateMoment = moment(localStorage.getItem('endDate'));
		// let startDate;
		// let endDate;
		// if (startDateMoment.isValid() && endDateMoment.isValid()) {
		// 	startDate = startDateMoment.format('YYYY-MM-DD');
		// 	endDate = endDateMoment.format('YYYY-MM-DD');
		// }

		const url = `${$path}?propertyIds=${propertyIds}&startDate=${startDate}&endDate=${endDate}&roomType=${roomTypes}&promoCodes=${promoCode}`;
		$.ajax({
			cache: false,
			type: 'GET',
			url: url,
			dataType: 'json'
		})
			.done((newData) => {
				RateApi._rateData = newData; //TODO transform this?
				this.dataLoaded(RateApi._rateData);
			})
			.fail((jqXHR, textStatus, errorThrown) => {
				//console.log(`Error searching for property: ${textStatus}, ${errorThrown}`);
			});
	}

	requestData(startDate, endDate) {		
		console.log('RateApi.requestData - Rate Data Requested for '+startDate+' - '+endDate);
		if(startDate !== RateApi._startDate || endDate !== RateApi._endDate) {
			// dates changed, load again
			RateApi._startDate = startDate;
			RateApi._endDate = endDate;
			RateApi._isLoading = false;
			RateApi._rateData = null;
		}

		if(RateApi._rateData) {
			this.dataLoaded(RateApi._rateData);
			return;
		}

		if(!RateApi._isLoading) {
			RateApi._isLoading = true;
			this.getRateData(startDate, endDate);
		}
	}

	dataLoaded(data) {
		RateApi._rateData = data;
		console.log('RateApi = Data Loaded ',data);
		// signal listeners
		let event = new CustomEvent('room-rates-loaded', { 'detail': { data: data }} );
		$(document)[0].dispatchEvent(event);
	}

	propertyIds() {
		let propertyIds = [];
		// Called directly by: OfferPropertySearch, RoomDetail, OfferSummary, PropertyDetail
		// Called indirectly by: PropertyGrid (PropertyDetail)
		// :not(button) eliminates specifically the choices in the reservation flyout
		$('[data-property-id]:not(button)').each(function(){
			let pid = $(this).data('property-id');
			if(!propertyIds.includes(pid)) {
				propertyIds.push(pid);
			}
		});

		return propertyIds;
	}

	roomTypes() {
		let roomTypes = [];
		$('[data-room-type]').each(function(){
			let room = $(this).data('room-type');
			if(!roomTypes.includes(room)) {
				roomTypes.push(room);
			}
		});

		return roomTypes;
	}
}

export default RateApi;
