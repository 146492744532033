class DiscountDetailInfo {

	/**
	 * DiscountDetailInfo Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - DiscountDetailInfo instance
	 */

	constructor($, $el) {    

		this.firstRun = () => {
			console.info('~~~ DiscountDetailInfo Module ~~~');
		};

		// catch modal dialog 'x' button tab out
		$('.tandc_close_x').on('keydown', (e) => {
			this.handleTab(e);
		});

		// catch modal dialog 'close' button tab out
		$('.tandc_close_button').on('keydown', (e) => {
			this.handleTab(e);
		});
	}    

	handleTab(e){        
		let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

		if (!isTabPressed) {
			return;
		} else {
			if(e.target.className.endsWith('tandc_close_x')){
				$('.tandc_close_button').focus();
			} else { // .tandc_close_button
				$('.tandc_close_x').focus();
			}
			e.preventDefault();
		}


	}

	name() {
		return 'DiscountDetailInfo';
	}

	init() {
		this.firstRun();
	}
}

export default DiscountDetailInfo;