/**
 * A module that provides tab navigation functionality.
 *
 * This class is responsible for initializing the tab navigation
 * functionality on a given element. It provides functions to toggle
 * the menu and to navigate between tabs using the arrow keys.
 */
class NavTabs {
	/**
	 * Constructor function.
	 *
	 * @param {jQuery} $ jQuery object
	 * @param {jQuery} element Element to initialize the module on
	 */
	constructor($, element) {

		/**
		 * The element to initialize the module on.
		 *
		 * @type {jQuery}
		 */
		this.element = element;

		/**
		 * The navigation links.
		 *
		 * @type {jQuery}
		 */
		this.navLinks = element.find('.nav-link');

		/**
		 * The tab panes.
		 *
		 * @type {jQuery}
		 */
		this.tabPanes = $('.tab-pane');

		/**
		 * Toggle the menu.
		 */
		this.toggleMenu = () => {
			if (this.element) {
				this.element.toggleClass('open');
			}
		};

		/**
		 * Show the tab corresponding to the clicked link.
		 *
		 * @param {jQuery.Event} e Event
		 */
		this.showTab = (e) => {
			if (!this.element || !this.navLinks) {
				return;
			}

			e.preventDefault();
			this.activateTab($(e.target).closest('.nav-link'));
		};

		/**
		 * Activate the given tab.
		 *
		 * @param {jQuery} $tab The tab to activate
		 */
		this.activateTab = ($tab) => {
			if (!this.element || !this.navLinks || !this.tabPanes) {
				return;
			}

			const $target = $($tab.data('target'));

			this.navLinks.attr('aria-selected', 'false').removeClass('active').attr('tabindex', '-1').parent().removeClass('nav-item-active');
			this.tabPanes.removeClass('show active').attr('tabindex', '-1');

			if ($target && $target.length) {
				$tab.attr('aria-selected', 'true').addClass('active').attr('tabindex', '0').parent().addClass('nav-item-active');
				$target.addClass('show active').attr('tabindex', '0');
			}
		};

		/**
		 * Navigate between tabs using the arrow keys.
		 *
		 * @param {jQuery.Event} e Event
		 */
		this.navigateTabs = (e) => {
			if (!this.element || !this.navLinks) {
				return;
			}

			const $activeTab = this.navLinks.filter('[aria-selected="true"]');
			let $nextTab = null;

			if (e.which === 37) {
				// Left arrow
				$nextTab = $activeTab.parent().prev().find('.nav-link');
				if (!$nextTab.length) {
					$nextTab = this.navLinks.last();
				}
			} else if (e.which === 39) {
				// Right arrow
				$nextTab = $activeTab.parent().next().find('.nav-link');
				if (!$nextTab.length) {
					$nextTab = this.navLinks.first();
				}
			}

			if ($nextTab && $nextTab.length) {
				e.preventDefault();
				$nextTab.focus();
				this.activateTab($nextTab);
			}
		};

		/**
		 * Initialize the module.
		 */
		this.init = () => {
			if (!this.element || !this.navLinks) {
				return;
			}

			this.element.find('[data-toggle="tab"]').on('click', this.toggleMenu);
			this.navLinks.on('click', this.showTab);
			this.navLinks.on('keydown', (e) => {
				if (!this.element || !this.navLinks) {
					return;
				}

				if (e.which === 37 || e.which === 39) {
					// Left or right arrow
					this.navigateTabs(e);
				} else if (e.which === 13 || e.which === 32) {
					// Enter or space
					this.showTab(e);
				} else if (e.which === 9 && !e.shiftKey) {
					e.preventDefault();
					const $target = $('#' + $(e.target).attr('aria-controls'));
					if ($target && $target.length) {
						$target.focus();
					}
				}
			});
			this.activateTab(this.navLinks.first());
		};
	}

	/**
	 * The name of the module.
	 *
	 * @returns {string}
	 */
	name() {
		return 'NavTabs';
	}
}

export default NavTabs;
