import moment from 'moment';
import BookNowButton from './BookNowButton';
import FavoriteButton from './FavoriteButton';
import PropertyDetail from './PropertyDetail';

class PropertyGrid {

	/**
	 * PropertyGrid Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PropertyGrid instance
	 * @param {object} utils - Utils instance
	 */

	constructor($, $el, utils, rateApi) {
		const objects = {
			objPropertyGrid: null
		};

		this.resize = () => { };

		this.setBookUrl = () => {
			let $btnBook = $('[data-action="book"]');

			$btnBook.each((i, e) => {
				let bookUrl = $(e).attr('href');
				let startDate = localStorage.getItem('startDate');
				let endDate = localStorage.getItem('endDate');

				if(startDate) {
					bookUrl = bookUrl
						.replace(/{arrive}/g, moment(startDate).format('MM-DD-YYYY'))
						.replace(/{depart}/g, moment(endDate).format('MM-DD-YYYY'));
				}

				$(e).attr('href', bookUrl);
			});
		};

		this.firstRun = () => {
			console.info('~~~ PropertyGrid Module ~~~');

			objects.objPropertyGrid = new PropertyGrid($, $el, utils);

			$(document).ajaxComplete(function(event, xhr, settings) {
				objects.objPropertyGrid.setBookUrl();

				if(settings['url'] && (settings['url'].includes('hidden.propertyLoad.html.ajax') || settings['url'].includes('hidden.loadProperties.html.ajax'))) {
					console.log('Initializing Modules');

					// Initialize generated Favorite Buttons
					$('.property-grid').find('.btn-favorite').each((i, fv) => {
						let module = new FavoriteButton($, $(fv), utils);
						if ($(fv).data('init') !== false) {
							module.init();
						}
					});
					// initialize generated Property Details
					$('.property-grid').find('[data-module="PropertyDetail"]').each((i, fv) => {
						let module = new PropertyDetail($, $(fv), utils, rateApi);
						if ($(fv).data('init') !== false) {
							module.init();
						}
					});
					// initialize generated Property Details
					$('.property-grid').find('[data-module="BookNowButton"]').each((i, fv) => {
						let module = new BookNowButton($, $(fv), utils);
						if ($(fv).data('init') !== false) {
							module.init();
						}
					});
				}
			});
		};
	}

	name() {
		return 'PropertyGrid';
	}

	init() {
		this.firstRun();
	}

}

export default PropertyGrid;

