
const NAVBAR_HEIGHT_THRESHOLD = 72;

class Header {

	/**
	 * Header Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Header instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const objects = {
			$body: $('body'),
			$navbar: $el.find('.navbar'),
			$navbarBrand: $el.find('.navbar-brand'),
			$navbarCollapse: $el.find('.navbar-collapse'),
			$navbarPanel: $el.find('.navbar-panel'),
			$navbarPanelContainer: $el.find('.navbar-panel-container'),
			$navbarPanelPreview: $el.find('.navbar-panel-preview'),
			$navbarLinks: $el.find('.navbar-nav > li > button, .navbar-nav > li > a'),
			$backBtn: $el.find('[data-action="back"]'),
			$propertyNavbarCollapse: $el.find('.property-navbar-collapse'),
			$globalBreadcrumbCollapse: $el.find('.global-breadcrumb > .menu-wrapper > .collapse'),
			$globalBreadcrumbLinks: $el.find('.global-breadcrumb > .menu-wrapper > a'),
			$navbarPanelNav: $el.find('.navbar-panel-nav'),
		};

		this.handleBack = (e) => {
			const $parent = $(e.target).parent();
			$parent.collapse('hide');
		};

		this.closeNavbarPanels = (e) => {
			objects.$navbarPanel.collapse('hide');

			if (e && e.target) {
				const parents = Array.from($(e.target).parents());
				if (!parents.includes(objects.$globalBreadcrumbCollapse[0])) {
					objects.$globalBreadcrumbCollapse.collapse('hide');
				}
			}
		};

		this.handleNavbarPreview = (e) => {
			const $previewLink = $(e.currentTarget);
			const linkPreviewImageSrc = $previewLink.attr('data-preview-image');
			const linkPreviewText = $previewLink.attr('data-preview-text');

			this.showNavbarPanelPreviewImage(linkPreviewImageSrc, linkPreviewText);
			this.showNavbarPanelPreviewText(linkPreviewText);
		};

		this.showNavbarPanelPreviewImage = (imageSrc, imageAlt) => {
			const $navbarPanelPreviewImage = objects.$navbarPanelPreview.find('img');
			const defaultImageSrc = objects.$navbarPanelPreview.attr('data-preview-image-default');
			const defaultImageAlt = objects.$navbarPanelPreview.attr('data-preview-image-alt-default');

			const previewImageSrc = imageSrc || defaultImageSrc;
			const previewImageAlt = imageAlt || defaultImageAlt;

			if (previewImageSrc) {
				$navbarPanelPreviewImage.attr('src', previewImageSrc);
				$navbarPanelPreviewImage.attr('alt', previewImageAlt);
				$navbarPanelPreviewImage.show();
			} else {
				// no value or default value, hide image
				$navbarPanelPreviewImage.attr('src', '');
				$navbarPanelPreviewImage.attr('alt', '');
				$navbarPanelPreviewImage.hide();
			}
		};

		this.showNavbarPanelPreviewText = (text) => {
			const $navbarPanelPreviewText = objects.$navbarPanelPreview.find('p');
			const defaultText = objects.$navbarPanelPreview.attr('data-preview-text-default');

			const previewText = text || defaultText;

			if (previewText) {
				$navbarPanelPreviewText.text(previewText);
				$navbarPanelPreviewText.show();
			} else {
				// no value or default value, hide text
				$navbarPanelPreviewText.text('');
				$navbarPanelPreviewText.hide();
			}
		};

		this.resize = () => {
			if (utils.getViewportSize() === 'lg' || utils.getViewportSize() === 'xl') {
				objects.$body.removeClass('overflow-hidden');
			}
			this.closeNavbarPanels();
		};

		this.scroll = () => {
			// add sticky class on scroll
			const $scroll = $(window).scrollTop();

			if ($scroll >= NAVBAR_HEIGHT_THRESHOLD) {
				objects.$navbar.addClass('is-stuck');
			} else {
				objects.$navbar.removeClass('is-stuck');
			}
		};

		this.firstRun = () => {
			console.info('~~~ Header Module ~~~');

			objects.$navbarLinks.on('focus', this.closeNavbarPanels);

			objects.$navbarPanel.on('show.bs.collapse', () => {
				if (utils.getViewportSize() === 'lg' || utils.getViewportSize() === 'xl') {
					this.closeNavbarPanels();
				}
			});

			// hide global breadcrumb collapse on other top-level breadcrumb link focus
			objects.$globalBreadcrumbLinks.on('focus', () => {
				objects.$globalBreadcrumbCollapse.collapse('hide');
			});

			objects.$navbarBrand.on('focus', () => {
				this.closeNavbarPanels();
			});

			objects.$navbarCollapse.on('shown.bs.collapse', (e) => {
				if (e.target.id === objects.$navbarCollapse.attr('id')) {
					objects.$navbar.addClass('active');
					// lock scrolling on body when navbar (top-level) is expanded
					objects.$body.addClass('overflow-hidden');
					// reset scroll positions on window and collapse
					$(window).scrollTop(0);
					objects.$navbarCollapse.scrollTop(0);
					// collapse property navbar
					objects.$propertyNavbarCollapse.collapse('hide');
				}
			});

			objects.$navbarCollapse.on('hidden.bs.collapse', (e) => {
				if (e.target.id === objects.$navbarCollapse.attr('id')) {
					objects.$navbar.removeClass('active');
					// remove scrolling lock on body when navbar (top-level) is collapsed
					objects.$body.removeClass('overflow-hidden');
				}
			});

			objects.$propertyNavbarCollapse.on('shown.bs.collapse', (e) => {
				if (e.target.id === objects.$propertyNavbarCollapse.attr('id')) {
					// lock scrolling on body when property navbar is expanded
					objects.$body.addClass('overflow-hidden');
				}
			});

			objects.$propertyNavbarCollapse.on('hidden.bs.collapse', (e) => {
				if (e.target.id === objects.$propertyNavbarCollapse.attr('id')) {
					// remove scrolling lock on body when property navbar is collapsed
					objects.$body.removeClass('overflow-hidden');
				}
			});

			// add preview link events
			objects.$navbarPanel.find('a').on('focus', this.handleNavbarPreview);
			objects.$navbarPanel.find('a').on('mouseenter', utils.debounce(this.handleNavbarPreview, 500));
			objects.$navbarPanelNav.on('mouseleave', utils.debounce(() => {
				this.showNavbarPanelPreviewImage();
				this.showNavbarPanelPreviewText();
			}, 500));

			objects.$backBtn.on('click', this.handleBack);

			utils.setTooltips();

			var browserZoomLevel = Math.round(window.devicePixelRatio * 100);
			var navbarHeader = $('.navbar-header');

			if (browserZoomLevel >= 200) {
				navbarHeader.addClass('zoomed');
			}

			$('[data-module="Header"]').on("keydown", (e) => {
				//console.log("keydown", e.key);
				const headerExpanded = $('.navbar-collapse.collapse.show').length > 0;
				if (headerExpanded) {
					const searchBoxExpanded = $('#search-collapse.collapse.show').length > 0;
					const navbarToggler = $('.navbar-toggler')[0];
					const mainNavListItems = $('.main-nav-link');
					const lastMenuEl = mainNavListItems[mainNavListItems.length - 1];

					if (document.activeElement === lastMenuEl && e.key === "Tab" && !e.shiftKey) {
						e.preventDefault();
						navbarToggler.focus();
					} else if (document.activeElement === navbarToggler && e.key === "Tab" && e.shiftKey) {
						e.preventDefault();
						lastMenuEl.focus();
					} else if (e.key === "Escape" && !searchBoxExpanded) {
						e.preventDefault();
						navbarToggler.click();
					}
				}
			});

			const collapsibleMenu = $('.navbar-collapse.collapse');
			var observer = new MutationObserver(function(mutations) {
				mutations.forEach(function(mutation) {
					var attributeValue = $(mutation.target).prop(mutation.attributeName);
					const ariaHiddenValue = attributeValue.includes("show");
					const mainElements = $('.bodywrapper').children();

					$('.bodywrapper').children().each((index, node) => {
						if (node.nodeName !== "HEADER" && node.ariaModal !== "true") {
							$(node).attr('aria-hidden', ariaHiddenValue);
						}
					});
				});
			});

			observer.observe(collapsibleMenu[0], {
				attributes: true,
				attributeFilter: ['class']
			});			
		};
	}

	name() {
		return 'Header';
	}

	init() {
		this.firstRun();
	}

}

export default Header;
