import flatpickr from 'flatpickr';
// import flatpickr from '../vendor/flatpickr';
import moment from 'moment';
import CustomEvent from 'custom-event';
import Loader from '../modules/Loader';

class CalendarFlyout {

	/**
	 * Range Calendar Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Carousel instance
	 * @param {object} Utils - Imports from Utils
	 */

	constructor($, $el, utils) {

		let qs = new URLSearchParams(window.location.search);
		let resize = false;
		const params = {
			rateCacheExpiration: $el.data('property-rate-cache-expiration'),
			rateUrl: $el.data('property-rate-url'),
			propertyId: $el.data('property-id'),
			currency: $el.data('currency') || '$',
			startDate: qs.get('start-date'),
			endDate: qs.get('end-date'),
			alwaysAvailable: $el.data('alwaysavailable') || false
		};

		const $modal = $('#collapse-date-picker');

		const objects = {
			$leftPickerInput: $el.find('.left-picker'),
			$enterDatesHelp: $el.find('.enter-dates-help'),
			$enterDatesResult: $el.find('.enter-dates-result'),
			$enterDatesButton: $el.find('.enter-dates-btn'),
			$bookDatesButton: $('.book-dates-btn'),
			$changeDatesButton: $('[data-change-dates]'),
			$changeDatesWhenUnavailableButton: $el.find('[data-change-dates-when-unavailable]'),
			$selectedDates: $el.find('.result-dates'),
			$loader: $el.find('[data-loader]'),
			$roomListing: $el.find('.rooms-listing'),
			$buttonContainer: $el.find('.js-avail-buttons'),
			$minStayMsg: $el.find('.js-avail-minstay-msg'),
			$daysInAdvanceMsg: $el.find('.js-days-advance-msg'),
			$closedMsg: $el.find('.js-avail-closed-msg'),
			$requiredMsgs: $el.find('.dates-unavailable-msg'),
			$unavailableDialog: $modal.find('.dates-unavailable-container'),
			$unavailableClose: $modal.find('.dates-unavailable-dialog .btn-close'),
			$cfActiveMonthSR: $modal.find('#cf-active-month-sr'),
			$daysInAdvMsg: $el.find('.js-days-in-advance-msg'),
			$minStayHelpMsg: $el.find('.js-avail-minstay-help'),
			objLoader: null,
			rateData: {},
			$toggleLink: $('.js-avail-buttons [data-check-rates-btn]'),
			$saveCalendarBtn: $el.find('.btn-save-dates'),
			$cancelSaveCalendar: $el.find('.cancel-save-calendar'),
			$displayDateRange: $el.find('.display-date-range'),
			flatpickrConfig: {},
			$btnOpenCal: $('[data-open-calendar]')
		};

		let initialSelectedDatesGlobal = '';
		let selectedDatesGlobal = '';
		let pickerGlobal = '';

		/**
		 * initCalendars()
		 * Activates left and right range calendars.
		 */
		this.initCalendars = () => {
			const viewportSize = utils.getViewportSize();
			const isMobile = viewportSize !== 'md' && viewportSize !== 'lg' && viewportSize !== 'xl';
			const monthCount = isMobile ? 1 : 2;

			this.handleLocalStorage().always(() => {
				objects.flatpickrConfig = {
					mode: 'range',
					showMonths: monthCount,
					inline: true,
					static: 'true',
					minDate: 'today',
					defaultDate: this.defaultDates,
					onMonthChange: this.onViewChange,
					onYearChange: this.onViewChange,
					onValueUpdate: this.onPickerUpdate,
					onDayCreate: this.handleCalendarDayCreate,
					onReady: this.handleOnReady,
					onChange: () => {
						this.fixCalendarDayTabIndex(false);
					},
				};

				this.leftPicker = flatpickr(objects.$leftPickerInput[0], objects.flatpickrConfig);
				this.onViewChange([], null, this.leftPicker);
				this.setHelperText();
				this.showHideMsg();
			});
		};

		this.handleLocalStorage = () => {
			let localStorageStartDate = moment(localStorage.getItem('startDate'));
			let localStorageEndDate = moment(localStorage.getItem('endDate'));
			let today = moment().startOf('day').toDate();

			// Check date from parameters
			let paramStartDate = moment(params.startDate);
			let paramEndDate = moment(params.endDate);
			if (paramStartDate.isValid() && paramEndDate.isValid()) {
				let startDate = paramStartDate.toDate();
				let endDate = paramEndDate.toDate();

				if (startDate >= today) {
					this.startDate = startDate;
					this.endDate = endDate;
					this.defaultDates = [startDate, endDate];
					// this.setBookUrl();
					// return this.getRateData(this.startDate, this.endDate);
					return $.when(null);
				}
			}

			// Check date from local storage
			if (localStorageStartDate.isValid() && localStorageEndDate.isValid()) {
				let startDate = localStorageStartDate.toDate();
				let endDate = localStorageEndDate.toDate();

				if (startDate >= today) {
					this.startDate = startDate;
					this.endDate = endDate;
					this.defaultDates = [localStorageStartDate.toDate(), localStorageEndDate.toDate()];
					// this.setBookUrl();
					// return this.getRateData(this.startDate, this.endDate);
				} else {
					localStorage.removeItem('startDate');
					localStorage.removeItem('endDate');
				}
			} else {
				let startStay = moment().startOf('day').add(7,'days');
				let endStay = moment().startOf('day').add(11,'days');
				this.startDate = startStay.toDate();
				this.endDate = endStay.toDate();

				this.defaultDates = [this.startDate, this.endDate];
			}

			return $.when(null);
		};

		this.handleOnReady = (selectedDates, inputValue, picker) => {

			selectedDatesGlobal = selectedDates;
			initialSelectedDatesGlobal = selectedDates;
			pickerGlobal = picker;

			// flatpickr ADA fixes
			let $monthSelect = $('select.flatpickr-monthDropdown-months');
			let $yearInput = $('input.cur-year');
			$el.find('#mar-month-year').text(`${$monthSelect.find('option:selected').text()} ${$yearInput.val()}`);
			$monthSelect.attr('aria-label', 'select month');
			$yearInput.attr('aria-label', 'select year');
			$yearInput.removeAttr('tabindex');

			$('.flatpickr-calendar').attr('role', 'application');
			$('.flatpickr-prev-month, .flatpickr-next-month').attr('role', 'button').attr('tabindex', 0);
			$('.flatpickr-input').attr('tabindex', -1);

			$('.flatpickr-weekdaycontainer').attr('aria-hidden', 'true');
			$('.flatpickr-prev-month').attr('aria-label', 'previous month');
			$('.flatpickr-next-month').attr('aria-label', 'next month');

			$('.flatpickr-prev-month, .flatpickr-next-month').on('keydown', (e) => {
				if (e.key === 'Enter') {
					if ($(e.target).hasClass('flatpickr-prev-month')) {
						this.leftPicker.changeMonth(-1);
					} else if ($(e.target).hasClass('flatpickr-next-month')) {
						this.leftPicker.changeMonth(1);
					}
				}
			});

			const mStartDate = moment(selectedDates[0]);
			const mEndDate = moment(selectedDates[1]);
			objects.$displayDateRange.find('span').text(`${mStartDate.format('MMM DD, YYYY')} - ${mEndDate.format('MMM DD, YYYY')}`);
			objects.$displayDateRange.show();

			let focusableEls = [
				$modal.find('.flatpickr-prev-month'),
				$modal.find('.cur-year'),
				$modal.find('.flatpickr-next-month'),
				$modal.find('.cancel-save-calendar'),
				($modal.find('.flatpickr-day.selected') || $modal.find('.flatpickr-day[tabindex=0]')[0]),
				$modal.find('.btn-save-dates'),
			];

			$modal.on('keydown', (e) => {
				let $activeElement = $(document.activeElement);

				switch (e.key) {
					case 'Tab': {
						if (e.shiftKey) {
							if ($activeElement.hasClass('flatpickr-day')) {
								e.preventDefault();
								$modal.find('.cancel-save-calendar').focus();
							} 
							else if ($activeElement.hasClass('cancel-save-calendar')) {
								e.preventDefault();
								$modal.find('.flatpickr-next-month').focus();
							} 
							else if ($activeElement.hasClass('cur-year')
											&& $('.btn-save-dates').is(':disabled') 
											&& $('.flatpickr-prev-month').hasClass('flatpickr-disabled')) {
								e.preventDefault();
								
								// let $anchor = $modal.find('.flatpickr-day.startRange:not(.nextMonthDay):not(.prevMonthDay)');

								let $anchor = $modal.find('.flatpickr-day.today');

								if (
									$anchor.length > 0
									&& $anchor.is(':visible')
								) {
									//
								} else {
									
									$anchor = $modal.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
								}
								$anchor.attr('tabindex', 0);
								$anchor.focus();
							} 
							else if ($activeElement.hasClass('cur-year')
											&& !$('.btn-save-dates').is(':disabled') 
											&& $('.flatpickr-prev-month').hasClass('flatpickr-disabled')) {
								e.preventDefault();
								$(focusableEls[focusableEls.length - 1]).focus();
							} 
							else if ($activeElement.hasClass('cur-year')) {
								e.preventDefault();
								$modal.find('.flatpickr-prev-month').focus();
							} 
							else if ($activeElement.hasClass('flatpickr-prev-month') 
											&& $('.btn-save-dates').is(':disabled')) {
								e.preventDefault();
								let $anchor = $modal.find('.flatpickr-day.startRange:not(.nextMonthDay):not(.prevMonthDay)');
								if (
									$anchor.length > 0
									&& $anchor.is(':visible')
								) {
									//
								} else {
									$anchor = $modal.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
								}
								$anchor.attr('tabindex', 0);
								$anchor.focus();
							} 
							else if ($activeElement.hasClass('flatpickr-prev-month') && !$('.btn-save-dates').is(':disabled')) {
								e.preventDefault();
								$(focusableEls[focusableEls.length - 1]).focus();
							} 
							else if ($activeElement.hasClass('btn-save-dates')) {
								e.preventDefault();
								let $anchor = $modal.find('.flatpickr-day.startRange:not(.nextMonthDay):not(.prevMonthDay)');
								if (
									$anchor.length > 0
									&& $anchor.is(':visible')
								) {
									//
								} else {
									$anchor = $modal.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
								}
								$anchor.attr('tabindex', 0);
								$anchor.focus();
							}
						} 
						else if ($activeElement.hasClass('flatpickr-next-month')) {
							let currentMonth = moment().month();
							let selectedMonth = this.leftPicker.currentMonth;
							if (currentMonth === selectedMonth) {
								e.preventDefault();

								let $anchor = $modal.find('.flatpickr-day.today');
								
								if (
									$anchor.length > 0
									&& $anchor.is(':visible')
								) {
									//
								} else {
									
									$anchor = $modal.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
								}
								$anchor.attr('tabindex', 0);
								$anchor.focus();
							}
						}
						else if ($activeElement.hasClass('flatpickr-day') && $('.btn-save-dates').is(':disabled')) {
							e.preventDefault();
							$(focusableEls[focusableEls.length - 3]).focus();
						} 
						else if ($activeElement.hasClass('flatpickr-day')) {
							e.preventDefault();
							$(focusableEls[focusableEls.length - 1]).focus();
						} else if ($activeElement.hasClass('btn-save-dates')) {
							e.preventDefault();
							$(focusableEls[focusableEls.length - 3]).focus();
						}
						break;
					}
					case 'PageUp': {
						if (e.shiftKey) {
							if (
								this.leftPicker.currentYear === moment().year() + 1 && this.leftPicker.currentMonth >= moment().month()
								|| (this.leftPicker.currentYear > moment().year() + 1)
							) {
								this.leftPicker.changeMonth(-12);
							} else {
								// jump to first available (current) date
								this.leftPicker.jumpToDate(moment().toDate(), true);
							}
						} else {
							this.leftPicker.changeMonth(-1);
						}

						this.fixCalendarDayTabIndex(true);
						break;
					}
					case 'PageDown': {
						if (e.shiftKey) {
							this.leftPicker.changeMonth(12);
						} else {
							this.leftPicker.changeMonth(1);
						}

						this.fixCalendarDayTabIndex(true);
						break;
					}
					case 'Home': {
						if ($activeElement.hasClass('flatpickr-day')) {
							e.preventDefault();
							// move to start of week
							const dataDate = moment($activeElement.attr('data-date'));
							const startOfWeek = dataDate.clone().startOf('week');
							const startOfMonth = dataDate.clone().startOf('month');
							let targetDate = (startOfWeek.month() < dataDate.month()) ? startOfMonth : startOfWeek;
							$(`.flatpickr-day[data-date="${targetDate.format('YYYY-MM-DD')}"]`).focus();
						}
						break;
					}
					case 'End': {
						if ($activeElement.hasClass('flatpickr-day')) {
							e.preventDefault();
							// move to end of week
							const dataDate = moment($activeElement.attr('data-date'));
							const endOfWeek = dataDate.clone().endOf('week');
							const endOfMonth = dataDate.clone().endOf('month');
							let targetDate = (endOfWeek.month() > dataDate.month()) ? endOfMonth : endOfWeek;
							$(`.flatpickr-day[data-date="${targetDate.format('YYYY-MM-DD')}"]`).focus();
						}
						break;
					}
				}
			});
		};

		this.onViewChange = (selectedDates, inpValue, picker) => {
			// check if modal is visible
			let modalShown = ($modal.data('bs.modal') || {})._isShown;
			if(!modalShown) return;

			let visibleMonth = moment([this.leftPicker.currentYear, this.leftPicker.currentMonth]);

			let startDate = moment(visibleMonth).subtract(1, 'months');
			let endDate = moment(visibleMonth).add(3, 'months').subtract(1, 'days');

			if($el.data('property-id')) {
				objects.objLoader.startLoader();
				this.getRateData(startDate, endDate, params.propertyId);
			}

			$('.flatpickr-month + .flatpickr-month .numInput.cur-year').attr('tabindex', -1);

			objects.$cfActiveMonthSR.text(`${moment().month(this.leftPicker.currentMonth).format('MMMM')} ${this.leftPicker.currentYear}`);

			this.fixCalendarDayTabIndex(false);
		};

		this.fixCalendarDayTabIndex = (shouldFocus = true) => {
			setTimeout(() => {
				let $anchor = '';
				let currentMonth = moment().month();
				let selectedMonth = this.leftPicker.currentMonth;
				if (currentMonth === selectedMonth) {
					$anchor = $modal.find('.flatpickr-day.today');
					if (
						$anchor.length > 0
						&& $anchor.is(':visible')
					) {
						//
					} else {
						$anchor = $modal.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
					}
				} else {
					$anchor = $modal.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
				}

				$anchor.attr('tabindex', 0);
				if (shouldFocus) {
					$anchor.focus();
				}

				// if (
				// 	$anchor.length > 0
				// 	&& $anchor.is(':visible')
				// ) {
				// 	$anchor.attr('tabindex', 0);

				// 	if (shouldFocus) {
				// 		$anchor.focus();
				// 	}
				// } else {
				// 	$anchor = $modal.find('.flatpickr-day:not(.disabled):not(.nextMonthDay):not(.prevMonthDay)').first();
				// 	$anchor.attr('tabindex', 0);

				// 	if (shouldFocus) {
				// 		$anchor.focus();
				// 	}
				// }
				
			}, 100);
		};

		// this.setDateText = () => {
		// 	let mStartDate = moment(this.startDate);
		// 	let mEndDate = moment(this.endDate);
		
		// 	if (mStartDate.isValid() && mEndDate.isValid()) {
		// 		let dateStr = mStartDate.format('MMM D');
		// 		if (mEndDate.month() === mStartDate.month()) {
		// 			dateStr += ` - ${mEndDate.format('D')}`;
		// 		} else {
		// 			dateStr += ` - ${mEndDate.format('MMM D')}`;
		// 		}
		// 		objects.$selectedDates.html(dateStr);
		// 	}
		// };


		this.setHelperText = () => {
			let mStartDate = moment(this.startDate);
			let mEndDate = moment(this.endDate);

			if (mStartDate.isValid() && mEndDate.isValid()) {

				// hide the enter dates button
				objects.$enterDatesButton.addClass('d-none');

				objects.$enterDatesHelp.addClass('d-none');
				objects.$enterDatesResult.removeClass('d-none');

				let dateStr = mStartDate.format('MMM D');
				if (mEndDate.month() === mStartDate.month()) {
					dateStr += ` - ${mEndDate.format('D')}`;
				} else {
					dateStr += ` - ${mEndDate.format('MMM D')}`;
				}
				objects.$selectedDates.html(dateStr);

				let showBook = false;
				let propertyRates = objects.rateData[params.propertyId];
				if (propertyRates) {
					$.each(Object.keys(propertyRates), (i, e) => {
						let date = moment(e).toDate();
						let rate = propertyRates[e];
						if (rate && date >= this.startDate && date <= this.endDate) {
							if (date < this.endDate) {
								showBook = true;
							}
						}
					});
				}

				objects.$bookDatesButton.addClass('d-none');
				objects.$changeDatesWhenUnavailableButton.addClass('d-none');

				// show either the change dates button or the book now button.
				objects.$changeDatesButton.removeClass('d-none');
				if(!params.propertyId) {
					$modal.find('.flatpickr-key').addClass('d-none');
				} else {
					$modal.find('.flatpickr-key').removeClass('d-none');
				}

				// persist last selected start and end date in local storage.
				localStorage.setItem('startDate', moment(this.startDate).format('YYYY-MM-DD'));
				localStorage.setItem('endDate', moment(this.endDate).format('YYYY-MM-DD'));

				// setTimeout(function(){
				// 	$modal.find('[data-dismiss="modal"]')[0].focus();
				// }, 500);
			} else {
				// show the enter dates button and the help text.
				objects.$changeDatesButton.removeClass('d-none');

				// hide the selected dates text, the book now button, and the change dates button.
				objects.$enterDatesResult.addClass('d-none');
				objects.$bookDatesButton.addClass('d-none');
				objects.$changeDatesWhenUnavailableButton.addClass('d-none');
			}
		};

		this.setDates = (selectedDates, picker) => {

			this.startDate = selectedDates[0];
			this.endDate = selectedDates[1];

			// picker.setDate(null);
			picker.selectedDates = [this.startDate, this.endDate];
			picker.redraw();

			const mStartDate = moment(this.startDate);
			const mEndDate = moment(this.endDate);

			objects.$displayDateRange.find('span').text(`${mStartDate.format('MMM DD, YYYY')} - ${mEndDate.format('MMM DD, YYYY')}`);
			objects.$displayDateRange.show();

			this.setHelperText();
			this.setBookUrl();
			this.showHideMsg();
		};

		this.setPickerDates = (selectedDates, picker) => {
			// const otherPicker = this.getOtherPicker(picker);

			// If we have a range already or we don't have a start date, the user is selecting a new start date.
			if ((this.startDate && this.endDate) || !this.startDate) {
				this.startDate = selectedDates[0];
				this.endDate = null;

				// picker.setDate(null);
				picker.selectedDates = [this.startDate];
				picker.redraw();

				// otherPicker.setDate(null);
				// otherPicker.selectedDates = [this.startDate];
				// otherPicker.redraw();
				objects.$displayDateRange.find('span').text('');
				objects.$displayDateRange.hide();
			} else {

				// We're picking the second date which may be before the first selected date if range was selected backwards.
				if (moment(selectedDates[0]).isSame(moment(this.startDate))) {
					this.endDate = selectedDates[1];
				} else {
					this.endDate = selectedDates[0];
				}

				// If they picked the end date first, untangle the dates.
				if (this.startDate && this.endDate && (this.endDate < this.startDate)) {
					const _swap = moment(this.endDate).toISOString();
					this.endDate = this.startDate;
					this.startDate = moment(_swap).toDate();
				}

				const mStartDate = moment(this.startDate);
				const mEndDate = moment(this.endDate);

				if (mStartDate.isSame(mEndDate)) {
					this.endDate = mEndDate.add(1, 'day').toDate();
				}
				picker.selectedDates = [this.startDate, this.endDate];
				picker.redraw();

				objects.$displayDateRange.find('span').text(`${mStartDate.format('MMM DD, YYYY')} - ${mEndDate.format('MMM DD, YYYY')}`);
				objects.$displayDateRange.show();
				// otherPicker.selectedDates = [this.startDate, this.endDate];
				// otherPicker.redraw();
			}
			this.setHelperText();
			this.setBookUrl();
			this.showHideMsg();
		};

		this.showHideMsg = () => {
			// Check if messaging needs to be displayed
			if (moment(this.endDate).isValid()) {
				if (objects.$minStayMsg.hasClass('d-none') && objects.$daysInAdvMsg.hasClass('d-none') && objects.$closedMsg.hasClass('d-none')) {
					objects.$unavailableDialog.hide();
				} else {
					objects.$unavailableDialog.show();
					this.trapFocus(objects.$unavailableDialog);
					setTimeout(() => {
						objects.$unavailableClose.focus();
					}, 500);
				}
			}
		};

		this.trapFocus = (element) => {
			if (element[0] && element[0].length) {
				let focusableEls = element[0].querySelectorAll('input:not([tabindex="-1"]), select:not([tabindex="-1"]), button:not([tabindex="-1"]), a:not([tabindex="-1"]), div[tabindex="0"]');

				let lastFocusableEl = focusableEls[focusableEls.length - 1];

				element[0].addEventListener('keydown', (e) => {
					let isTabPressed = (e.key === 'Tab' || e.keyCode === 9);

					if (!isTabPressed) {
						return;
					}

					if (e.shiftKey) /* shift + tab */ {
						if (document.activeElement === element[0]) {
							lastFocusableEl.focus();
							e.preventDefault();
						}
					} else /* tab */ {
						if (document.activeElement === lastFocusableEl) {
							objects.$unavailableDialog.focus();
							e.preventDefault();
						}
					}
				});
			}
		};

		this.sendEvent = () => {
			// custom event triggering
			const ev = new CustomEvent('reservationdatesselected', {
				'detail': {
					source: $el,
					startDate: this.startDate,
					endDate: this.endDate
				}
			});
			$(document)[0].dispatchEvent(ev);
			this.setBookUrl();
		};

		this.onPickerUpdate = (selectedDates, inputValue, picker) => {

			if (!resize) {

				if (selectedDates[1]) {
					// objects.$saveCalendarBtn.attr('disabled', false);
					objects.$saveCalendarBtn.focus();
				} else {
					// objects.$saveCalendarBtn.attr('disabled', true);
				}

				selectedDatesGlobal = selectedDates;
				pickerGlobal = picker;
				this.setPickerDates(selectedDatesGlobal, pickerGlobal);

				//		Commeted out because we want this.sendEvent() to fire when a user hits the save button
				//
				// 	let sendEv = true;
				// 	if (!this.startDate || !this.endDate) {
				// 		sendEv = false;
				// 	} else {
				// 		let dateCompare = moment(this.startDate).format('YYYY-MM-DD').localeCompare(moment(this.endDate).format('YYYY-MM-DD'));
				// 		if (dateCompare >= 0) {
				// 			sendEv = false;
				// 		}
				// 	}
				// 	if (sendEv) {
				// 		this.sendEvent();
				// 	}
			}
			resize = false;

			// $('.flatpickr-month + .flatpickr-month .numInput.cur-year').attr('tabindex', -1);
		};

		this.saveDates = () => {

			console.log(selectedDatesGlobal);

			if(selectedDatesGlobal.length === 2) {
				initialSelectedDatesGlobal = selectedDatesGlobal;
			} else {
				selectedDatesGlobal = initialSelectedDatesGlobal;
				this.setDates(selectedDatesGlobal, pickerGlobal);
			}

			this.sendEvent();

			$('#collapse-date-picker').modal('hide');
			// objects.$saveCalendarBtn.attr('disabled', true);
		};

		this.cancelSaveCalendar = () => {

			selectedDatesGlobal = initialSelectedDatesGlobal;
			this.setDates(selectedDatesGlobal, pickerGlobal);

			this.sendEvent();
			
			$('#collapse-date-picker').modal('hide');
			// objects.$saveCalendarBtn.attr('disabled', true);
		};

		this.handleCalendarDayCreate = (dObj, dStr, fp, dayElem) => {
			let currentDate = moment();
			let ariaLabel = dayElem.getAttribute('aria-label');

			dayElem.setAttribute('data-date', moment(dayElem.dateObj).format('YYYY-MM-DD'));

			if (params.propertyId && objects.rateData[params.propertyId]) {
				let propertyRateData = objects.rateData[params.propertyId];
				let dayDate = moment(dayElem.dateObj).format('YYYY-MM-DD');
				let dayRateData = propertyRateData[dayDate];

				if (params.alwaysAvailable) {
					dayElem.setAttribute('data-minstay', -1);
					dayElem.setAttribute('aria-label', ariaLabel + ' Continue to see rates.');
					dayElem.setAttribute('tabindex', 0);
					dayElem.setAttribute('role', 'button');
				} else if (dayRateData) {
					let rate = dayRateData.rate;
					if(rate && (!isNaN(rate))) {
						let ariaLabel = dayElem.getAttribute('aria-label');
						// let thisRate = `${params.currency}${Math.round(rate)}`;

						if(dayRateData.availability === 'MinStay') {
							dayElem.className += ' min-stay';
							dayElem.setAttribute('data-minstay', dayRateData.avail_qty);
							dayElem.setAttribute('aria-label', ariaLabel + ' This day requires a minimum stay of ' + dayRateData.avail_qty + ' days.' /* This day has a rate of ' + thisRate */ );
							dayElem.setAttribute('tabindex', 0);
							dayElem.setAttribute('role', 'button');

						} else {
							dayElem.setAttribute('data-minstay', -1);
							// dayElem.setAttribute('aria-label', ariaLabel + ' This day has a rate of ' + thisRate);
							dayElem.setAttribute('tabindex', 0);
							dayElem.setAttribute('role', 'button');
						}
						// dayElem.innerHTML += `<div class="rate">${params.currency}${Math.round(rate)}</div>`;
					} else {
						dayElem.className += ' unavailable';
						dayElem.setAttribute('data-days-in-advance', dayRateData.avail_qty);
						dayElem.setAttribute('role', 'button');
					}
				} else {
					dayElem.className += ' unavailable';
					dayElem.setAttribute('aria-label', ariaLabel + ' is unavailable');
				}
			}

			$('.flatpickr-calendar').removeAttr('tabindex');
			$('.flatpickr-days').removeAttr('tabindex');

			// flatpickr ADA fixes
			$(dayElem).attr('role', 'button');

			if (moment(dayElem.dateObj).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
				$(dayElem).attr('aria-current', 'date');
			}

			if ($(dayElem).hasClass('prevMonthDay') || $(dayElem).hasClass('nextMonthDay')) {
				$(dayElem).addClass('disabled');
				$(dayElem).attr('aria-hidden', 'true');
				$(dayElem).removeAttr('aria-label');
			}

			if ($(dayElem).hasClass('startRange') && $(dayElem).hasClass('endRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as date');
			} else if ($(dayElem).hasClass('startRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as check in date');
			} else if ($(dayElem).hasClass('endRange')) {
				dayElem.setAttribute('aria-label', ariaLabel + ' selected as check out date');
			}
		};

		this.dateRangeToArray = (startDate, endDate, format = 'YYYY-MM-DD') => {
			let dateArray = [];
			let iDate = startDate.startOf('day');
			while (iDate <= endDate.startOf('day')) {
				dateArray.push(iDate.format(format));
				iDate = iDate.add(1, 'days');
			}
			return dateArray;
		};

		/**
		 * getRateData()
		 * Gets Property rate data for provided date range and property id
		 * @param {date} startDate
		 * @param {date} endDate
		 */
		this.getRateData = (startDate, endDate) => {
			objects.pendingRateRequest = true;
			let requiredKeys = this.dateRangeToArray(moment(startDate), moment(endDate));

			$('.flatpickr-calendar').attr('aria-busy', true);

			// if (params.propertyId) {
			if (objects.rateData[params.propertyId] && requiredKeys.every(key => Object.hasOwnProperty.call(objects.rateData[params.propertyId], key))) {
				objects.pendingRateRequest = false;
				objects.objLoader.stopLoader();
			} else {
				let $bundle = $('#rateapi-bundle');
				let promoCode = '';
				if($bundle && $bundle.data('promo-code')) {
					promoCode = $bundle.data('promo-code').toLowerCase();
				}
				$( 'a[data-action=\'book\']').each(function( ) {
					// Get promo code from book now buttons
					if($(this).data('promo-code')) {
						if (promoCode.length > 0) {
							promoCode += ',';
						}
						promoCode += $(this).data('promo-code').toLowerCase();
					}
				});

				let client = params.rateUrl.replace(/{propertyId}/g, params.propertyId).replace(/{startDate}/g, moment(startDate).format('YYYY-MM-DD')).replace(/{endDate}/g, moment(endDate).format('YYYY-MM-DD')).replace(/{promoCode}/g, promoCode);

				return $.ajax({
					type: 'GET',
					url: client,
					dataType: 'json'
				})
					.done((res) => {
						let rates = res.data.rates;
						objects.rateData[params.propertyId] = Object.assign({}, objects.rateData[params.propertyId], rates);
					})
					.fail((err) => {
					//	console.log('Error getting rate data: ', err);
					})
					.always(() => {
						objects.pendingRateRequest = false;
						if (this.leftPicker) {
							this.leftPicker.redraw();

							this.setBookUrl();

							this.fixCalendarDayTabIndex();

							objects.objLoader.stopLoader();
							$('.flatpickr-calendar').attr('aria-busy', false);
							$('.flatpickr-month + .flatpickr-month .numInput.cur-year').attr('tabindex', -1);

							$modal.children().find('.dayContainer:first-of-type .flatpickr-day').not('.hidden').not('[disabled]')[0].focus();
						}
					});
			}
		};

		this.setBookUrl = () => {
			// Check MLOS requirements
			if(this.leftPicker) {
				let minstay = 0;
				let closed = false;
				let diaCount = 0;
				let selectedList = $(this.leftPicker.calendarContainer).find('.inRange:not(.prevMonthDay):not(.nextMonthDay), .startRange:not(.prevMonthDay):not(.nextMonthDay)');

				selectedList.each(function () {
					let myMin = this.getAttribute('data-minstay');
					if (myMin > minstay) {
						minstay = myMin;
					}
					if ($(this).hasClass('closed') || $(this).hasClass('unavailable')) {
						closed = true;
						let dia = this.getAttribute('data-days-in-advance');
						if (diaCount < dia) {
							diaCount = dia;
						}
					}
				});
				let minNights = 0;
				if (minstay > 0) {
					minNights = Number(minstay);
				}
				// console.log('SEL: '+selectedList.length);
				// console.log('MIN: '+minstay);
				// console.log('LT: '+(selectedList.length < minNights));
				if (params.alwaysAvailable) {
					objects.$buttonContainer.removeClass('d-none');
					objects.$minStayMsg.addClass('d-none');
					objects.$minStayHelpMsg.addClass('d-none');
					objects.$daysInAdvMsg.addClass('d-none');
					objects.$daysInAdvanceMsg.addClass('d-none');
					objects.$closedMsg.addClass('d-none');

					objects.$bookDatesButton.removeClass('d-none');
					if(objects.$bookDatesButton.length > 0) {
						objects.$changeDatesButton.addClass('d-none');
					}
					objects.$changeDatesWhenUnavailableButton.addClass('d-none');

					$(document).find('.js-booknow-' + params.propertyId).show();
				}
				if (selectedList.length < minNights) {
					// console.log('MIN STAY NOT MET');
					objects.$buttonContainer.addClass('d-none');
					objects.$minStayMsg.html(objects.$minStayMsg.attr('data-msg').replace('{0}', minstay));
					objects.$minStayMsg.removeClass('d-none');
					objects.$minStayHelpMsg.removeClass('d-none');
					objects.$daysInAdvanceMsg.addClass('d-none');
					objects.$daysInAdvMsg.addClass('d-none');
					objects.$closedMsg.addClass('d-none');

					$(document).find('.js-booknow-' + params.propertyId).hide();
				} else if (closed) {
					if (diaCount > 0) {
						// Days in Advance
						objects.$buttonContainer.addClass('d-none');
						objects.$minStayMsg.addClass('d-none');
						objects.$daysInAdvanceMsg.removeClass('d-none');
						objects.$daysInAdvMsg.removeClass('d-none');
						objects.$daysInAdvanceMsg.html(objects.$daysInAdvanceMsg.attr('data-msg').replace('{0}', diaCount));
						objects.$closedMsg.addClass('d-none');
					} else {
						// Closed
						objects.$buttonContainer.addClass('d-none');
						objects.$minStayMsg.addClass('d-none');
						objects.$minStayHelpMsg.addClass('d-none');
						objects.$daysInAdvanceMsg.addClass('d-none');
						objects.$daysInAdvMsg.addClass('d-none');
						objects.$closedMsg.removeClass('d-none');
					}
					$(document).find('.js-booknow-' + params.propertyId).hide();
				} else if (selectedList.length > 0) {
					objects.$buttonContainer.removeClass('d-none');
					objects.$minStayMsg.addClass('d-none');
					objects.$minStayHelpMsg.addClass('d-none');
					objects.$daysInAdvanceMsg.addClass('d-none');
					objects.$daysInAdvMsg.addClass('d-none');
					objects.$closedMsg.addClass('d-none');

					objects.$bookDatesButton.removeClass('d-none');
					if(objects.$bookDatesButton.length > 0) {
						objects.$changeDatesButton.addClass('d-none');
					}
					objects.$changeDatesWhenUnavailableButton.addClass('d-none');

					$(document).find('.js-booknow-' + params.propertyId).show();
				}
			}

			// Update Book Now Buttons
			$(document)[0].dispatchEvent(new CustomEvent('date-occupancy-update'));
		};

		this.defaultDates = [];
		this.startDate = null;
		this.endDate = null;

		this.populateCalendarDates = () => {
			let visibleMonth = moment([this.leftPicker.currentYear, this.leftPicker.currentMonth]);
			let start = moment(visibleMonth).subtract(1, 'months');
			let end = moment(visibleMonth).add(3, 'months').subtract(1, 'days');

			if($el.data('property-id')) {
				objects.objLoader.startLoader();
				this.getRateData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));

				if (!params.propertyId) {
					$('#collapse-date-picker .flatpickr-key').addClass('d-none');
				} else {
					$('#collapse-date-picker .flatpickr-key').removeClass('d-none');
				}
			}
			const mStartDate = moment(this.startDate);
			const mEndDate = moment(this.endDate);
			objects.$displayDateRange.find('span').text(`${mStartDate.format('MMM DD, YYYY')} - ${mEndDate.format('MMM DD, YYYY')}`);
			objects.$displayDateRange.show();
		};

		this.firstRun = () => {
			console.info('~~~ Calendar Flyout Module ~~~', this);

			// capture initial url for templating on date selection.
			$('[data-action="book"]').each((i, e) => {
				$(e).data('url', e.href);
			});

			objects.objLoader = new Loader($, objects.$loader);

			this.initCalendars();

			$(document).on('reservationdatesselected', (ev) => {
				let detail = ev.detail;
				if (detail.source !== $el) {
					this.startDate = detail.startDate;
					this.endDate = null;
					this.setPickerDates([detail.startDate, detail.endDate], this.leftPicker);
				}
			});

			objects.$saveCalendarBtn.on('click', this.saveDates);
			objects.$cancelSaveCalendar.on('click', this.cancelSaveCalendar);
			objects.$unavailableClose.on('click', this.handleUnavailableClose);

			$('#collapse-date-picker .flatpickr-key').addClass('d-none');

			objects.$toggleLink.on('click', () => {this.populateCalendarDates();});
			objects.$btnOpenCal.on('click', () => {this.populateCalendarDates();});

			// TODO: added until messaging display issues resolved
			objects.$closedMsg.addClass('d-none');
			objects.$minStayHelpMsg.addClass('d-none');
			objects.$daysInAdvMsg.addClass('d-none');
			objects.$unavailableDialog.hide();

			$modal.on('shown.bs.modal', () => {
				$('.flatpickr-month + .flatpickr-month .numInput.cur-year').attr('tabindex', -1);
				this.fixCalendarDayTabIndex(true);
			});

			$modal.on('hide.bs.modal', () => {
				objects.$enterDatesResult.focus();
			});

			// hide unused (shared) messaging elements
			$el.find('[data-unavailable="property"]').hide();
			$el.find('[data-unavailable="room"]').hide();
		};

		this.handleUnavailableClose = () => {
			objects.$unavailableDialog.hide();
			$modal.find('.flatpickr-day.selected').focus();
		};

		this.resize = () => {
			resize = true;
			const viewportSize = utils.getViewportSize();
			const isMobile = viewportSize !== 'md' && viewportSize !== 'lg' && viewportSize !== 'xl';
			let monthCount = isMobile ? 1 : 2;
			this.leftPicker.set('showMonths', monthCount);
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'CalendarFlyout';
	}

	init() {
		this.firstRun();
	}
}

export default CalendarFlyout;
