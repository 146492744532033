class BiographyGroup {
	/**
	 * BiographyGroup Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Container element for collapsible buttons
	 * @param {object} Utils - Utility class, not used but included for structure consistency
	 */
	constructor($, $el, Utils) {
		this.$el = $el;
		this.$ = $;
		this.Utils = Utils;

		const objects = {
			$collapsibleButtons: $el.find('.collapsible-btn'),
		};

		this.firstRun = () => {
			console.log('~~~ BiographyGroup Module ~~~');

			objects.$collapsibleButtons.each((index, button) => {
				const collapsible = document.querySelector(button.getAttribute('data-target'));
				const content = collapsible.querySelector('p');

				const collapsedText = content.textContent.trim().slice(0, 200) + '...';

				const expandedText = content.textContent.trim();

				collapsible.setAttribute('aria-expanded', 'false');

				button.setAttribute('aria-expanded', 'false');

				content.textContent = collapsedText;

				button.addEventListener('click', function () {
					const isExpanded = collapsible.getAttribute('aria-expanded') === 'true';
					collapsible.setAttribute('aria-expanded', !isExpanded);

					button.setAttribute('aria-expanded', !isExpanded);

					content.textContent = isExpanded ? collapsedText : expandedText;

					if (!isExpanded) {
						collapsible.setAttribute('aria-live', 'polite');
						collapsible.focus();
					} else {
						button.focus();
					}
				});
			});
		};
	}


	name() {
		return 'BiographyGroup';
	}

	init() {
		this.firstRun();
	}
}

export default BiographyGroup;

