class PasswordReset {

	/**
	 * PasswordReset Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - PasswordReset instance
	 */

	constructor($, $el) {

		const objects = {
			$alertError: $el.find('.js-alert-error'),
			$alertNetworkError: $el.find('.js-network-error'),
			$alertSuccess: $el.find('.js-alert-success'),
			$form: $el.find('form')
		};

		this.firstRun = () => {
			console.info('~~~ PasswordReset Module ~~~');
			objects.$form.submit(this.handleForgot);
		};

		this.handleForgot = (event) => {
			event.preventDefault();
			$el.find('.form-group.has-danger').removeClass('has-danger');
			$el.find('.form-control-feedback').addClass('d-none');
			// POST to forgotPassword action.
			const $form = $(event.target);
			$form.find('.btn').addClass('disabled');
			const url = $form.attr('action');
			const data = $form.serialize();
			objects.$alertSuccess.addClass('d-none');
			objects.$alertError.addClass('d-none');
			objects.$alertNetworkError.addClass('d-none');
			$.ajax({
				cache: false,
				type: 'POST',
				url: url,
				data: data,
				dataType: 'json'
			})
				.done(this.forgotSuccess)
				.fail(this.forgotFail);
		};

		this.forgotSuccess = (data, textStatus, jqXHR) => {
			if (data['result_code'] === 200) {
				console.info('Forgot password succeeded.');
				objects.$alertSuccess.removeClass('d-none');
			} else {
				this.forgotFail(jqXHR, textStatus, data);
			}
		};

		this.forgotFail = (jqXHR, textStatus, errorThrown) => {
			console.error(`Forgot password failed: ${textStatus} : ${errorThrown}`);
			if(textStatus === 'success') {
				const resultCode = errorThrown['result_code'];
				if (resultCode) {
					switch (resultCode) {
						// TODO Other errors? Probably should not give hints.
						default:
							// Other error show generic message.
							objects.$alertError.removeClass('d-none');//.html(errorThrown['result_text'].replace('Occured', 'Occurred'));
							objects.$alertSuccess.addClass('d-none');
							objects.$alertNetworkError.addClass('d-none');
					}
				}
			} else {
				objects.$alertError.addClass('d-none');
				objects.$alertSuccess.addClass('d-none');
				objects.$alertNetworkError.removeClass('d-none');
			}

			objects.$form.find('.btn').removeClass('disabled');
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'PasswordReset';
	}

	init() {
		this.firstRun();
	}

}

export default PasswordReset;
