import objectFitImages from 'object-fit-images';
import RateApi from './utilities/RateApi';
// import Plyr from 'plyr';

class Main {

	constructor($, imports, Utils) {
		// this._imports = imports;

		// /* put all available modules in an object */
		// let _objects = this._imports.reduce( (obj, prop) => {
		// 	console.log(`import: ${prop.name}`);
		// 	obj[prop.name] = prop;
		// 	return obj;
		// }, Object.create(null));

		let _objects = imports;

		/**
		 * injectIcons()
		 * Loads icon bundle file via http request for page injection
		 */
		this.injectIcons = () => {
			const xhr = new XMLHttpRequest();
			let $bundle = $('#icon-bundle');
			let $path = $bundle.data('path') || '../img';
			xhr.onload = (res) => {
				$bundle.html(res.currentTarget.responseText);
			};
			xhr.open('get', `${$path}/icons.svg`, true);
			//xhr.setRequestHeader('Cache-Control', 'max-age=1000');
			xhr.send();
		};

		/**
		 * instantiateModules()
		 * USAGE: Main.instantiateModules( $(' element to search for modules to instantiate ') )
		 */
		this.instantiateModules = (element = $('body')) => {

			let _utils = new Utils();
			//TODO initialize rateApi module
			let _rateApi = new RateApi();

			element.find('[data-module]').each((i,v) => {
				let name = $(v).data('module');

				if (_objects[name]) {
					const tStart = performance.now();
					let module = new _objects[name]($, $(v), _utils, _rateApi);

					if ($(v).data('init') !== false) {
						module.init();
					}
					_utils.listeners(module);
					const tEnd = performance.now();
					console.debug(`Init took ${tEnd - tStart} ms.`);
				}
			});

			_utils.initViewMore();
			_utils.initScrollTo();
			_utils.initSetSticky();
			_utils.moveModals();
			_utils.trimBadges();

			$(window).ready(function() {
				_utils.trimBadges();
			});

			$(document.body).on('touchmove', function() {
				_utils.setSticky();
			}); // for mobile
		};

		this.detectTabbing = () => {
			function handleFirstTab(e) {
				if (e.keyCode === 9) {
					document.body.classList.add('user-is-tabbing');
					window.removeEventListener('keydown', handleFirstTab);
				}
			}

			window.addEventListener('keydown', handleFirstTab);
		};

	}

	init() {
		this.instantiateModules();
		this.injectIcons();
		objectFitImages();
		this.detectTabbing();
	}
}

export default Main;
