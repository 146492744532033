class Loader {

	/**
	 * Loader Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Loader instance
	 */

	constructor($, $el) {

		const objects = {
			$stop1: $el.find('.stop1'),
			$stop2: $el.find('.stop2')
		};

		let $value = 0;
		let $IntID;

		this.increment = () => {
			objects.$stop1.attr('offset', $value);
			objects.$stop2.attr('offset', $value);
			$value = $value + 0.01;

			if($value <= 1) {
				$value = $value + 0.01;
			} else {
				$value = 0;
				this.increment();
			}
		};

		this.setInterval = () => {
			return setInterval(this.increment, 25);
		};

		this.stopLoader = () => {
			$el.hide();
			clearInterval($IntID);
			$value = 0;
		};

		this.startLoader = () => {
			this.stopLoader();
			$el.show();
			$IntID = this.setInterval();
		};

		this.firstRun = () => {
			console.info('~~~ Loader Module ~~~');
		};
	}

	name() {
		return 'Loader';
	}

	init() {
		this.firstRun();
	}

}

export default Loader;
