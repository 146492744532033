class HotelSelector {

	/**
	 * HotelSelector Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - HotelSelector instance
	 */

	constructor($, $el) {

		const objects = {
			$dropdownPanel: $el.find('.dropdown-panel'),
			$dropdownToggle: $el.find('.dropdown-toggle'),
			$dropdownValue: $el.find('.dropdown-toggle [data-value]'),
			$dropdownOption: $el.find('.dropdown-panel-group-options ul li a'),
			$firstOption: $el.find('.dropdown-panel li a'),
			$form: $el.find('form'),
			$inputDestination: $el.find('#destination'),
			$submit: $el.find('[type="submit"]')
		};

		this.handleOptionClick = (e) => {
			e.preventDefault();
			// Change dropdown value, enable submit button and collapse panel on option click
			const $destination = $(e.currentTarget).data('destination');
			const $itemValue = $(e.currentTarget).data('text');
			objects.$dropdownValue.text($itemValue);
			objects.$inputDestination.val($destination);
			objects.$submit.attr('aria-disabled', false).attr('disabled', false).attr('aria-label', `Go to Browse hotels & condo resorts in ${$itemValue}`);
			objects.$dropdownPanel.collapse('hide');

			$(e.currentTarget).attr('aria-selected', 'true').attr('aria-current', 'true');
		};

		this.resize = () => { };

		this.firstRun = () => {
			console.info('~~~ Hotel Selector Module ~~~');

			objects.$dropdownOption.on('click', this.handleOptionClick);
			
			objects.$dropdownOption.attr('aria-selected', 'false').attr('aria-current', 'false');

			objects.$dropdownToggle.on('click', function () {
				setTimeout(function () {
					objects.$firstOption.first().focus();
				}, 300);
			});

			// Hide dropdown panel on click outside panel
			$(document).on('click', (e) => {
				if (!$(e.target).closest(objects.$dropdownPanel).length) {
					if (objects.$dropdownPanel.hasClass('show')) {
						objects.$dropdownPanel.collapse('hide');
					}
				}
			});

		};
	}

	name() {
		return 'HotelSelector';
	}

	init() {
		this.firstRun();
	}

}

export default HotelSelector;
