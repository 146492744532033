class Tablist {

	/**
	 * TabList Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - TabList instance
	 */

	constructor($, $el) {

		const objects = {
			$tpanel: $el,
			$tabs: $el.find('.tab'),
			$panels: $el.find('.tab-pane'),

			keys: {
				left: 37,
				up: 38,
				right: 39,
				down: 40,
				enter: 13
			}
		};

		this.switchTabs = ($newTab) => {

			objects.$tabs.removeClass('is-active');
			$newTab.addClass('is-active');

			let $newTabLink = $newTab.find('a'),
				$newTabPane = $newTabLink.attr('aria-controls');

			objects.$tabs.find('a').attr('tabindex', '-1').attr('aria-expanded', 'false');

			$newTabLink.attr('aria-expanded', 'true');

			objects.$panels.removeClass('active in').attr('aria-hidden', 'true');
			$('#' + $newTabPane).addClass('active in').attr('aria-hidden', false).attr('tabindex', 0);

			$newTabLink.attr('tabindex', '0');
			$newTabLink.focus();
		};


		this.handleTabKeyDown = ($tab, e) => {
			let $newTab, tabIndex;
			let $tabParent = $tab.parent();

			switch (e.keyCode) {
				case objects.keys.left:
				case objects.keys.up: {

					tabIndex = objects.$tabs.index($tabParent);

					if (tabIndex === 0) {
						$newTab = objects.$tabs.last();
					}
					else {
						$newTab = objects.$tabs.eq(tabIndex - 1);
					}

					this.switchTabs($newTab);

					e.preventDefault();
					return false;
				}
				case objects.keys.right:
				case objects.keys.down: {

					tabIndex = objects.$tabs.index($tabParent);

					if (tabIndex === objects.$tabs.length-1) {
						$newTab = objects.$tabs.first();
					}
					else {
						$newTab = objects.$tabs.eq(tabIndex + 1);
					}

					this.switchTabs($newTab);

					e.preventDefault();
					return false;
				}
				case objects.keys.enter: {
					this.handleTabClick;
				}
			}
		};


		this.handleTabClick = (e) => {
			let newTab = $(e.target).parent();

			this.switchTabs(newTab);
		};


		this.init = () => {
			let $tab;

			// objects.$panels.attr('aria-hidden', 'true');

			$tab = objects.$tabs.filter('.is-active');

			if ($tab === undefined) {
				$tab = objects.$tabs.first();
				$tab.addClass('active');
			}

			objects.$tpanel
				.find('#' + $tab.find('a').attr('aria-controls'))
				.addClass('active in').attr('aria-hidden', 'false');

			objects.$tabs.on('keydown', (e) => {
				this.handleTabKeyDown($(e.target), e);
			});

			objects.$tabs.on('click', this.handleTabClick);
		};


		this.firstRun = () => {
			this.init();
		};
	}

	name() {
		return 'Tablist';
	}

	init() {
		this.firstRun();
	}
}

export default Tablist;
