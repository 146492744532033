import Loader from '../modules/Loader';
import CustomEvent from 'custom-event';

class FavoriteButton {
	/**
	 * FavoriteButton Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - FavoriteButton instance
	 */

	constructor($, $el) {

		const objects = {
			$loader: $el.find('[data-loader]'),
			objLoader: null
		};

		const params = {
			identifier: $el.data('identifier'),
			isFavorite: false
		};

		this.resize = () => {
		};


		this.loadData = () => {

			console.log('Favorites Load Data');

			// // Check if other instance has already loaded stay data.
			if (FavoriteButton._loading) { return; }
			FavoriteButton._loading = true;

			const url = $el.data('action');

			objects.objLoader.startLoader();
			$.getJSON({url: url}, {_: new Date().getTime()})
				.done((data) => {
					//console.log('Favs: '+JSON.stringify(data));
					let event = new CustomEvent('favoritedataloaded', { 'detail': { data: data }} );
					$(document)[0].dispatchEvent(event);
				})
				.fail((jqXHR, textStatus) => {
					//console.log(textStatus);
				})
				.always(() => {
					objects.objLoader.stopLoader();
					FavoriteButton._loading = false;
				});
		};

		this.onFavoriteDataLoaded = (event) => {
			const data = event.detail.data;
			const user = data['user'];

			// console.log('Favorite Loaded '+JSON.stringify(data));
			// console.log('User: '+user);

			if(!$el.attr('data-target')) {
				$el.attr('data-toggle', 'modal');
				$el.attr('data-target', '#travel-log-modal');
			}
			if(user === 'guest' || user === undefined) {
				$el.attr('data-target', '#sign-in-modal');
			} else {
				let includedIn = '';

				// Scan Favorites
				let favoriteObj = data['favorites'];
				const favoriteArray = favoriteObj['favorites'];
				params.isFavorite = false;
				for (let favorite of favoriteArray) {
					if (favorite === params.identifier) {
						// console.log("FAV FOUND: "+favorite+" is "+params.identifier);
						params.isFavorite = true;
						includedIn += favoriteObj['id'];
					}
				}

				// Scan Travel Logs
				for (let travelLog of data['travelLogs']) {
					for (let tlFavorite of travelLog['favorites']) {
						if (tlFavorite === params.identifier) {
							// console.log("TL FAV FOUND: "+tlFavorite+" is "+params.identifier);
							params.isFavorite = true;
							includedIn += (includedIn.length > 0 ? ',' : '') + travelLog['id'];
						}
					}
				}

				if (params.isFavorite) {
					$el.children('.heart-filled').removeClass('d-none');
					$el.children('.heart-open').addClass('d-none');
					// console.log("Included In: "+includedIn);
					$el.attr('data-included-in', includedIn);
				} else {
					$el.children('.heart-filled').addClass('d-none');
					$el.children('.heart-open').removeClass('d-none');
				}

				$el.on('click', this.onClick);
			}
		};

		this.onTravelLogClosed = (event) => {
			const data = event.detail.data;
			if('#'+data.travellog === $el.attr('data-target')) {
				if ($el.attr('data-included-in')) {
					$el.children('.heart-filled').removeClass('d-none');
					$el.children('.heart-open').addClass('d-none');
				} else {
					$el.children('.heart-filled').addClass('d-none');
					$el.children('.heart-open').removeClass('d-none');
				}
			}
		};

		this.firstRun = () => {
			console.info('~~~ FavoriteButton Module ~~~');

			// Load indicator.
			objects.objLoader = new Loader($, objects.$loader);

			$(document).on('favoritedataloaded', this.onFavoriteDataLoaded);
			$(document).on('travellogclosed', this.onTravelLogClosed);

			this.loadData();
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'FavoriteButton';
	}

	init() {
		this.firstRun();
	}

	onClick() {
		let identifier = $(this).attr('data-identifier');
		// console.log('Click ID: '+identifier);

		// console.log("Form: %o", $('#travel-log-modal'));
		// console.log("Inputs: %o", $('#travel-log-modal').find('.form-check-input'));
		// $('#travel-log-modal').find('.form-check-input').attr('data-page-id', identifier);

		let includedIn = [];
		let includedString = $(this).attr('data-included-in');
		// console.log("Included String: "+includedString);
		if(includedString) {
			includedIn = $(this).attr('data-included-in').split(',');
			// console.log("Included In: "+includedIn);
		}

		let target = $(this).attr('data-target');
		$(target).find('.form-check-input').each(function() {
			// Set new page for dialog
			$(this).attr('data-page-id', identifier);

			// Check if travel log should be selected
			let travelLogId = $(this).attr('data-travel-log-id');
			// console.log('Finding '+travelLogId+" at "+includedIn.indexOf(travelLogId));
			$(this).prop('checked', includedIn.indexOf(travelLogId) >= 0);
		});

		$(this).children('.heart-filled').removeClass('d-none');
		$(this).children('.heart-open').addClass('d-none');
	}

}

export default FavoriteButton;
