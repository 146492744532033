class OfferList {

	/**
	 * OfferList Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - OfferList instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {

		const objects = {
			$carousel: $el.find('.carousel'),
			$offerListing: $el.find('.offer-listing')
		};

		this.resize = () => {
			this.filterCarousel();
		};

		this.filterCarousel = (e) => {
			if(objects.$carousel.hasClass('slick-initialized')) {
				objects.$carousel.slick('slickUnfilter');

				if (utils.getViewportSize() ==='xs' || utils.getViewportSize() ==='sm') {
					objects.$carousel.slick('slickFilter', '.offer-listing');
				}
			}
		};

		this.firstRun = () => {
			console.info('~~~ OfferList Module ~~~');
			
			$(window).on('load', () => {
				this.filterCarousel();

				$el.find('figure').attr('tabindex', -1);
				objects.$offerListing.removeAttr('tabindex');
			});

			objects.$offerListing.removeAttr('tabindex');
			for (let offer of objects.$offerListing) {
				offer.addEventListener('click', (e) => {

					window.dataLayer.push({
						'event': 'offerClicked',
						'data-gtm-module': offer.getAttribute('data-gtm-module'),
						'data-gtm-view': offer.getAttribute('data-gtm-view')
					});
				});
			}
		};
	}

	name() {
		return 'OfferList';
	}

	init() {
		this.firstRun();
	}

}

export default OfferList;

