import moment from 'moment';

class OfferPropertySearch {

	/**
	 * OfferPropertySearch Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - OfferPropertySearch instance
	 * @param {object} utils - Instance of Utils
	 * @param {object} rateApi - Instance of rateApi
	 */

	constructor($, $el, utils, rateApi) {

		//let qs = new URLSearchParams(window.location.search);

		const params = {
			destinationUrl: $el.data('destination-url'),
			propertyUrl: $el.data('property-url'),
			srcmod: $el.data('srcmod')
		};

		const objects = {
			$filters: $el.find('.filters'),
			$searchHotels: $el.find('.js-input-search-hotels'),
			$selectDestinations: $el.find('.js-select-destinations-button'),
			$selectSort: $el.find('.js-select-sort'),
			$selectSortDropdown: $el.find('.ps-select-sort-dropdown'),
			$selectRooms: $el.find('.js-select-rooms-button'),
			$selectAmenities: $el.find('.js-select-amenities-button'),
			$amenitiesCard: $el.find('.js-amenities-card'),
			$destinationsCard: $el.find('.js-destinations-card'),
			$roomsCard: $el.find('.js-destinations-card'),
			$selectDates: $el.find('.enter-dates-result'),
			$optionsAmenitiesSubmit: $el.find('[data-action="submit-amenities"]'),
			$optionsAmenitiesClear: $el.find('[data-action="clear"]'),
			$optionsDestinationsSubmit: $el.find('[data-action="submit-destinations"]'),
			$optionsDestinationsClear: $el.find('[data-action="clear-destinations"]'),
			$optionsRoomsSubmit: $el.find('[data-action="submit-rooms"]'),
			$optionsRoomsClear: $el.find('[data-action="clear-rooms"]'),
			$propertyContainer: $el.find('.js-property-load-container'),
			$loadProperties: $el.find('.js-load-properties'),
			$ariaAssistant: $el.find('[data-aria-assistant]'),
			$amenitiesCountBadge: $el.find('[data-filter="amenities"]'),
			$destinationsCountBadge: $el.find('[data-filter="destinations"]'),
			$roomsCountBadge: $el.find('[data-filter="rooms"]'),
			hotels: undefined
		};

		this.firstRun = () => {
			console.info('~~~ OfferPropertySearch Module ~~~');

			let searchTermParam = utils.getUrlParameter('searchTerm');
			let destinationParam = utils.getUrlParameter('destination');
			let regionParam = utils.getUrlParameter('region');
			let sortByParam = utils.getUrlParameter('sortBy');
			objects.$selectSortValue = 'priceA';

			objects.$optionsAmenitiesSubmit.on('click', this.handleAmenitiesChange);
			objects.$optionsAmenitiesClear.on('click', this.handleAmenitiesClear);

			objects.$optionsDestinationsSubmit.on('click', this.handleDestinationsChange);
			objects.$optionsDestinationsClear.on('click', this.handleDestinationsClear);

			objects.$optionsRoomsSubmit.on('click', this.handleRoomsChange);
			objects.$optionsRoomsClear.on('click', this.handleRoomsClear);

			objects.$selectSortDropdown.on('change', this.handleSortSelect);

			objects.$searchHotels.val(searchTermParam ? searchTermParam : '');

			objects.$searchHotels.on('keydown', function(e) {
				if(e.keyCode == 13){
					$(this).blur();
				}
			});

			let searchTerms = searchTermParam ? searchTermParam.replace(' ', '+') : '';
			let destination = destinationParam ? destinationParam.replace(' ', '+') : '';
			let region = regionParam ? regionParam.replace(' ', '+') : '';
			let sortBy = sortByParam ? sortByParam : 'priceA';

			inactivateAllFields();

			loadDestinations(searchTerms, sortBy, destination, region);

			objects.$filters.find('.collapse').on('show.bs.collapse', (e) => {
				objects.$filters.find('.collapse').collapse('hide');
				$el.find('[data-template-marker-click]').hide();
			});

			const startDateMoment = moment(localStorage.getItem('startDate'));
			const endDateMoment = moment(localStorage.getItem('endDate'));
			let startDate;
			let endDate;
			if (startDateMoment.isValid() && endDateMoment.isValid()) {
				startDate = startDateMoment.format('YYYY-MM-DD');
				endDate = endDateMoment.format('YYYY-MM-DD');
			}
			console.log('Start Date: '+startDate);
			console.log('End Date: '+endDate);

			$(document).on('room-rates-loaded', this.handleRateLoad);
			rateApi.requestData(startDate, endDate);

			// set amenity count to 0
			objects.$amenitiesCountBadge.find('[data-filter-count]').text(0);
			this.toggleDisplayValues(objects.$amenitiesCountBadge, false);

			// set destination count to 0
			objects.$destinationsCountBadge.find('[data-filter-count]').text(0);
			this.toggleDisplayValues(objects.$destinationsCountBadge, false);

			// set rooms count to 0
			objects.$roomsCountBadge.find('[data-filter-count]').text(0);
			this.toggleDisplayValues(objects.$roomsCountBadge, false);			

			$(document).on('focus', '*' ,function() {
				if ($(this).parents('#filter-rooms').length > 0 ||
						$(this).parents('#filter-amenities').length > 0 ||
						$(this).parents('#filter-destinations').length > 0) {
					return false;
				} else {
					objects.$filters.find('.collapse').collapse('hide');
				}
			});
		};

		function inactivateAllFields(){
			objects.$searchHotels.attr({
				'aria-disabled': true,
				disabled: true
			});
			objects.$selectDates.attr({
				'aria-disabled': true,
				disabled: true
			});
			objects.$selectRooms.attr({
				'aria-disabled': true,
				disabled: true
			});
			objects.$selectAmenities.attr({
				'aria-disabled': true,
				disabled: true
			});
			objects.$selectDestinations.attr({
				'aria-disabled': true,
				disabled: true
			});
			objects.$selectSort.attr({
				'aria-disabled': true,
				disabled: true
			});
			objects.$selectSortDropdown.attr({
				'aria-disabled': true,
				disabled: true
			});
		}

		function activateAllFields(){
			objects.$searchHotels.attr({
				'aria-disabled': false,
				disabled: false
			});
			objects.$selectDates.attr({
				'aria-disabled': false,
				disabled: false
			});
			objects.$selectRooms.attr({
				'aria-disabled': false,
				disabled: false
			});
			objects.$selectAmenities.attr({
				'aria-disabled': false,
				disabled: false
			});
			objects.$selectDestinations.attr({
				'aria-disabled': false,
				disabled: false
			});
			objects.$selectSort.attr({
				'aria-disabled': false,
				disabled: false
			});
			objects.$selectSortDropdown.attr({
				'aria-disabled': false,
				disabled: false
			});
		}

		this.toggleDisplayValues = ($filter, applied) => {
			//console.log('OPS toggleDisplayValues  applied: ', applied);
			if (applied) {
				$filter.find('[data-view="applied"]').show();
				$filter.find('[data-view="applied"]').attr('aria-hidden', false);
				$filter.find('[data-view="unapplied"]').hide();
				$filter.find('[data-view="unapplied"]').attr('aria-hidden', true);
			} else {
				$filter.find('[data-view="unapplied"]').show();
				$filter.find('[data-view="unapplied"]').attr('aria-hidden', false);
				$filter.find('[data-view="applied"]').hide();
				$filter.find('[data-view="applied"]').attr('aria-hidden', true);
			}
		};

		function loadProperties(searchTerms, sortBy, destination) {
			//console.log('OPS = Load Properties');
			let searchUrl = params.propertyUrl;
			objects.$propertyContainer.load(searchUrl+'?srcmod='+params.srcmod, function() {
				updateFilter();
			});
		}

		function loadDestinations(searchTerms, sortBy, destination, region) {
			//console.log('OPS = Load Destinations');
			if(destination || region) {
				let destinationOptions = objects.$selectDestinations.find('value="'+destination+'"');
				

				let regions = $el.find('.property-grid-item[data-region="'+region+'"]');
				if(destinationOptions.length > 0) {
					objects.$selectDestinations.val(destination).prop('checked', true);
					updateFilter(regions.length > 0 ? region : null);
				}
			}
		}

		function updateFilter (region) {
			//console.log('OPS = Update Filter region='+region);
			let searchTerms = objects.$searchHotels.val();
			// let destination = objects.$selectDestination.val();
			let bedroomSelection = objects.$selectRooms.val();
			let selectedAmenities = [];
			let selectedDestinations = [];
			let selectedRooms = [];

			$el.find('[name="ps-amenities"]:checked').each((i, a)=> {
				selectedAmenities.push(a.value.toLowerCase());
			});

			$el.find('[name="ps-destinations"]:checked').each((i, a)=> {
				selectedDestinations.push(a.value.toLowerCase());
			});

			$el.find('[name="ps-bedrooms"]:checked').each((i, a)=> {
				selectedRooms.push(a.value.toLowerCase());
			});

			$el.find('.property-grid-item').each(function(){
				// Search Term Match
				let title = $(this).find('.js-property-title').text().toLowerCase();
				let terms = searchTerms.split(' ');
				let hasAllTerms = true;
				for(let i=0; i<terms.length; i++) {
					let term = terms[i];
					if(!title.includes(term.toLowerCase())) {
						hasAllTerms = false;
					}
				}

				// Region Match
				if(region) {
					let dataRegion = $(this).data('region');
					if (dataRegion !== region && region !== '') {
						destMatch = false;
					}
				}

				let propertyId = $(this).data('property-id');
				let hotelData = getHotelDataById(propertyId);

				let bedroomMatch = true;
				if(hotelData != undefined){
					let hotelBedrooms = hotelData.numberOfBedrooms;

					if (selectedRooms.length > 0 && hotelBedrooms.length > 0) {
						let roomMatches = 0;

						selectedRooms.forEach(selectedRoom => {
							if (hotelBedrooms.includes(selectedRoom)) {
								roomMatches++;
							}
						});

						if (roomMatches == 0) {
							bedroomMatch = false;
						}
					}
				}

				let hotelDestination = $(this).data('destination');
				let destMatch = true;
				if (selectedDestinations.length > 0 && !selectedDestinations.includes(hotelDestination)) {
					destMatch = false;
				}

				// amenities match
				let amenitiesMatch = true;
				if(hotelData != undefined){
					let hotelAmenities = hotelData.amenities;
					for(let idx in selectedAmenities){
						let amenity = selectedAmenities[idx];
						if(!hotelAmenities.includes(amenity)){
							amenitiesMatch = false;
							break;
						}
					}
				}

				// Show or Hide grid item
				//console.log('hasAllTerms: '+hasAllTerms+'  destMatch: '+destMatch+'  bedroomMatch: '+bedroomMatch+'  amenitiesMatch: '+amenitiesMatch);
				if(hasAllTerms && destMatch && bedroomMatch && amenitiesMatch) {
					$(this).show();
				} else {
					$(this).hide();
				}
			});
		}

		function getHotelDataById(propertyId){
			for (let item in objects.hotels){
				if(objects.hotels[item].hotelId == propertyId){
					return objects.hotels[item];
				}
			}
			return undefined;
		}

		function updatePriceSort () {
			let sortBy = objects.$selectSortValue;

			let $gridItems = $el.find('.property-grid-item');

			let sortedArray = [];
			$gridItems.each(function () {
				let insertAt = 0;
				let myText;
				if(sortBy !== 'priceD' && sortBy !== 'priceA') {
					myText = $(this).attr('data-sort');
				} else {
					myText = $(this).find('.js-room-rate').text().replace('$', '').trim();
				}
				if(!myText) {
					// Sold Out or Error
					insertAt = sortedArray.length;
				} else {
					for (let i = 0; i < sortedArray.length; i++) {
						let cText;
						if(sortBy !== 'priceD' && sortBy !== 'priceA') {
							cText = sortedArray[i].attr('data-sort');
						} else {
							cText = sortedArray[i].find('.js-room-rate').text().replace('$', '').trim();
						}
						if (!cText) {
							break;
						}
						let myInt = parseInt(myText);
						let cInt = parseInt(cText);
						if (myInt === cInt || myInt > cInt && sortBy === 'priceD' || myInt < cInt && sortBy !== 'priceD') {
							break;
						}
						insertAt = i + 1;
					}
				}
				sortedArray.splice(insertAt,0,$(this));
			});

			for(let j=0; j<sortedArray.length; j++) {
				let jitem = sortedArray[j];
				objects.$propertyContainer.append(jitem.detach());
			}
		}

		this.handleSortSelect = (event) => {
			//console.log('OfferPropertySearch = handleSortSelect data = ', event.target.value);
			objects.$selectSortValue = event.target.value;
			updatePriceSort();
		};

		this.handleRateLoad = (event) => {
			//console.log('OfferPropertySearch = handleRateLoad data = ',event.detail.data);
			let hotels = event.detail.data.hotels;
			objects.hotels = hotels;
			activateAllFields();
		};

		// update the filter counts displayed on the dropdown badge
		this.updateBadgeValue = ($filter) => {
			if ($filter) {
				let filterCount = $filter.find('input[type="checkbox"]:checked, input[type="radio"]:checked').length;
				if (filterCount === 0) filterCount = '';
				$filter.find('[data-filter-count]').text(filterCount);
			}
		};

		this.changeAmenities = (amenities) => {
			//console.log('OPS changeAmenities: ', amenities);

			if (amenities.length > 0) {
				$(amenities).each((i,a) => {
					$el.find(`[name="ps-amenities"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				//qs.set('amenities', amenities);
				this.toggleDisplayValues(objects.$amenitiesCountBadge, true);
			} else {
				//qs.delete('amenities');
				this.toggleDisplayValues(objects.$amenitiesCountBadge, false);
			}
			this.updateAmenitiesDisplay(objects.$amenitiesCountBadge);
			this.updateBadgeValue(objects.$amenitiesCountBadge);
		};

		this.updateAmenitiesDisplay = ($filter) => {
			if ($filter) {
				let selectedFilterArray = $filter.find('input[type="checkbox"]:checked, input[type="radio"]:checked');
				let newFilterText = [];
				for (var i = 0; i < selectedFilterArray.length; i++) {
					newFilterText.push($(selectedFilterArray[i]).attr('aria-label'));
				}
				if (newFilterText.length <= 0) {
					objects.$selectAmenities.find('.js-select-amenities-button-copy').text('All Amenities');
					objects.$selectAmenities.attr('aria-label', 'All Amenities');
				} else {
					objects.$selectAmenities.find('.js-select-amenities-button-copy').text(newFilterText.join(', '));
					objects.$selectAmenities.attr('aria-label', newFilterText.join(', '));
				}
			}
		};

		this.handleAmenitiesChange = (e) => {
			//console.log('OPS handleAmenitiesChange: ', e);

			let selectedOptions =  $el.find('[name="ps-amenities"]:checked');
			let selectedValues = [];

			selectedOptions.each((i,a)=> {
				selectedValues.push(a.value.toLowerCase());
			});

			this.changeAmenities(selectedValues);
			updateFilter();
			this.closeCollapse();
		};

		this.handleAmenitiesClear = (e) => {
			//console.log('OPS handleAmenitiesClear: ', e);
			let selectedOptions =  $el.find('[name="ps-amenities"]:checked');
			let selectedValues = [];

			selectedOptions.each((index, option)=> {
				option.checked = false;
			});

			this.changeAmenities(selectedValues);
			updateFilter();
			this.closeCollapse();
		};

		// **

		this.changeDestinations = (destinations) => {
			//console.log('OPS changedestinations: ', destinations);

			if (destinations.length > 0) {
				$(destinations).each((i,a) => {
					$el.find(`[name="ps-destinations"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				//qs.set('destinations', destinations);
				this.toggleDisplayValues(objects.$destinationsCountBadge, true);
			} else {
				//qs.delete('destinations');
				this.toggleDisplayValues(objects.$destinationsCountBadge, false);
			}
			this.updateDestinationsDisplay(objects.$destinationsCountBadge);
			this.updateBadgeValue(objects.$destinationsCountBadge);
		};

		this.updateDestinationsDisplay = ($filter) => {
			if ($filter) {
				let selectedFilterArray = $filter.find('input[type="checkbox"]:checked, input[type="radio"]:checked');
				let newFilterText = [];
				for (var i = 0; i < selectedFilterArray.length; i++) {
					newFilterText.push($(selectedFilterArray[i]).data('display-name'));
				}
				if (newFilterText.length <= 0) {
					objects.$selectDestinations.find('.js-select-destinations-button-copy').text('All Destinations');
					objects.$selectDestinations.attr('aria-label', 'All Destinations');
				} else {
					objects.$selectDestinations.find('.js-select-destinations-button-copy').text(newFilterText.join(', '));
					objects.$selectDestinations.attr('aria-label', newFilterText.join(', '));
				}
			}
		};

		this.handleDestinationsChange = (e) => {
			//console.log('OPS handledestinationsChange: ', e);

			let selectedOptions =  $el.find('[name="ps-destinations"]:checked');
			let selectedValues = [];

			selectedOptions.each((i,a)=> {
				selectedValues.push(a.value.toLowerCase());
			});

			this.changeDestinations(selectedValues);
			updateFilter();
			this.closeCollapse();
		};

		this.handleDestinationsClear = (e) => {
			//console.log('OPS handledestinationsClear: ', e);
			let selectedOptions =  $el.find('[name="ps-destinations"]:checked');
			let selectedValues = [];

			selectedOptions.each((index, option)=> {
				option.checked = false;
			});

			this.changeDestinations(selectedValues);
			updateFilter();
			this.closeCollapse();
		};
		
		// **

		// **

		this.changeRooms = (rooms) => {
			//console.log('OPS changerooms: ', rooms);
		
			if (rooms.length > 0) {
				$(rooms).each((i, a) => {
					$el.find(`[name="ps-bedrooms"][value="${a.toLowerCase()}"]`).prop('checked', true);
				});
				//qs.set('rooms', rooms);
				this.toggleDisplayValues(objects.$roomsCountBadge, true);
			} else {
				//qs.delete('rooms');
				this.toggleDisplayValues(objects.$roomsCountBadge, false);
			}
			this.updateRoomsDisplay(objects.$roomsCountBadge);
			this.updateBadgeValue(objects.$roomsCountBadge);
		};
		
		this.updateRoomsDisplay = ($filter) => {
			if ($filter) {
				let selectedFilterArray = $filter.find('input[type="checkbox"]:checked, input[type="radio"]:checked');
				let newFilterText = [];
				for (var i = 0; i < selectedFilterArray.length; i++) {
					newFilterText.push($(selectedFilterArray[i]).data('display-name'));
				}
				if (newFilterText.length <= 0) {
					objects.$selectRooms.find('.js-select-rooms-button-copy').text('Any Room Type');
					objects.$selectRooms.attr('aria-label', 'Any Room Type');
				} else {
					objects.$selectRooms.find('.js-select-rooms-button-copy').text(newFilterText.join(', '));
					objects.$selectRooms.attr('aria-label', newFilterText.join(', '));
				}
			}
		};
		
		this.handleRoomsChange = (e) => {
			//console.log('OPS handleroomsChange: ', e);
		
			let selectedOptions = $el.find('[name="ps-bedrooms"]:checked');
			let selectedValues = [];
		
			selectedOptions.each((i, a) => {
				selectedValues.push(a.value.toLowerCase());
			});
		
			this.changeRooms(selectedValues);
			updateFilter();
			this.closeCollapse();
		};
		
		this.handleRoomsClear = (e) => {
			//console.log('OPS handleroomsClear: ', e);
			let selectedOptions = $el.find('[name="ps-bedrooms"]:checked');
			let selectedValues = [];
		
			selectedOptions.each((index, option) => {
				option.checked = false;
			});
		
			this.changeRooms(selectedValues);
			updateFilter();
			this.closeCollapse();
		};

		// **

		this.closeCollapse = () => {
			objects.$filters.find('.collapse').collapse('hide');
			// if (utils.getViewportSize() === 'xs' || utils.getViewportSize() === 'sm') {
			// 	utils.scrollTo(null, null, null, () => {});
			// }
		};

		objects.$searchHotels.on('input', objects.$loadProperties, function(e) {
			//console.log('OPS = Change Search Term');
			updateFilter();
		});

		// select dates - display modal if space bar is pressed
		objects.$selectDates.on('keypress', function(e){
			//console.log('OPS = display dates modal');
			$('#collapse-date-picker').modal({
				show: true
			});
		});

		// price sort
		objects.$selectSort.on('click', objects.$loadProperties, function(e) {
			let alertText;
			let $btn = $(e.currentTarget);

			if (objects.$selectSortValue === 'priceD') {
				objects.$selectSortValue = 'priceA';
				$btn.find('svg').first().show();
				$btn.find('svg').last().hide();

				$btn.attr('aria-label', $('.ascending-label').text() + $btn.data('sort-type'));
				alertText = ' ' + $('.ascending-label').data('type') + ' ';
			} else {
				objects.$selectSortValue = 'priceD';
				$btn.find('svg').first().hide();
				$btn.find('svg').last().show();

				$btn.attr('aria-label', $('.descending-label').text() + $btn.data('sort-type'));
				alertText = ' ' + $('.descending-label').data('type') + ' ';
			}

			let newText = objects.$ariaAssistant.data('aria-assistant') + alertText + $btn.data('sort-type');
			objects.$ariaAssistant.text(newText);

			updatePriceSort();
		});

		let roomsLoaded = 0;
		// Wait until all rooms are loaded and then sort by price if applicable
		$(document).on('property-detail-rate-updated', function() {
			roomsLoaded++;
			if(roomsLoaded >= $el.find('.property-grid-item').length) {
				objects.$selectSort.prop('disabled', false);
				if(objects.$selectSort.val() === 'priceA' || objects.$selectSort.val() === 'priceD') {
					updatePriceSort();
				}
			}
		});
	}

	init() {
		this.firstRun();
	}
}

export default OfferPropertySearch;
