import moment from 'moment/moment';

class BookNowButton {

	constructor($, $el) {

		const params = {
			url: $el.attr('data-bookUrl')
		};

		const objects = {
			$btn: $el
		};

		this.firstRun = () => {
			console.info('~~~ BookNowButton Module ~~~');

			$(document).on('date-occupancy-update', this.update);

			this.update();
		};

		this.update = (event) => {
			let bookUrl = params.url;
			let startDate = localStorage.getItem('startDate');
			let endDate = localStorage.getItem('endDate');
			let adultCount = localStorage.getItem('adults');
			let childCount = localStorage.getItem('children');
			let rooms = localStorage.getItem('rooms');

			let today = moment().startOf('day').toDate();
			if(!startDate || startDate < today) {
				startDate  = moment(new Date()).add(1,'days');
				endDate  = moment(new Date()).add(5,'days');
			}
			if(!adultCount) { adultCount = '2'; }
			if(!childCount) { childCount = '0'; }
			if(!rooms) { rooms = '1'; }

			// console.log("Start URL: "+bookUrl);

			bookUrl = bookUrl
				.replace(/{arrive}/g, moment(startDate).format('MM-DD-YYYY'))
				.replace(/{depart}/g, moment(endDate).format('MM-DD-YYYY'))
				.replace(/{adult}/g, adultCount)
				.replace(/{child}/g, childCount)
				.replace(/{rooms}/g, rooms);

			/* 3/29/24 - TMW - dont set dest because synxis doesn't like it anymore */
			bookUrl = bookUrl
				.replace(/{dest}/g, '')
				.replace(/{src}/g, objects.$btn.attr('data-src'))
				.replace(/{hotel}/g, objects.$btn.attr('data-hotel'))
				.replace(/{promo}/g, objects.$btn.attr('data-promo'));

			objects.$btn.attr('href', bookUrl);
		};
	}

	init() {
		this.firstRun();
	}
}

export default BookNowButton;
