class Login {

	/**
	 * Login Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Login instance
	 */

	constructor($, $el) {

		const objects = {
			$form: $el.find('form'),
			$btn: $el.find('button.btn')
		};

		this.handleLogin = (event) => {
			// POST to Jahia login servlet.
			objects.$btn.addClass('disabled');
			let url = objects.$form.attr('action');
			let data = objects.$form.serialize();

			$el.find('.alert.alert-danger').addClass('d-none');

			$.ajax({
				cache: false,
				type: 'POST',
				url: url,
				data: data
			})
				.done(this.loginSuccess)
				.fail(this.loginFail);
			event.preventDefault();
		};

		this.loginSuccess = (data, textStatus, jqXHR) => {
			if (data === 'OK') {
				console.info('Login succeeded.');
				location.reload();
			} else {
				this.loginFail(jqXHR, textStatus, data);
			}
		};

		this.loginFail = (jqXHR, textStatus, errorThrown) => {
			console.error(`Login failed: ${textStatus} : ${errorThrown}`);

			if(textStatus === 'success') {
				$el.find('.alert.alert-danger.d-none.unauthorized').removeClass('d-none');
			} else {
				$el.find('.alert.alert-danger.d-none.network-error').removeClass('d-none');
			}

			objects.$btn.removeClass('disabled');
		};

		this.resize = () => {
		};

		this.firstRun = () => {
			console.info('~~~ Login Module ~~~');
			$el.submit(this.handleLogin);
			$el.find('.btn-facebook').on('click', Login.connectToFacebook);
			$el.find('.btn-google').on('click', Login.connectToGoogle);
		};
	}

	// noinspection JSMethodCanBeStatic
	name() {
		return 'Login';
	}

	init() {
		this.firstRun();
	}

/*  Removed as authentication is not working.  See Task 21799
	static connectToFacebook(event) {
		event.preventDefault();
		const $btn = $(event.currentTarget);
		const actionUrl = $btn.data('action');
		const site = $btn.data('site');
		const popup = window.open('', 'Facebook Authorization', 'menubar=no,status=no,scrollbars=no,width=1145,height=725,modal=yes,alwaysRaised=yes');
		const xhr = new XMLHttpRequest();
		xhr.open('GET', actionUrl);
		xhr.setRequestHeader('Accept', 'application/json;');
		xhr.send();

		xhr.onreadystatechange = function () {
			if (xhr.readyState !== 4 || xhr.status !== 200) return;
			const json = JSON.parse(xhr.responseText);
			popup.location.href = json['authorizationUrl'];
			window.addEventListener('message', function (event) {
				if (event.data['authenticationIsDone']) {
					setTimeout(function () {
						popup.close();
						if (event.data['isAuthenticate']) {
							window.location.search = `site=${site}`;
						}
					}, 3000);
				}
			});
		};
	}

	static connectToGoogle(event) {
		event.preventDefault();
		const $btn = $(event.currentTarget);
		const actionUrl = $btn.data('action');
		const site = $btn.data('site');
		const popup = window.open('', 'Google Authorization', 'menubar=no,status=no,scrollbars=no,width=1145,height=725,modal=yes,alwaysRaised=yes');
		const xhr = new XMLHttpRequest();
		xhr.open('GET', actionUrl);
		xhr.setRequestHeader('Accept', 'application/json;');
		xhr.send();

		xhr.onreadystatechange = function () {
			if (xhr.readyState !== 4 || xhr.status !== 200) return;
			const json = JSON.parse(xhr.responseText);
			popup.location.href = json['authorizationUrl'];
			window.addEventListener('message', function (event) {
				if (event.data['authenticationIsDone']) {
					setTimeout(function () {
						popup.close();
						if (event.data['isAuthenticate']) {
							window.location.search = `site=${site}`;
						}
					}, 3000);
				}
			});
		};

	}
*/
}
export default Login;
