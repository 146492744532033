import shave from 'shave';

class Collapsible {

	/**
	 * Collapsible Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - Collapsible instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {
		const objects = {
			$linesShown: $el.data('lines-shown'),
			$linesShownMobile: $el.data('mobile-lines-shown'),
			$lineHeight: $el.find('p').css('line-height'),
			$collapseBtn: $el.siblings('.collapsible-btn')
		};

		this.checkViewport = () => {
			if (utils.getViewportSize() ==='xs' || utils.getViewportSize() ==='sm') {
				this.handleHeight(objects.$linesShownMobile);
			} 
			else {
				this.handleHeight(objects.$linesShown);
			}
		};


		this.handleHeight = (linesShown) => {
			let $newHeight = parseFloat(objects.$lineHeight) * linesShown;
			$el.css('height', $newHeight - 2);

			if($el.data('ellipsis')) {
				shave($el, $newHeight);
			}
		};


		this.hideBtn = () => {
			let $totalLines = parseFloat($el.css('height')) / parseFloat(objects.$lineHeight);
			let $lines;

			if (utils.getViewportSize() ==='xs' || utils.getViewportSize() ==='sm') {
				$lines = objects.$linesShownMobile;
			} 
			else {
				$lines = objects.$linesShown;
			}

			if(Math.floor($totalLines) <= $lines) {
				objects.$collapseBtn.removeClass('active');
				$el.addClass('no-fade');
			} else {
				objects.$collapseBtn.addClass('active');
				this.checkViewport();
			}
		};

		this.removeEllipsis = () => {
			$el.find('.js-shave').show();
			$el.find('.js-shave-char').hide();
		};

		this.firstRun = () => {
			console.info('~~~ Collapsible Module ~~~');

			this.hideBtn();

			$el.on('hidden.bs.collapse', this.checkViewport);
			objects.$collapseBtn.on('click', this.removeEllipsis);
		};
	}

	name() {
		return 'Collapsible';
	}

	init() {
		this.firstRun();
	}

}

export default Collapsible;

