//import GoogleMapsLoader from 'google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import CustomEvent from 'custom-event';

class MapView {

	/**
	 * MapView Class
	 * @param {object} $ - Object imports jQuery
	 * @param {object} $el - MapView instance
	 * @param {object} utils - Instance of Utils
	 */

	constructor($, $el, utils) {
		const params = {
			lang: $el.data('lang') || $('html').lang || 'en',
			region: $el.data('region') || '',
			version: $el.data('version') || '3',
			locations: $el.data('locations'),
			centerPoint: $el.data('center-point'),
			imagePath: $el.data('image-path'),
			markerSetActiveOnload: $el.data('marker-set-active-onload'),
			markerIcon: $el.data('marker'),
			markerIconActive: $el.data('marker-active'),
			markerIconSize: $el.data('marker-size'),
			markerIconSizeMobile: $el.data('marker-size-mobile'),
			markerClick: $el.data('marker-click'),
			markerClickMobile: $el.data('marker-click-mobile'),
			markerClickPosition: $el.data('marker-click-position') || 'RIGHT',
			markerClickOffset: $el.data('marker-click-offset') || { x: 0, y: 0},
			markerClickPan: $el.data('marker-click-pan'),
			markerHover: $el.data('marker-hover'),
			markerHoverMobile: $el.data('marker-hover-mobile'),
			markerHoverPosition: $el.data('marker-hover-position') || 'RIGHT',
			markerHoverOffset: $el.data('marker-hover-offset') || { x: 0, y: 0},
			markerHoverPan: $el.data('marker-hover-pan'),
			zoomControlPosition: $el.data('zoom-control-position'),
			zoomControlPositionMobile: $el.data('zoom-control-position-mobile') || $el.data('zoom-control-position'),
			fitToBounds: $el.data('fit-to-bounds'),
			zoom: $el.data('zoom') || 8,
			styles: $el.data('styles') || [
				{
					'featureType': 'all',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#3c3c3c'
						}
					]
				},
				{
					'featureType': 'landscape',
					'elementType': 'all',
					'stylers': [
						{
							'color': '#e5f7ec'
						}
					]
				},
				{
					'featureType': 'landscape',
					'elementType': 'labels',
					'stylers': [
						{
							'visibility': 'simplified'
						}
					]
				},
				{
					'featureType': 'landscape',
					'elementType': 'labels.text',
					'stylers': [
						{
							'visibility': 'simplified'
						},
						{
							'color': '#006666'
						}
					]
				},
				{
					'featureType': 'landscape.man_made',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#e5f7ec'
						},
						{
							'visibility': 'on'
						}
					]
				},
				{
					'featureType': 'landscape.man_made',
					'elementType': 'geometry.stroke',
					'stylers': [
						{
							'color': '#83d8a3'
						},
						{
							'visibility': 'on'
						},
						{
							'weight': 1.5
						}
					]
				},
				{
					'featureType': 'poi',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'poi.attraction',
					'elementType': 'labels.text',
					'stylers': [
						{
							'color': '#408080'
						},
						{
							'visibility': 'simplified'
						}
					]
				},
				{
					'featureType': 'poi.park',
					'elementType': 'labels.text',
					'stylers': [
						{
							'color': '#006666'
						},
						{
							'visibility': 'simplified'
						}
					]
				},
				{
					'featureType': 'road',
					'elementType': 'all',
					'stylers': [
						{
							'color': '#bdeace'
						}
					]
				},
				{
					'featureType': 'road',
					'elementType': 'labels',
					'stylers': [
						{
							'visibility': 'on'
						}
					]
				},
				{
					'featureType': 'road',
					'elementType': 'labels.text',
					'stylers': [
						{
							'color': '#000000'
						},
						{
							'visibility': 'simplified'
						}
					]
				},
				{
					'featureType': 'road.highway',
					'elementType': 'labels',
					'stylers': [
						{
							'color': '#000000'
						},
						{
							'visibility': 'simplified'
						}
					]
				},
				{
					'featureType': 'road.highway',
					'elementType': 'labels.icon',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'transit.station.bus',
					'elementType': 'all',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'featureType': 'water',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#aadbe6'
						}
					]
				}
			]
		};
		const objects = {
			$window: $(window),
			$linkedCarousel: $('[data-linked-carousel]'),
			$map: $el.find('.map')[0],
			$markerClickTemplate: $el.find('[data-template-marker-click]'),
			$markerHoverTemplate: $el.find('[data-template-marker-hover]'),
			$clusterHoverTemplate: $el.find('[data-template-cluster-hover]'),
			objMap: null
		};

		let isInitialized = false;

		let bounds;
		let centerPoint = params.centerPoint;
		let markerCluster;

		let markers = [];
		let markerActive = null;

		let infoboxClick, infoboxHover;

		this.locations = params.locations;

		let viewport = utils.getViewportSize();

		let markerIconSize = (viewport === 'xs' || viewport === 'sm') ? params.markerIconSizeMobile : params.markerIconSize;

		this.getPixelOffset = (markerHoverPosition, template) => {

			let mtWidth = template.width();
			let mtHeight = template.outerHeight();

			switch (markerHoverPosition) {
				case 'TOP':
					return new self.google.maps.Size(-(mtWidth / 2 + (markerIconSize / 2)), -(markerIconSize + 10));
				case 'BOTTOM':
					return new self.google.maps.Size(-(mtWidth / 2 + (markerIconSize / 2)), (mtHeight + 10));
				case 'RIGHT':
					return new self.google.maps.Size(10, (mtHeight / 2) - (markerIconSize / 2));
				case 'LEFT':
					return new self.google.maps.Size(-(mtWidth) - (markerIconSize + 10), (mtHeight / 2) - (markerIconSize / 2));
				default:
					return {x: 0, y: 0};
			}
		};

		/**
		 * highlightMarker()
		 * Highlights map marker
		 * @param {number} marker -  Index of marker within array to be highlighted
		 */
		this.highlightMarker = (marker) => {
			if (markerActive !== null) {
				markers[markerActive].setIcon(markers[markerActive].iconDefault);
			}
			if (markers[marker]) {
				markers[marker].setIcon(markers[marker].iconActive);
			}
			markerActive = marker;
		};

		this.unhighlightMarker = (marker) => {
			if (marker !== null) {
				markers[marker].setIcon(markers[marker].iconDefault);
			}
			markerActive = null;
		};

		this.centerMarker = (marker) => {
			objects.objMap.setCenter(markers[marker].position);
		};

		this.setZoom = (zoomLevel) => {
			objects.objMap.setZoom(zoomLevel);
		};

		this.getZoom = () => {
			return objects.objMap.zoom;
		};

		/**
		 * showClusterHover()
		 * Displays infobox upon map cluster hover
		 * @param {object} cluster -  Cluster that was hovered over
		 */
		this.showClusterHover = (cluster) => {
			if (infoboxHover) { infoboxHover.close(); }
			if (infoboxClick) { infoboxClick.close(); }

			infoboxHover.pixelOffset_ = this.getPixelOffset(params.markerHoverPosition, objects.$clusterHoverTemplate);
			let clusterHoverContent = objects.$clusterHoverTemplate.prop('outerHTML');
			infoboxHover.setContent(clusterHoverContent);
			infoboxHover.open(objects.objMap, cluster.markers_[0]);
		};

		/**
		 * showMarkerHover()
		 * Displays infobox upon map marker hover
		 * @param {number} marker -  Index of marker within array of markers that was hovered
		 */
		this.showMarkerHover = (marker) => {
			if (infoboxHover) { infoboxHover.close(); }
			if (infoboxClick) { infoboxClick.close(); }
			if (marker !== null) {

				let m = markers[marker];
				infoboxHover.pixelOffset_ = this.getPixelOffset(params.markerHoverPosition, objects.$markerHoverTemplate);
				infoboxHover.setContent(m.contentStringHover);
				infoboxHover.open(objects.objMap, m);

				if (params.markerHoverPan === true) {
					objects.objMap.panTo(infoboxHover.getPosition());
				}
			}
		};

		this.hideClusterHover = (cluster) => {
			if (infoboxHover) { infoboxHover.close(); }
		};

		this.hideMarkerHover = (marker) => {
			if (infoboxHover) { infoboxHover.close(); }
		};

		/**
		 * showMarkerClick()
		 * Displays infobox upon map marker click
		 * @param {number} marker -  Index of marker within array of markers that was clicked
		 */
		this.showMarkerClick = (marker) => {
			if (
				((viewport === 'xs' || viewport === 'sm') && params.markerClickMobile) ||
				((viewport !== 'xs' && viewport !== 'sm') && params.markerClick)
			) {

				if (marker !== null && objects.$markerClickTemplate[0]) {
					let m = markers[marker];

					infoboxClick.pixelOffset_ = this.getPixelOffset(params.markerClickPosition, objects.$markerClickTemplate);
					infoboxClick.setContent(m.contentStringClick);
					infoboxClick.open(objects.objMap, m);

					if (params.markerClickPan === true) {
						objects.objMap.setCenter(infoboxClick.getPosition());
					}
				}
			}

			else if (
				((viewport === 'xs' || viewport === 'sm') && !params.markerClickMobile)
			) {
				objects.objMap.setCenter(markers[marker].position);
			}
		};

		this.hideMarkerClick = () => {
			if (infoboxClick) { infoboxClick.close(); }
		};

		/**
		 * getMarker()
		 * Returns specific map maker from array of markers
		 * @param {string} key -  Key to find marker by. i.e. 'id'
		 * @param {string} value - Value of attribute to find marker by
		 * @returns {number} - Index within array of markers
		 */
		this.getMarker = (key, value) => {
			let marker = markers.find((m) => m[key].toString() === value.toString());
			return marker ? markers.indexOf(marker) : -1;
		};

		/**
		 * markerIsActive()
		 * Checks to see if marker is current active marker
		 * @param {number} marker - marker index within array of markers
		 * @returns {boolean} - Whether marker is current active marker
		 */
		this.markerIsActive = (marker) => {
			return markers[markerActive] === markers[marker];
		};

		this.addADA = () => {
			setTimeout(function(){
				// $('.cluster').attr('tabindex', 0);

				$('[aria-label="Zoom in"]').attr('data-toggle', 'tooltip');
				$('[aria-label="Zoom in"]').attr('data-trigger', 'focus');

				$('[aria-label="Zoom out"]').attr('data-toggle', 'tooltip');
				$('[aria-label="Zoom out"]').attr('data-trigger', 'focus');

				$('[aria-label="Zoom out"]').tooltip();
				$('[aria-label="Zoom in"]').tooltip();
			}, 500);
		};

		/**
		 * plotMarkers()
		 * Places map markers on map from markers array
		 * @param {array} m - array of map markers to plot
		 */
		this.plotMarkers = (m) => {
			bounds = new self.google.maps.LatLngBounds();
			markers = [];
			m.forEach((markerData, index) => {
				const position = new self.google.maps.LatLng(markerData.lat, markerData.lng);
				const $mct = objects.$markerClickTemplate;

				let $img = $mct.find('[data-image]');
				$img.attr('src', markerData.imgPath);

				let title = markerData.title;
				if (markerData.titleUrl) {
					// add link for <img>/<picture>
					if ($img.parent().is('picture')) {
						$img.parent().wrap(`<a title="${title}" href="${markerData.titleUrl}"></a>`);
					} else {
						$img.wrap(`<a title="${title}" href="${markerData.titleUrl}"></a>`);
					}
					// add link for title
					title = `<a title="${title}" href="${markerData.titleUrl}">${title}</a>`;
				}
				$mct.find('[data-title]').html(title);
				$mct.find('[data-description]').html(markerData.description).wrap(`<a href="${markerData.titleUrl}"></a>`);

				let nextPin = index+1;
				let prevPin = index-1;
				if(nextPin === m.length) {
					nextPin = 0;
				}
				if(prevPin < 0) {
					prevPin = m.length - 1;
				}

				// $mct.find('.nextPin').attr('onclick', "var ev = new CustomEvent('updateMap'," +
				// 	"{'detail': {marker: "+nextPin+"}} );" +
				// 	"$(document)[0].dispatchEvent(ev);");
				// $mct.find('.prevPin').attr('onclick', "let ev = new CustomEvent('updateMap'," +
				// 	"{'detail': {marker: "+prevPin+"}} );" +
				// 	"$(document)[0].dispatchEvent(ev);");
				// For IE compatibility we cannot use the new CustomEvent() function
				$mct.find('.nextPin').attr('onclick', 'var ev = document.createEvent(\'CustomEvent\'); ' +
					'ev.initCustomEvent(\'updateMap\', false, false, {marker: '+nextPin+'});' +
					'$(document)[0].dispatchEvent(ev);');
				$mct.find('.prevPin').attr('onclick', 'var ev = document.createEvent(\'CustomEvent\'); ' +
					'ev.initCustomEvent(\'updateMap\', false, false, {marker: '+prevPin+'});' +
					'$(document)[0].dispatchEvent(ev);');

				let contentStringClick;

				if (markerData.contentStringClick) {
					contentStringClick = markerData.contentStringClick;
				} else {
					contentStringClick = $mct.prop('outerHTML');
				}

				let markerIcon = {
					url: params.markerIcon,
					anchor: new self.google.maps.Point(markerIconSize, markerIconSize),
					scaledSize: new self.google.maps.Size(markerIconSize, markerIconSize)
				};
				let markerIconActive = {
					url: params.markerIconActive,
					anchor: markerIcon.anchor,
					scaledSize: markerIcon.scaledSize
				};

				if (markerData.iconUrl) {
					markerIcon.url = markerData.iconUrl;
				}
				if (markerData.iconUrlActive) {
					markerIconActive.url = markerData.iconUrlActive;
				}

				const $mht = objects.$markerHoverTemplate;
				$mht.find('[data-title]').html(markerData.title);
				let contentStringHover;

				if (markerData.contentStringHover) {
					contentStringHover = markerData.contentStringHover;
				} else {
					contentStringHover = $mht.prop('outerHTML');
				}


				const marker = new self.google.maps.Marker({
					id: markerData.id,
					status: markerData.status,
					title: markerData.title,
					label: { text: ' ', color: 'white' },
					map: objects.objMap,
					position: position,
					icon: markerIcon,
					iconDefault: markerIcon,
					iconActive: markerIconActive,
					animation: self.google.maps.Animation.DROP,
					contentStringHover: contentStringHover,
					contentStringClick: contentStringClick,
					gmpClickable: true,
				});

				markers.push(marker);
				bounds.extend(position);

				if (
					((viewport === 'xs' || viewport === 'sm') && params.markerHoverMobile) ||
					((viewport !== 'xs' && viewport !== 'sm') && params.markerHover)
				) {

					self.google.maps.event.addListener(marker, 'mouseover', (e) => {
						if (params.markerHover === true) {
							this.showMarkerHover(markers.indexOf(marker));
						}
						this.highlightMarker(markers.indexOf(marker));
					});
					self.google.maps.event.addListener(marker, 'mouseout', (e) => {
						if (params.markerHover === true) {
							this.hideMarkerHover(markers.indexOf(marker));
						}
						// this.unhighlightMarker(markers.indexOf(marker));
					});
				}

				self.google.maps.event.addListener(marker, 'click', (e) => {
					let ev = new CustomEvent('markerclick',
						{'detail': {marker: marker}}
					);
					$el[0].dispatchEvent(ev);

					if (infoboxClick) {
						infoboxClick.close();
					}
					if (infoboxHover) {
						infoboxHover.close();
					}

					if (params.markerClick === true) {
						this.showMarkerClick(markers.indexOf(marker));
					}

					this.highlightMarker(markers.indexOf(marker));

				});

				objects.objMap.addListener('dragstart', function() {
					let ev = new CustomEvent('drag',
						{'detail': {drag: 'start'}}
					);
					$el[0].dispatchEvent(ev);
				});
				objects.objMap.addListener('zoom_changed', function() {
					let ev = new CustomEvent('zoom',
						{'detail': {zoom: 'change'}}
					);
					$el[0].dispatchEvent(ev);
				});
			});

			const renderer = {
				render: ({ count, position }) => {
					return new self.google.maps.Marker({
						position,
						label: {
							text: String(count),
							color: 'white',
							fontSize: '12px'
						},
						title: String(count) + ' hotels or condo resorts. Please use the enter or space key to zoom in and view the individual properties.',
						icon: {
							url: `${params.imagePath}/markers/map-marker-available-circle.svg`,
							scaledSize: new self.google.maps.Size(markerIconSize, markerIconSize),
						},
						// adjust zIndex to be above other markers
						zIndex: Number(self.google.maps.Marker.MAX_ZINDEX) + count,
					});
				}
			};

			markerCluster = new MarkerClusterer({
				markers,
				map: objects.objMap,
				renderer: renderer
			});

			markerCluster.addListener('clusteringend', () => {
				/* -- Disabling custom cluster markers
				let clusters = markerCluster.getClusters();
				$(clusters).each((i,c) => {
					// let markers = c.markers_;
					// let statuses = markers.map((m) => {
					// 	return m.status;
					// });

					let clusterMarkers = c.markers_;
					let statuses = clusterMarkers.map((m) => {
						return m.status;
					});

					c.isActive = clusterMarkers.includes(markers[markerActive]);

					if (statuses.every((a) => a===-1)) {
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-unavailable-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-unavailable-cluster-active.svg`;
					} else if (statuses.every((a) => a===0)) {
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-non-matching-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-non-matching-cluster-active.svg`;
					} else if (statuses.every((a) => a===1)) {
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-available-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-available-cluster-active.svg`;
					} else if (statuses.includes(-1) && statuses.includes(0) && statuses.includes(1)) {
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-available-non-matching+unavailable-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-available-non-matching+unavailable-cluster-active.svg`;
					} else if (statuses.includes(-1) && statuses.includes(0)) {
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-non-matching+unavailable-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-non-matching+unavailable-cluster-active.svg`;
					} else if (statuses.includes(-1) && statuses.includes(1)) {
						// todo: need icon?
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-available+unavailable-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-available+unavailable-cluster-active.svg`;
					} else if (statuses.includes(0) && statuses.includes(1)) {
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-non-matching+unavailable-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-non-matching+unavailable-cluster-active.svg`;
					} else {
						c.clusterIcon_.url_ = `${params.imagePath}/markers/map-marker-available-cluster.svg`;
						c.clusterIcon_.active_url_ = `${params.imagePath}/markers/map-marker-available-cluster-active.svg`;
					}

					if (c.clusterIcon_.div_ && c.clusterIcon_.div_.firstChild) {
						if (c.isActive) {
							c.clusterIcon_.div_.firstChild.src = c.clusterIcon_.active_url_;
						} else {
							c.clusterIcon_.div_.firstChild.src = c.clusterIcon_.url_;
						}
					}
				});
				*/

				this.addADA();
			});

			if (
				((viewport === 'xs' || viewport === 'sm') && params.markerHoverMobile) ||
				((viewport !== 'xs' && viewport !== 'sm') && params.markerHover)
			) {
				self.google.maps.event.addListener(markerCluster,'mouseover', (c) => {
					c.clusterIcon_.div_.firstChild.src = c.clusterIcon_.active_url_;
					this.showClusterHover(c);
				});

				self.google.maps.event.addListener(markerCluster,'mouseout', (c) => {
					c.clusterIcon_.div_.firstChild.src = c.clusterIcon_.url_;
					this.hideClusterHover(c);
				});
			}


			self.google.maps.event.addListener(markerCluster,'click', (c) => {
				this.hideClusterHover(c);
				this.addADA();
			});

			this.fitMap();

			if(this.getZoom() < 14) {
				this.setZoom(14);
			}

			if (params.markerSetActiveOnload === true) {
				this.showMarkerClick(0);
				this.highlightMarker(0);
				markerActive = 0;
			}

			self.google.maps.event.addListener(infoboxClick, 'domready', function() {
				$('.infoBox .close').on('click', () => {
					if (infoboxClick) {
						infoboxClick.close();
					}
					if (infoboxHover) {
						infoboxHover.close();
					}
				});
			});

			this.addADA();
		};

		this.fitMap = () => {
			let viewport = utils.getViewportSize();
			if(params.fitToBounds === true && markers.length > 1) {
				let padding = 50;
				if (viewport === 'xs' || viewport === 'sm') { padding = 5; }
				objects.objMap.fitBounds(bounds, padding);
			}
			if(markers.length === 1) {
				this.centerMarker(0);
			}
		};

		this.initMap = () => {
			//GoogleMapsLoader.KEY = 'AIzaSyDgyQzSAhGEuu18XPrXhnCvU466Y_hCl1I';
			//GoogleMapsLoader.LANGUAGE = params.lang;
			//GoogleMapsLoader.REGION = params.region;
			//GoogleMapsLoader.VERSION = params.version;

			const loader = new Loader({
				// Client provided: https://projects.avantia.net/issues/12694
				apiKey: 'AIzaSyDgyQzSAhGEuu18XPrXhnCvU466Y_hCl1I',
				version: 'weekly',
			});

			loader.load().then((google) => {

				let paramZoomControlPosition = params.zoomControlPosition;
				if (viewport === 'xs' || viewport === 'sm') {
					paramZoomControlPosition = params.zoomControlPositionMobile;
				}

				let zcp;
				switch (paramZoomControlPosition) {
					case 'LEFT_TOP':
						zcp = self.google.maps.ControlPosition.LEFT_TOP;
						break;
					case 'RIGHT_TOP':
						zcp = self.google.maps.ControlPosition.RIGHT_TOP;
						break;
					case 'RIGHT_BOTTOM':
						zcp = self.google.maps.ControlPosition.RIGHT_BOTTOM;
						break;
					default:
						zcp = self.google.maps.ControlPosition.LEFT_BOTTOM;
				}

				objects.objMap = new google.maps.Map(objects.$map, {
					center: centerPoint,
					zoom: params.zoom,
					scrollwheel: false,
					streetViewControl: false,
					mapTypeControl: false,
					zoomControl: true,
					zoomControlOptions: { position: zcp },
					fullscreenControl: false,
					styles: params.styles
				});

				let InfoBox  = require('google-maps-infobox');
				infoboxClick = new InfoBox({
					content: '',
					disableAutoPan: false,
					alignBottom: true,
					pixelOffset: new self.google.maps.Size(params.markerClickOffset.x, params.markerClickOffset.y ),
					zIndex: null,
					boxStyle: {},
					closeBoxURL: '',
					maxWidth: 0,
					infoBoxClearance: new self.google.maps.Size(100, 100)
				});

				infoboxHover = new InfoBox({
					content: '',
					disableAutoPan: false,
					alignBottom: true,
					pixelOffset: new self.google.maps.Size(params.markerHoverOffset.x, params.markerHoverOffset.y),
					zIndex: null,
					boxStyle: {},
					closeBoxURL: '',
					infoBoxClearance: new self.google.maps.Size(50, 50)
				});

				if (this.locations) {
					this.plotMarkers(this.locations);
					if(this.locations.length > 0)
						isInitialized = true;
				}
			});

			// start: additional syncing
			///////////////////////////////////////
			let controllerMapId = this.id;
			if ($el.data('sync-carousel')) {
				let $linkedCarousel = $(`[data-sync-map="#${controllerMapId}"]`);
				$linkedCarousel.on('slidechange', (e) => {
					let marker = this.getMarker('id', e.detail.data.id || e.detail.data.propertyId);

					if (marker >= 0 && !this.markerIsActive(marker)) {
						if (infoboxClick) {
							infoboxClick.close();
						}
						if (infoboxHover) {
							infoboxHover.close();
						}
						this.showMarkerClick(marker);
						this.highlightMarker(marker);
						objects.objMap.setZoom(17);
						objects.objMap.setCenter(markers[marker].position);
					}
				});
			}
			///////////////////////////////////////

			$(document).on('updateMap', (e) => {
				let marker = e.detail.marker;

				if (marker >= 0 && !this.markerIsActive(marker)) {
					if (infoboxClick) {
						infoboxClick.close();
					}
					if (infoboxHover) {
						infoboxHover.close();
					}
					this.showMarkerClick(marker);
					this.highlightMarker(marker);
					objects.objMap.setZoom(17);
					objects.objMap.setCenter(markers[marker].position);
				}
			});
		};

		this.id = $el.attr('id');

		this.resize = () => {
			if (objects.objMap && self.google) {
				self.google.maps.event.trigger(objects.objMap, 'resize');
			}
		};

		this.firstRun = () => {
			console.info('~~~ 🌐 MapView Module 🌐 ~~~', this);
			this.initMap();
			$(window).on('load', this.addADA);
		};

		this.isInitialized = function() {
			return isInitialized;
		};
	}

	name() {
		return 'MapView';
	}

	init() {
		// if (!this.isInitialized()) {
		this.firstRun();
		// }
	}
}

export default MapView;
