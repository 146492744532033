/**
 * Module for highlighting the active tab when it is clicked.
 *
 * This module listens for clicks on the navigation links and adds the 'nav-item-active' class
 * to the parent nav item. It also listens for clicks on the icon-svg element to toggle the
 * 'open' class on the element.
 */
class GalleryTabs {

	/**
	 * Constructor function.
	 *
	 * @param {jQuery} $ jQuery object
	 * @param {jQuery} element Element to initialize the module on
	 */
	constructor($, element) {
		/**
		 * The jQuery instance.
		 *
		 * @type {jQuery}
		 */
		this.$ = $;

		/**
		 * The element to initialize the module on.
		 *
		 * @type {jQuery}
		 */
		this.element = element;
	}

	/**
	 * Initialize the module.
	 */
	init() {
		console.log('~~~ GalleryTabs Module ~~~');
		this.setupEventListeners();
	}

	/**
	 * Sets up event listeners for the tab navigation.
	 */
	setupEventListeners() {
		// Handle clicks on nav-links to activate tabs
		this.element.on('click', '.nav-link', (event) => {
			const target = this.$(event.currentTarget);
			const parentNavItem = target.closest('.nav-item');

			// Remove active class from all nav items
			this.element.find('.nav-item').removeClass('nav-item-active');

			// Add active class to the clicked nav item
			parentNavItem.addClass('nav-item-active');
			this.element.removeClass('open');
		});

		// Handle clicks on the specific icon-svg for opening and closing the nav-tabs
		// Event delegation is used here to ensure the listener is attached to dynamically added elements
		this.element.on('click', '.icon-svg.icon-sm.d-md-none', () => {
			this.element.toggleClass('open');
		});
	}

	/**
	 * The name of the module.
	 *
	 * @returns {string}
	 */
	name() {
		return 'GalleryTabs';
	}
}

export default GalleryTabs;

